import './CreateRuleEditParameters2.scss'
import '../rulesLibrary/rulesDrawer.scss'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import SelectSingle from '../ruleParameters2/selectSingle/selectSingle'
import NumberInput from '../ruleParameters2/numberInput/numberInput'
import CheckboxItem from '../ruleParameters2/checkbox/checkbox'
import StringInput from '../ruleParameters2/stringInput/stringInput'
import { Box, Divider, Paper, Typography } from '@mui/material'
import { camelCaseFormatter } from '../../../utilities/formatters/camelCase'
import TransactionAmountThresholdGueno from '../ruleParameters2/transactionAmountThresholdGueno.jsx/transactionAmountThresholdGueno'
import WeekDayRangePicker from '../ruleParameters2/notWorkingDays/weekDayRangePicker'
import ListOfRuleIds from '../ruleParameters2/listOfRuleIds/listOfRuleIds'
import SelectMultiple from '../ruleParameters2/selectMultiple/selectMultiple'
import ListOfBlacklistIds from '../ruleParameters2/blacklistids/listOfBlacklistIds'
import ListOfStrings from '../ruleParameters2/listOfStrings/listOfStrings'
import TimeWindow from '../ruleParameters2/timeWindow/timeWindow'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../../utilities/TableChip'
import LowTransactionValues from '../ruleParameters2/lowTransactionValues/lowTransactionValues'
import AmountDateRangePicker from '../ruleParameters2/amountDateRange/amountDateRange'
import TransactionCountThreshold from '../ruleParameters2/transactionCountThreshold/transactionCountThreshold'
import NumberAndStringInputs from '../ruleParameters2/numberAndStringInputs/numberAndStringInputs'
import MinMaxValues from '../ruleParameters2/minMaxValues/minMaxValues'

const CreateGuenoRuleEditParameters = ({
  rule = { name: '' },
  ruleParameters,
  setRuleParameters,
  selectedAction,
  setSelectedAction,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box>
      {(!rule.parametersSchema || Object.keys(rule.parametersSchema).length === 0) && (
        <Box className="Label">
          <span>No editable parameters...</span>
        </Box>
      )}

      {rule?.parametersSchema && (
        <Box>
          {rule.parametersSchema?.type === 'object' &&
            typeof rule.parametersSchema?.properties === 'object' &&
            Object.values(rule.parametersSchema?.properties)?.map((item, index) => (
              <Box key={index}>
                <Box>
                  <Box className="title-parameter">
                    {(item.nullable === false ||
                      (typeof item?.items?.properties === 'object' &&
                        Object.values(item?.items?.properties).length !== 0 &&
                        Object.values(item?.items?.properties)?.find((p) => p.nullable === false))) && (
                      <span className="mandatory">* </span>
                    )}

                    <Typography variant="title3">
                      {item.title
                        ? camelCaseFormatter(item.title)
                        : item.component
                        ? camelCaseFormatter(item.component)
                        : ''}
                    </Typography>
                  </Box>

                  {item.component === 'NumberInput' && (
                    <NumberInput
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      schema={item}
                      isRequired={!item.nullable}
                      setError={setError}
                    />
                  )}
                  {item.component === 'SelectSingle' && (
                    <SelectSingle
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      posibleValues={item.enum}
                      schema={item}
                      isRequired={!item.nullable}
                      setError={setError}
                      desc={item.description ?? ''}
                    />
                  )}

                  {item.component === 'StringInput' && (
                    <StringInput
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      schema={item}
                      isRequired={!item.nullable}
                      setError={setError}
                    />
                  )}

                  {item.component === 'CheckboxItem' && (
                    <CheckboxItem
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      label={item.title}
                      text={item.description}
                    />
                  )}

                  {item.component === 'NotWorkingDays' && (
                    <WeekDayRangePicker
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                    />
                  )}

                  {item.component === 'AmountDateRange' && (
                    <AmountDateRangePicker
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      possibleValues={item?.items?.properties?.currency?.enum}
                    />
                  )}

                  {item.component === 'TransactionAmountThreshold' && (
                    <TransactionAmountThresholdGueno
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      setError={setError}
                      errorList={errorList}
                      setErrorList={setErrorList}
                      possibleValues={item?.items?.properties?.currency?.enum}
                    />
                  )}
                  {item.component === 'ListOfRuleIds' && (
                    <ListOfRuleIds
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                    />
                  )}
                  {(item.component === 'ListOfUserProperties' || item.component === 'SelectMultiple') && (
                    <SelectMultiple
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      posibleValues={item.enum}
                    />
                  )}
                  {item.component === 'ListOfBlacklistIds' && (
                    <ListOfBlacklistIds
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      type={'black_list'}
                    />
                  )}
                  {item.component === 'ListOfStrings' && (
                    <ListOfStrings
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      defaultListValues={[]}
                      setError={setError}
                      errorList={errorList}
                      setErrorList={setErrorList}
                    />
                  )}
                  {item.component === 'TimeWindow' && (
                    <TimeWindow
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      granularity={item?.properties?.granularity?.enum}
                      desc={item?.properties?.rollingBasis?.description}
                      schema={item?.properties?.units}
                      setError={setError}
                      errorList={errorList}
                      setErrorList={setErrorList}
                    />
                  )}
                  {item.component === 'LowTransactionValues' && (
                    <LowTransactionValues
                      isEditing={isEditing}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      setError={setError}
                      errorList={errorList}
                      setErrorList={setErrorList}
                      isRequired={!item.nullable}
                    />
                  )}
                  {item.component === 'TransactionCountThreshold' && (
                    <TransactionCountThreshold
                      isEditing={isEditing}
                      property={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                      setError={setError}
                      setErrorList={setErrorList}
                      errorList={errorList}
                      desc={item.properties?.timeWindow?.properties?.rollingBasis?.title}
                      granularity={item.properties?.timeWindow?.properties?.granularity?.enum}
                      numberInputSchema={item.properties?.threshold}
                      timeWindowSchema={item.properties?.timeWindow?.properties?.units}
                    ></TransactionCountThreshold>
                  )}
                  {item.component === 'NumberAndStringInputs' && (
                    <NumberAndStringInputs
                      isEditing={isEditing}
                      schema={item.properties}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                    />
                  )}
                  {item.component === 'MinMaxValues' && (
                    <MinMaxValues
                      isEditing={isEditing}
                      rule={rule}
                      propertie={item.key}
                      ruleParameters={ruleParameters}
                      setRuleParameters={setRuleParameters}
                    />
                  )}
                  <Divider sx={{ margin: '1rem 0' }} />
                </Box>
              </Box>
            ))}
        </Box>
      )}

      <Box className="drawer-block">
        <Typography variant="title">{t('common:ruleActions')}</Typography>
        <FormControl>
          <Box className="actions-grid">
            <Paper
              elevation={0}
              variant="colorPrimary"
              className="actions-flex"
              sx={{ border: selectedAction === 'FLAG' ? '1px solid var(--alert-dark)' : 'none' }}
            >
              <FormControlLabel
                disabled={!isEditing}
                checked={selectedAction === 'FLAG'}
                value="FLAG"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--alert-dark)',
                      },
                    }}
                  />
                }
                label={<TableChip action="FLAG" noIcon={true} />}
                onChange={() => setSelectedAction('FLAG')}
                labelPlacement="start"
              />
              <Typography variant="subtitle4">
                Allow the transaction to continue but mark as FLAGGED. Recommended for AML Rules.
              </Typography>
            </Paper>

            <Paper
              elevation={0}
              variant="colorPrimary"
              className="actions-flex"
              sx={{ border: selectedAction === 'SUSPEND' ? '1px solid var(--calm-dark)' : 'none' }}
            >
              <FormControlLabel
                disabled={!isEditing}
                checked={selectedAction === 'SUSPEND'}
                value="SUSPEND"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--calm-dark)',
                      },
                    }}
                  />
                }
                label={<TableChip action="SUSPEND" noIcon={true} />}
                onChange={() => setSelectedAction('SUSPEND')}
                labelPlacement="start"
              />
              <Typography variant="subtitle4">Put the transaction on hold till investigation is complete.</Typography>
            </Paper>

            <Paper
              elevation={0}
              variant="colorPrimary"
              className="actions-flex"
              sx={{ border: selectedAction === 'BLOCK' ? '1px solid var(--error-dark)' : 'none' }}
            >
              <FormControlLabel
                disabled={!isEditing}
                checked={selectedAction === 'BLOCK'}
                value="BLOCK"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--error-dark)',
                      },
                    }}
                  />
                }
                label={<TableChip action="BLOCK" noIcon={true} />}
                onChange={() => setSelectedAction('BLOCK')}
                labelPlacement="start"
              />
              <Typography variant="subtitle4">
                Stop transaction from continuing. Recommended for Fraud Rules.
              </Typography>
            </Paper>
          </Box>
        </FormControl>
      </Box>
    </Box>
  )
}

export default CreateGuenoRuleEditParameters
