import { createSlice } from '@reduxjs/toolkit'

export const kycSlice = createSlice({
  name: 'kyc',
  initialState: {
    kyc: {},
    kycDetail: {},
    kycRelatedCompany: {},
    toAssign: [],
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      checkId: '',
      status: 'ALL',
      name: '',
      documentNumber: '',
      nationality: '',
    },
  },
  reducers: {
    updateKyc: (state, action) => {
      state.kyc = action.payload
    },
    updateKycDetail: (state, action) => {
      state.kycDetail = action.payload
    },
    updateKycShareholder: (state, action) => {
      state.kycRelatedCompany = action.payload
    },
    updateKycToAssign: (state, action) => {
      state.toAssign = action.payload
    },
    updateKycFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateKyc, updateKycDetail, updateKycFilters, updateKycToAssign, updateKycShareholder } =
  kycSlice.actions
export default kycSlice.reducer
