import '../reports.scss'
import { useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ruleQByClientReportDataMapper,
  ruleQByClientReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/ruleQByClientReportDataMapper'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DiffComponent from '../../../components/utilities/DiffComponent'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { abmMapper, abmMapperForPDF } from '../../../components/common/dataExport/dataMappers/abmGenericMap'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { DownloadIcon } from '../../../components/utilities/DownloadIcon'
import { useTranslation } from 'react-i18next'
import RulesQReport from './rulesQReport'

const RulesQReportByClient = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  reportKey,
  title,
  isLoading,
  setIsLoading,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [previous, setPrevious] = useState({})
  const [newImage, setNewImage] = useState({})
  const [info, setInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const allReports = useReports(reportKey, setIsLoading, isLoading)

  const handleClose = () => {
    setOpen(false)
    setPrevious({})
    setNewImage({})
    setInfo({})
  }

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="title">
          <Box>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon color="primary" />
            </IconButton>
          </Box>
          <span>{reportKey === 'rules_total_report_by_client' ? t('common:reportRulesTotalPerUserReportTitle') : t('common:reportRulesPerRulePerUserReportTitle')}</span>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 ? (
            <Button
              disabled={!activeServices.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
            >
              <DownloadIcon />
            </Button>
          ) : (
            <></>
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell>{t('common:userId')}</TableCell>
              {reportKey !== 'rules_total_report_by_client' && <TableCell>{t('common:ruleId')}</TableCell>}
              {reportKey !== 'rules_total_report_by_client' && <TableCell>{t('common:ruleInstanceId')}</TableCell>}
              <TableCell>{t('common:exeQ')}</TableCell>
              <TableCell>{t('common:hitQ')}</TableCell>
              <TableCell>{t('common:hitExeRatio')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(allReports?.data) && allReports?.data?.length > 0 ? (
              allReports.data
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.userId}</TableCell>
                    {reportKey !== 'rules_total_report_by_client' && <TableCell>{row.ruleId}</TableCell>}
                    {reportKey !== 'rules_total_report_by_client' && <TableCell>{row.ruleInstanceId}</TableCell>}
                    <TableCell>{row.exeQ}</TableCell>
                    <TableCell>{row.hitQ}</TableCell>
                    <TableCell>{row.hitExeRate !== -1 ? row.hitExeRate.toFixed(4) : 'not-defined value'}</TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow className="no-rows-user no-rows-container">
                <td>{t('common:noRows')}</td>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={allReports?.data}
        dataMapper={ruleQByClientReportDataMapper(reportKey)}
        dataMapperForPDF={ruleQByClientReportDataMapper(reportKey)}
        fileName={reportKey}
      />
    </Box>
  )
}

export default RulesQReportByClient
