import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRoles } from '../../features/roles/rolesSlice'
import { useNavigate } from 'react-router-dom'

export const useRolesByEmailClient = (emailClient) => {
  const navigate = useNavigate()
  const rolesState = useSelector((state) => state.role)
  const queryState = useSelector((state) => state.role.filters)
  const { viewMode, limit, offset, page, status, roleName } = queryState ? queryState : { ...null }
  const { accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  useEffect(() => {
    const baseUrlRoles = `${process.env.REACT_APP_BASEURL}/admin/roles/emailClient/${emailClient}`
    let url = buildUrl(baseUrlRoles)

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`

      if (status?.length) url += `&status=${status}`

      if (roleName?.length) url += `&roleName=${roleName}`

      return url
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../../data/roles.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateRoles(aux))

          if (!res.data?.length) {
            dispatch(updateRoles([]))
            navigate('/')
          }
        }
      })
      .catch((error) => console.error('[HOOK: useRolesByEmailClient] --> ', error))
  }, [queryState])

  return rolesState
}
