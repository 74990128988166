import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import { useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DiffComponent from '../../../components/utilities/DiffComponent'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { abmMapper, abmMapperForPDF } from '../../../components/common/dataExport/dataMappers/abmGenericMap'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { DownloadIcon } from '../../../components/utilities/DownloadIcon'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'

const AbmGenericReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  reportKey,
  title,
  isLoading,
  setIsLoading,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [previous, setPrevious] = useState({})
  const [newImage, setNewImage] = useState({})
  const [info, setInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const allReports = useReports(reportKey, setIsLoading, isLoading)

  const handleClose = () => {
    setOpen(false)
    setPrevious({})
    setNewImage({})
    setInfo({})
  }

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="title">
          <span>{title}</span>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 ? (
            <Button
              disabled={!activeServices.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
            >
              <DownloadIcon />
            </Button>
          ) : (
            <></>
          )}
        </Box>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>
                {t('common:action')} {t('common:owner')}
              </TableCell>
              {reportKey === 'abm_users' && <TableCell>{t('common:user')}</TableCell>}
              {reportKey === 'abm_roles' && <TableCell>{t('common:role')}</TableCell>}
              <TableCell>{t('common:action')}</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>{t('common:date')}</TableCell>
              <TableCell>{t('common:details')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allReports?.data?.length ? (
              allReports.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="table-body-cell">{row.emailUser}</TableCell>
                    {reportKey === 'abm_users' && (
                      <TableCell className="table-body-cell">{row.activityDetails?.userABM}</TableCell>
                    )}
                    {reportKey === 'abm_roles' && (
                      <TableCell className="table-body-cell">
                        {row.activityDetails?.newRecord?.roleName
                          ? row.activityDetails.newRecord.roleName
                          : row.activityDetails?.previousRecord?.roleName}
                      </TableCell>
                    )}
                    <TableCell className="table-body-cell">{row.activityDetails?.abmAction}</TableCell>
                    <TableCell className="table-body-cell">{row.activityDetails?.actionResult}</TableCell>
                    <TableCell className="table-body-cell">{buildTimeStringFromTimestamp(row.createdAt)}</TableCell>
                    {(row.activityDetails?.previousRecord || row.activityDetails?.newRecord) && (
                      <TableCell className="table-body-cell">
                        <Box style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => {
                              setOpen(true)
                              setPrevious(row.activityDetails?.previousRecord)
                              setNewImage(row.activityDetails?.newRecord)
                              setInfo({
                                email: row.emailUser,
                                action: row.activityDetails?.abmAction,
                                result: row.activityDetails?.actionResult,
                                date: row.createdAt,
                              })
                            }}
                            style={{ color: '#0DDE4E' }}
                          >
                            <VisibilityIcon style={{ height: '1.45rem' }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('common:rows')}
          className="pagination"
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={allReports?.data}
        dataMapper={abmMapper(reportKey)}
        dataMapperForPDF={abmMapperForPDF(reportKey)}
        fileName={reportKey}
        typeDataExport={'reports'}
        keyReports={reportKey}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="report-detail-modal report">
          <DiffComponent previousData={previous} newData={newImage} info={info} reportKey={reportKey} />
        </Box>
      </Modal>
    </Box>
  )
}

export default AbmGenericReport
