import { createSlice } from '@reduxjs/toolkit'

export const kybSlice = createSlice({
  name: 'kyb',
  initialState: {
    kyb: {},
    kybDetail: {},
    kybShareholder: {},
    toAssign: [],
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: '',
      checkId: '',
      status: 'ALL',
      name: '',
      documentNumber: '',
    },
  },
  reducers: {
    updateKyb: (state, action) => {
      state.kyb = action.payload
    },
    updateKybDetail: (state, action) => {
      state.kybDetail = action.payload
    },
    updateKybShareholder: (state, action) => {
      state.kybShareholder = action.payload
    },
    updateKybToAssign: (state, action) => {
      state.toAssign = action.payload
    },
    updateKybFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateKyb, updateKybDetail, updateKybFilters, updateKybToAssign, updateKybShareholder } =
  kybSlice.actions
export default kybSlice.reducer
