import { Box } from '@mui/material'
import RecursiveProperty from './RenderJSON'
import { camelCaseFormatter } from './formatters/camelCase'

export const mapLawsuits = (data) => {
  const groupBy = (arr, key) =>
    arr.reduce((acc, obj) => {
      const groupKey = obj[key]?.toLowerCase() || '---'
      acc[groupKey] = acc[groupKey] || []
      acc[groupKey].push(obj)
      return acc
    }, {})

  const groupedByCourtType = groupBy(data, 'CourtType')

  const result = {}
  Object.keys(groupedByCourtType).forEach((courtType) => {
    result[courtType] = groupBy(groupedByCourtType[courtType], 'MainSubject')
  })

  return result
}

export const renderJap = (data) => {
  return (
    <Box>
      {data &&
        Object.entries(data)?.map(([key, value], index) => {
          return (
            <Box className="accordion-jap" key={index}>
              {key !== 'Lawsuits' && key !== 'NextPageId' && (
                <Box className="accordion-jap-row">
                  <span>{camelCaseFormatter(key)}: </span>
                  <p>{value ?? '---'}</p>
                </Box>
              )}
            </Box>
          )
        })}

      {data && (
        <RecursiveProperty property={mapLawsuits(data.Lawsuits)} excludeBottomBorder={false} rootProperty={true} />
      )}
    </Box>
  )
}

export const renderLawsuits = (data) => {
  return (
    data &&
    Object.entries(data)?.map(([key, value], index) => {
      return (
        <Box className="accordion-jap" key={index}>
          {key !== 'Lawsuits' && key !== 'InactiveOwners' && key !== 'ActiveOwners' && (
            <Box className="accordion-jap-row">
              <span>{camelCaseFormatter(key)}: </span>
              <p>{value ?? '---'}</p>
            </Box>
          )}

          {key === 'Lawsuits' && (
            <Box className="accordion-lawsuits">
              {Object.entries(value).map(([key2, value2]) => {
                return (
                  <Box key={key2}>
                    {value2.Lawsuits.length > 0 && Array.isArray(value2.Lawsuits) && (
                      <Box className="accordion-lawsuits-row">
                        <span>Document Nº ({key2}): </span>
                        <Box>
                          <RecursiveProperty
                            property={mapLawsuits(value2.Lawsuits)}
                            excludeBottomBorder={false}
                            rootProperty={true}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
      )
    })
  )
}
