import { useNavigate, useParams } from 'react-router-dom'
import './workflows.scss'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import Spinner from '../../components/common/spinner/spinner'
import ReactFlowTest from './flowPOC/ReactFlowTest'

const WorkflowsBuilder = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }, [])

  return (
    <Box className="datatable workflows-2">
      {isLoading && <Spinner noTransform />}
      {/* MCC UPGRADE */}
      {/* {id === 'F-143' && !isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695823879/Testing/head-mcc.png"
          alt=""
        />
      )} */}
      {id === 'F-143' && !isLoading && (
        <div style={{ height: '624px' }}>
          <ReactFlowTest style={{ height: '624px' }} />
        </div>
      )}

      {/* Desition per group */}
      {id === 'F-126' && !isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695823879/Testing/head-group.png"
          alt=""
        />
      )}
      {id === 'F-126' && !isLoading && (
        <Box className="img-wrapper">
          <img
            className="img-separator-2"
            src="https://res.cloudinary.com/g-eno/image/upload/v1695823649/Testing/flow-group.png"
            alt=""
          />
        </Box>
      )}

      {/* Onboarding */}
      {(id === 'F-353' || id === 'F-342') && !isLoading && (
        <img
          className="img-separator"
          src="https://res.cloudinary.com/g-eno/image/upload/v1695823879/Testing/head-onboarding.png"
          alt=""
        />
      )}
      {id === 'F-353' && !isLoading && (
        <Box className="img-wrapper">
          <img src="https://res.cloudinary.com/g-eno/image/upload/v1695823649/Testing/flow-metamap.png" alt="" />
        </Box>
      )}
      {id === 'F-342' && !isLoading && (
        <Box className="img-wrapper">
          <img src="https://res.cloudinary.com/g-eno/image/upload/v1695823649/Testing/flow-onboarding.png" alt="" />
        </Box>
      )}
    </Box>
  )
}

export default WorkflowsBuilder
