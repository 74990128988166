import './listOfBlacklistIds.scss'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useEffect } from 'react'
import { MenuProps } from '../../../../utilities/MenuProps'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

const ListOfBlacklistIds = ({ propertie, ruleParameters, setRuleParameters, isEditing, type }) => {
  const { accessToken } = useSelector((state) => state.login)
  const [selectedValue, setSelectedValue] = useState('')
  const [change, setChange] = useState(false)
  const [list, setList] = useState([])
  const [context, setContext] = useState('')

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASEURL}/admin/lists?type=${type}`

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/black_list.json' : url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data } = res
          setList(data)
        }
      })
      .catch((error) => {
        console.error('[HOOK: useLists] --> ', error)
      })
  }, [])

  useEffect(() => {
    if (ruleParameters[propertie]) {
      setSelectedValue(ruleParameters[propertie])
    }
    if (propertie === 'blacklist') setContext('userId')
    else if (propertie === 'blacklistIbans') setContext('iban')
    else if (propertie === 'blacklistBankAccountNumbers') setContext('bankAccount')
    else if (propertie === 'blacklistCardFingerprints') setContext('cardFingerprint')
    else if (propertie === 'blacklistAchAccountNumbers') setContext('achAccount')
    else if (propertie === 'blacklistSwiftAccountNumbers') setContext('swiftAccount')
    else if (propertie === 'blacklistWalletIds') setContext('walletId')
  }, [propertie])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setSelectedValue(value)
    setChange(true)
  }

  useEffect(() => {
    if (change) {
      let all = { ...ruleParameters }
      all[propertie] = selectedValue
      setRuleParameters({ ...all })
    }
    setChange(false)
  }, [selectedValue, ruleParameters, setRuleParameters])

  return (
    <Box>
      <Select
        fullWidth
        size="small"
        displayEmpty
        disabled={!isEditing}
        value={selectedValue}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          return selected
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {Array.isArray(list) &&
          list
            ?.filter((item) => item.context === context)
            .map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {`${item.title}(${item.id})`}
              </MenuItem>
            ))}
      </Select>
    </Box>
  )
}

export default ListOfBlacklistIds
