import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateKyb, updateKybDetail, updateKybShareholder, updateKybToAssign } from '../../features/kyb/kybSlice'
import { updateKycDetail, updateKycToAssign } from '../../features/kyc/kycSlice'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'
import { getCountrySegment } from '../kyb/kyb.hook-helpers'
import { useParams } from 'react-router-dom'

export const useKyb = (isMyKyb) => {
  const queryState = useSelector((state) => state.kyb?.filters)
  const { limit, offset, fromDate, toDate, page, checkId, status, name, documentNumber } = queryState || {}
  const { accessToken, email } = useSelector((state) => state.login)

  const { currentCountry, countrySegment, isLoading } = useSelector((state) => state.kybCountry)

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (isMyKyb) url += `&assignedUsers=${email}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (checkId?.length) url += `&id=${checkId}`
    if (status !== 'ALL' && status?.length) url += `&status=${status}`
    if (name?.length) url += `&name=${name}`
    if (documentNumber?.length) url += `&documentNumber=${documentNumber}`
    return url
  }

  useEffect(() => {
    if (isLoading && currentCountry !== '') {
      const baseUrlKyb = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}`
      let url = buildUrl(baseUrlKyb)

      fetch(process.env.REACT_APP_IS_DEMO ? '../data/kybs.json' : url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const { data, pagination } = res
            const aux = {
              data,
              pagination,
            }
            dispatch(updateKyb(aux))
            dispatch(setIsLoading(false))
          }
        })
        .catch((error) => {
          console.error('[HOOK: useKyb] --> ', error)
          dispatch(setIsLoading(false))
        })
    }
  }, [isLoading, isMyKyb, currentCountry, countrySegment])
}

/**
 * the kyb routing logic of kybDetail is particular, to facilitate link sharing we should include the country to which the id belongs to in the url.
 * this is since the redux state is not available
 * @param {*} id
 * @param {*} isShareholder
 * @param {*} isPerson
 */
export const useKybDetail = (id, isShareholder, isPerson) => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state) => state.login)
  const languageState = useSelector((state) => state.language.lang)
  const { country } = useParams()

  const countrySegment = getCountrySegment(country)
  const { isLoading } = useSelector((state) => state.kybCountry)

  useEffect(() => {
    if (isLoading) {
      let baseUrl
      const urlToAssign = `${process.env.REACT_APP_BASEURL}/user/toAssign`

      if (isShareholder) {
        baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
      } else if (isPerson) {
        baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
      } else {
        baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
      }

      if (languageState === 'pt-BR') baseUrl += '?lang=PT'

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/kybDetails.json' : baseUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (isShareholder) {
            dispatch(updateKybShareholder(res.data))
          } else if (isPerson) {
            dispatch(updateKycDetail(res.data))
          } else {
            dispatch(updateKybDetail(res.data))
          }

          dispatch(setIsLoading(false))
        })
        .catch((error) => {
          console.error('[HOOK: useKybDetail] --> ', error)

          if (isShareholder) {
            dispatch(
              updateKybShareholder({
                statusCode: 404,
              }),
            )
          } else if (isPerson) {
            dispatch(
              updateKycDetail({
                statusCode: 404,
              }),
            )
          } else {
            dispatch(
              updateKybDetail({
                statusCode: 404,
              }),
            )
          }

          dispatch(setIsLoading(false))
        })

      if (!isShareholder) {
        fetch(urlToAssign, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.success) {
              if (isPerson) {
                dispatch(updateKycToAssign(res.data))
              } else {
                dispatch(updateKybToAssign(res.data))
              }
            }
            dispatch(setIsLoading(false))
          })
          .catch((error) => {
            console.error('[HOOK: useKybDetail] --> ', error)
            dispatch(setIsLoading(false))
          })
      }
    }
  }, [isLoading, id, country])
}

export const useKybShareholder = (id) => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state) => state.login)
  const { kybShareholder } = useSelector((state) => state.kyb)
  const languageState = useSelector((state) => state.language.lang)

  const { currentCountry, listOfAvailableKybCountryCodes, countrySegment, isLoading } = useSelector(
    (state) => state.kybCountry,
  )

  useEffect(() => {
    if (isLoading || kybShareholder?._id === id) {
      let url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
      if (languageState === 'pt-BR') url += '?lang=PT'

      fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/kybKycDetails.json' : url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch(updateKybShareholder(res.data))
          dispatch(setIsLoading(false))
        })
        .catch((error) => {
          console.error('[HOOK: useKybDetail] --> ', error)
          dispatch(
            updateKybShareholder({
              statusCode: 404,
            }),
          )
          dispatch(setIsLoading(false))
        })
    }
  }, [isLoading, id, currentCountry])
}
