/**
 * @function selectCountryCode
 * @description
 * @param {string[]} listOfAvailableKybCountryCodes - List of available KYB country codes.
 * @param {Function} setCurrentCountry - Function to update the current country state.
 */
export const selectCountryCode = (listOfAvailableKybCountryCodes, setCurrentCountry) => {
  if (listOfAvailableKybCountryCodes.length > 1) {
    if (listOfAvailableKybCountryCodes.includes('BR')) {
      setCurrentCountry('BR')
    } else {
      setCurrentCountry(listOfAvailableKybCountryCodes[0])
    }
  } else {
    setCurrentCountry(listOfAvailableKybCountryCodes[0])
  }
}

export const isMulticountryEnabled = (listOfAvailableKybCountryCodes) => {
  return listOfAvailableKybCountryCodes.length > 1
}

/**
 * getCountrySegment toma un pais y devuelve la convencion de ruteos para kyb
 * @param {'BR' | 'CO'} country
 * @returns {string}
 */
export const getCountrySegment = (country) => {
  if (!country) return '/'
  return country.toUpperCase() === 'BR' ? '/' : `/v2/${country}/`
}

/**
 * Adds additional parameters to the data object based on the type.
 * @param {string} type - The type of check.
 * @param {Object} data - The data object to which additional parameters will be added.
 * @returns {Object} The modified data object with additional parameters.
 */
export const addAdditionalParams = (type, data) => {
  
  const cedula = {
    document_type: 'cedula',
  }

  const mappedData = {
    'policia-co': cedula,
    'cadaveres-co': cedula,
    'procuraduria-co': cedula,
    'desaparecidos-co': cedula,
    'rnmc-co': cedula,
    'rues-co': {},
    'judicatura-co': {},
    'procesos-judiciales-co': {
      ...cedula,
    },
    'simit-co': {
      ...cedula,
    },
    'registraduria-co': {
    },
    'adres-co': {
      ...cedula,
    },
    'inpec-co': {
    },
    'fiscalia-co': {

    },
    'inhabilidades-co': {
      document_type: 'cedula',
    },
    'identity-business-co': {},
    'secop-co': {

    },
    'experian-hcpn-co': {
      ...cedula,
    },
  };

  const mappedKey = Object.keys(mappedData).find((key) => key === type)

  if(typeof mappedKey === 'undefined') console.error('Unknown check type:', type)
  

    const dataToSend = {
      ...data, 
      ...mappedData[mappedKey]
    }

  return dataToSend
}
