import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import './typing_animation.scss'
import SelfTypingUnits from './SelfTypingUnits'

const TypingAnimation = ({ casesData, caseToClose }) => {
  const [reportItems, setReportItems] = useState([
    {
      key: '1a',
      title: 'Company Description',
      content: [
        {
          type: 'text',
          itemContent:
            'ACME Financial Services, Inc. is a leading global financial institution, providing a wide range of financial products and services to a substantial and diversified client base that includes corporations, financial institutions, governments, and individuals. Founded over a century ago, ACME has grown into a premier banking entity, recognized for its innovative financial solutions. The bank operates in more than 50 countries, employing over 30,000 people worldwide. ACME is committed to maintaining the highest standards of integrity and professionalism in its relationships with its clients, shareholders, and communities, adhering to a strict regulatory framework designed to ensure the security and well-being of its operations and clientele.',
          rendered: false,
        },
      ],
    },
    {
      key: '2a',
      title: 'Risk Overview',
      content: [
        {
          type: 'table',
          itemContent: {
            headers: ['Name', 'Age', 'Passed'],

            rows: [
              ['John Doe', 25, true],
              ['Jane Doe', 22, false],
            ],
          },
          rendered: false,
        },
      ],
    },
    {
      key: '3a',
      title: 'Steps Taken in the EDD Review',
      content: [
        {
          type: 'text',
          itemContent:
            'The Enhanced Due Diligence (EDD) review undertaken by our Compliance Department involved several critical steps to ensure thorough evaluation and risk assessment of our high-risk clients.',
          rendered: false,
        },
        {
          type: 'text',
          itemContent: 'These steps included:',
          rendered: false,
        },
        {
          type: 'text',
          itemContent:
            '1. Identity Verification: We conducted in-depth verification of the client identity, utilizing government-issued documents, independent data sources, and third-party information.',
          rendered: false,
        },
        {
          type: 'text',
          itemContent:
            '2. Understanding the Nature of the Business: Our team reviewed the client’s business activities, industry sector, and the markets they operate in to assess the risk profile and the potential for money laundering or terrorist financing.',
          rendered: false,
        },
        {
          type: 'text',
          itemContent:
            '3. Source of Funds and Wealth: We investigated the origin of the client’s funds and wealth, requiring documentary evidence to confirm legitimacy and to ensure compliance with anti-money laundering regulations.',
          rendered: false,
        },
        {
          type: 'text',
          itemContent:
            '4. Ongoing Monitoring: Establishing a framework for continuous monitoring of the client’s transactions and activities, enabling us to identify and investigate any unusual or suspicious behavior.',
          rendered: false,
        },
        {
          type: 'text',
          itemContent:
            '5. Risk Assessment: A comprehensive risk assessment was conducted, taking into account the client’s country of operation, business activities, transaction patterns, and any negative news reports.',
          rendered: false,
        },
        {
          type: 'text',
          itemContent:
            'These steps are designed to mitigate potential risks associated with money laundering and terrorist financing, ensuring compliance with both domestic and international regulatory standards.',
          rendered: false,
        },
      ],
    },
    {
      key: '4a',
      title: 'Conclusion',
      content: [
        {
          type: 'text',
          itemContent:
            'Ut in neque at risus vestibulum consectetur ac in massa. Praesent et lacinia leo, a imperdiet nulla. Sed a egestas tortor, vitae fringilla quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed posuere, quam eget bibendum posuere, leo diam lacinia ante, sed porttitor felis sem eget tortor. Suspendisse vitae lacus mi. Duis eget lectus magna. Sed ultricies congue pharetra. Nullam sollicitudin tortor sed lobortis scelerisque. Aenean rutrum aliquet neque accumsan pharetra. Nulla turpis nisi, rhoncus at volutpat sit amet, volutpat vitae lorem. Pellentesque sed tellus elementum, semper nisl sit amet, lobortis dui.',
          rendered: false,
        },
      ],
    },
  ])
  const [currentCase, setCurrentCase] = useState(casesData.find((c) => c.id === caseToClose.id))

  useEffect(() => {
    if (casesData && casesData.length > 0) {
      setCurrentCase(casesData.find((c) => c.id === caseToClose.id))
    }
  }, [casesData, caseToClose])

  return (
    <Box>
      <Typography
        variant="h5"
        component="div"
        sx={{
          fontWeight: 'bold',
        }}
      >
        Enhanced Due Dilingence Report
      </Typography>
      <hr className="report_title_separator" />
      <p>
        <span className={'reportDescription'}>Title:</span>
        <span> Fraud Report on user {currentCase?.userId}. </span>
      </p>
      <p>
        <span className={'reportDescription'}>Last updated at:</span>
        <span> {currentCase?.updatedAt.split('T')[0]}. </span>
      </p>
      <p>
        <span className={'reportDescription'}>Reason for review:</span>
        <span> {currentCase?.generalAlerts.length} alerts have ran. </span>
      </p>
      <SelfTypingUnits reportItems={reportItems} setReportItems={setReportItems} />
    </Box>
  )
}

export default TypingAnimation
