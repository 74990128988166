import { createSlice } from '@reduxjs/toolkit'

export const kybNotificationsSlice = createSlice({
  name: 'kybNotifications',
  initialState: {
    notifications: {},
    outdated: {},
    next: {},
    filters: {
      page: 0,
      limit: 25,
      offset: 0,
      fromDate: null,
      toDate: null,
      expirationDate: '',
      id: '',
      status: '',
      riskLevel: '',
    },
  },
  reducers: {
    updateKybNotifications: (state, action) => {
      state.notifications = action.payload
    },
    updateKybNotificationsFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { updateKybNotifications, updateKybNotificationsFilters } = kybNotificationsSlice.actions
export default kybNotificationsSlice.reducer
