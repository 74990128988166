import '../home/home.scss'
import './onboarding.scss'
import { FileUploader } from 'react-drag-drop-files'
import { Box, Button, MenuItem, Select, TextField, Paper, InputLabel, Typography, Link } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { countryDictionary } from '../../components/common/flag/countries'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../components/utilities/RenderTooltip'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'

const DOCUMENT_TYPES = [
  'passport',
  'driving_license',
  'national_insurance_number',
  'social_security_number',
  'tax_identification_number',
  'utility_bill',
  'national_identity_card',
  'visa',
  'polling_card',
  'residence_permit',
  'birth_certificate',
  'bank_statement',
  'change_of_name',
  'tax_document',
  'company_confirmation_statement',
  'company_annual_accounts',
  'company_statement_of_capital',
  'company_change_of_address',
  'company_incorporation',
  'company_change_of_officers',
  'company_change_of_beneficial_owners',
  'unknown',
  'other',
]

const CHECK_TYPES = ['identity_check', 'document_check', 'both']

const FILE_TYPES = ['JPG', 'JPEG', 'PNG', 'GIF']

const OnboardingManualCheck = () => {
  const { accessToken } = useSelector((state) => state.login)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [documentType, setDocumentType] = useState('national_identity_card')
  const [webhookUrl, setWebhookUrl] = useState('')
  const [issuingCountry, setIssuingCountry] = useState('')
  const [selfie, setSelfie] = useState('')
  const [documentFront, setDocumentFront] = useState('')
  const [documentBack, setDocumentBack] = useState('')
  const [checkType, setCheckType] = useState('both')
  const [check1, setCheck1] = useState({})
  const [check2, setCheck2] = useState({})
  const [finish, setFinish] = useState(false)
  const [webhook, setWebhook] = useState(false)
  const { theme } = useSelector((state) => state.theme)

  const { t } = useTranslation(['common', 'messages'])

  const handleSubmit = () => {
    setIsFetching(true)
    setErrorMessage('')
    if (!process.env.REACT_APP_IS_DEMO) {
      let formData = new FormData()
      formData.append('email', email)
      formData.append('firstName', firstName)
      formData.append('lastName', lastName)
      formData.append('documentType', documentType)
      if (issuingCountry !== '') formData.append('issuingCountry', issuingCountry)
      formData.append('webhookUrl', webhookUrl)
      if (selfie !== '') formData.append('selfie', selfie)
      formData.append('documentImage', documentFront)
      if (documentBack !== '') formData.append('backDocumentImage', documentBack)

      let url = `${process.env.REACT_APP_BASEURL}/onboarding`
      if (checkType === 'both') url += '/identityAndDocumentCheck'
      else if (checkType === 'identity_check') url += '/identityCheck'
      else url += '/documentCheck'

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            if (res.data?.identityCheck && res.data?.documentCheck) {
              setCheck1({ type: 'identityCheck', id: res.data?.identityCheck?.id })
              setCheck2({ type: 'documentCheck', id: res.data?.documentCheck?.id })
            } else if (res.data?.id) {
              setCheck1({ type: res.data?.type, id: res.data?.id })
            }
            setWebhook(true)
            setTimeout(() => {
              setIsFetching(false)
              setFinish(true)
              setWebhook(false)
            }, 15000)
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    } else {
      setCheck1({ type: 'identityCheck', id: '64f7992560e7680008dd8f56' })
      setCheck2({ type: 'documentCheck', id: '64f7981d1753700008f54fc6' })
      setWebhook(true)
      setTimeout(() => {
        setIsFetching(false)
        setFinish(true)
        setWebhook(false)
      }, 5000)
    }
  }

  const handleReset = () => {
    setEmail('')
    setFirstName('')
    setLastName('')
    setDocumentType('national_identity_card')
    setWebhookUrl('-')
    setIssuingCountry('')
    setSelfie('')
    setDocumentFront('')
    setDocumentBack('')
    setCheckType('both')
    setCheck1('')
    setCheck2('')
    setFinish(false)
    setErrorMessage('')
  }

  const canSend = () => {
    return (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ||
      firstName === '' ||
      lastName === '' ||
      webhookUrl === '' ||
      documentFront === ''
    )
  }

  const openInNewTab = (id) => {
    const base = process.env.REACT_APP_BASEURL
    const finalBase = base.includes('dev')
      ? 'https://www.dev.dashboard.gueno.io/'
      : base.includes('stg')
      ? 'https://www.sandbox.dashboard.gueno.io/'
      : base.includes('prd')
      ? 'https://www.dashboard.gueno.io/'
      : 'http://localhost:3001/'

    window.open(finalBase + 'onboardings/' + id, '_blank', 'noreferrer')
  }

  return (
    <Box>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">
            {t('common:identity')} {t('common:manualCheck')}
          </Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      <Box className="onb-manual-divider">
        <Paper elevation={0} variant="rootOutlined" sx={{ marginRight: '1rem', padding: '1.5rem 1rem 1.5rem 1.5rem' }}>
          <Box className="onb-manual-overflow">
            <Box sx={{ marginBottom: '1.5rem' }}>
              <Typography variant="title">{t('common:information')}</Typography>
            </Box>

            <Box className="onb-manual-wrapper">
              <Box className="onb-manual-flex">
                <Box className="onb-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:firstName')}</InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                  </Box>

                  <TextField
                    size="small"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    placeholder={t('common:firstName')}
                  />
                </Box>

                <Box className="onb-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:lastName')}</InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                  </Box>
                  <TextField
                    size="small"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    placeholder={t('common:lastName')}
                  />
                </Box>
              </Box>

              <Box className="onb-manual-input">
                <Box className="required">
                  <InputLabel>{t('common:email')}</InputLabel>
                  <Typography variant="subtitle3">({t('common:required')})</Typography>
                </Box>

                <TextField
                  size="small"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('common:email')}
                />
                {!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && email !== '' && (
                  <Typography variant="h5" color="error">
                    {t('messages:shouldBeValidEmail')}
                  </Typography>
                )}
              </Box>

              <Box className="onb-manual-flex">
                <Box className="onb-manual-input">
                  <Box className="required">
                    <InputLabel>
                      {t('common:document')} {t('common:type')}
                    </InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                  </Box>
                  <Select
                    fullWidth
                    displayEmpty
                    size="small"
                    required
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:document')}`}</Typography>
                    </MenuItem>
                    {DOCUMENT_TYPES.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box className="onb-manual-input">
                  <Box className="required">
                    <InputLabel>{t('common:issuingCountry')}</InputLabel>
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                  </Box>

                  <Select
                    size="small"
                    fullWidth
                    displayEmpty
                    required
                    value={issuingCountry}
                    onChange={(e) => setIssuingCountry(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t(
                        'common:issuingCountry',
                      )}`}</Typography>
                    </MenuItem>
                    {Object.keys(countryDictionary).map((key) => (
                      <MenuItem key={key} value={key}>
                        {countryDictionary[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Box className="onb-manual-flex">
                <Box className="onb-manual-input">
                  <Box className="required">
                    <InputLabel>Webhook Url</InputLabel>{' '}
                    <Typography variant="subtitle3">({t('common:required')})</Typography>
                    <RenderTooltip
                      title={
                        'This value is for you to test your webhook URLs, you do not have to send it if you only want to use/test the final response.'
                      }
                    />
                  </Box>
                  <TextField
                    size="small"
                    value={webhookUrl}
                    onChange={(event) => setWebhookUrl(event.target.value)}
                    placeholder="Webhook Url"
                    required
                  />
                </Box>
                <Box className="onb-manual-input">
                  <InputLabel>{t('common:checkType')}</InputLabel>

                  <Select
                    size="small"
                    fullWidth
                    displayEmpty
                    required
                    value={checkType}
                    onChange={(e) => setCheckType(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">{`${t('common:select')} ${t('common:checkType')}`}</Typography>
                    </MenuItem>
                    {CHECK_TYPES.map((name) => (
                      <MenuItem key={name} value={name}>
                        {t(`common:${name}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Box sx={{ gridColumn: '1 / 4' }}>
                <Typography variant="title">{t('common:uploadPictures')}</Typography>
              </Box>

              <Box className="onb-files-drag">
                <Typography variant="title2">{t('common:addASelfie')}</Typography>
                <FileUploader
                  handleChange={setSelfie}
                  name="file"
                  label={t('common:fileUploaderLabel')}
                  types={FILE_TYPES}
                  children={
                    <Paper elevation={0} variant="rootDashed" className="draggable draggable-small">
                      <Box className="draggable-icon"></Box>
                      <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                      <Box>
                        <Typography variant="subtitle4">{t('common:or')} </Typography>
                        <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                          {t('common:browseMedia')}
                        </Typography>
                      </Box>
                    </Paper>
                  }
                />
                <Typography variant="subtitle4">{t('common:supportedMedia')}: JPG, JPEG, PNG, GIF.</Typography>

                {selfie && (
                  <Paper elevation={0} variant="colorPrimary" className="onb-file">
                    <Box className="onb-file-description">
                      <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                        <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                          JPG
                        </Typography>
                      </Box>

                      <Typography variant="h6" title={selfie.name}>
                        {selfie.name}
                      </Typography>
                    </Box>
                    <Box className="onb-file-icons">
                      <Button variant="outlinedBlank" onClick={(e) => setSelfie('')}>
                        <DeleteOutlineIcon />
                      </Button>
                    </Box>
                  </Paper>
                )}
              </Box>

              <Box className="onb-manual-flex">
                <Box className="onb-files-drag">
                  <Typography variant="title2">{t('common:documentFrontSide')}</Typography>
                  <FileUploader
                    handleChange={setDocumentFront}
                    name="file"
                    label={t('common:fileUploaderLabel')}
                    types={FILE_TYPES}
                    children={
                      <Paper elevation={0} variant="rootDashed" className="draggable draggable-small">
                        <Box className="draggable-icon"></Box>
                        <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                        <Box>
                          <Typography variant="subtitle4">{t('common:or')} </Typography>
                          <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                            {t('common:browseMedia')}
                          </Typography>
                        </Box>
                      </Paper>
                    }
                  />
                  <Typography variant="subtitle4">{t('common:supportedMedia')}: JPG, JPEG, PNG, GIF.</Typography>

                  {documentFront && (
                    <Paper elevation={0} variant="colorPrimary" className="onb-file">
                      <Box className="onb-file-description">
                        <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                          <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                            JPG
                          </Typography>
                        </Box>

                        <Typography variant="h6" title={documentFront.name}>
                          {documentFront.name}
                        </Typography>
                      </Box>
                      <Box className="onb-file-icons">
                        <Button variant="outlinedBlank" onClick={(e) => setDocumentFront('')}>
                          <DeleteOutlineIcon />
                        </Button>
                      </Box>
                    </Paper>
                  )}
                </Box>

                <Box className="onb-files-drag">
                  <Typography variant="title2">{t('common:documentBackSide')}</Typography>
                  <FileUploader
                    handleChange={setDocumentBack}
                    name="file"
                    label={t('common:fileUploaderLabel')}
                    types={FILE_TYPES}
                    children={
                      <Paper elevation={0} variant="rootDashed" className="draggable draggable-small">
                        <Box className="draggable-icon"></Box>
                        <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                        <Box>
                          <Typography variant="subtitle4">{t('common:or')} </Typography>
                          <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                            {t('common:browseMedia')}
                          </Typography>
                        </Box>
                      </Paper>
                    }
                  />
                  <Typography variant="subtitle4">{t('common:supportedMedia')}: JPG, JPEG, PNG, GIF.</Typography>

                  {documentBack && (
                    <Paper elevation={0} variant="colorPrimary" className="onb-file">
                      <Box className="onb-file-description">
                        <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                          <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                            JPG
                          </Typography>
                        </Box>

                        <Typography variant="h6" title={documentBack.name}>
                          {documentBack.name}
                        </Typography>
                      </Box>
                      <Box className="onb-file-icons">
                        <Button variant="outlinedBlank" onClick={(e) => setDocumentBack('')}>
                          <DeleteOutlineIcon />
                        </Button>
                      </Box>
                    </Paper>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper elevation={0} variant="rootOutlined" className="onb-manual-results-wrapper" sx={{ padding: '1.5rem' }}>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Typography variant="title">
              {t('common:check')} {t('common:results')}
            </Typography>
          </Box>

          {!isFetching && finish && errorMessage === '' ? (
            <Box className="onb-manual-results">
              <Paper elevation={0}>
                <Box className="onb-manual-sub-results">
                  <Box className="onb-manual-grid">
                    <Typography variant="subtitle3">{t('common:checkType')}</Typography>
                    <Typography variant="title2">{check1.type ? check1.type : '---'}</Typography>
                  </Box>
                  <Box className="onb-manual-grid">
                    <Typography variant="subtitle3">{t('common:check')} ID</Typography>
                    {check1 && (
                      <Link onClick={() => openInNewTab(0)}>
                        <Typography variant="number" color="action">
                          {check1.id}
                        </Typography>
                      </Link>
                    )}
                    {check2 && (
                      <Link onClick={() => openInNewTab(1)}>
                        <Typography variant="number" color="action">
                          {check2.id}
                        </Typography>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Box>
          ) : (
            <Paper elevation={0} className="onb-manual-legend">
              <Typography variant="legend" textAlign="center">
                {t('messages:manualCheckPreview')}
              </Typography>
            </Paper>
          )}
        </Paper>
      </Box>

      <Box className="modal-filter-buttons">
        <Button
          onClick={() => {
            handleReset()
          }}
          size="small"
          variant="outlinedGrey"
        >
          {t('common:reset')}
        </Button>
        <Button size="small" variant="contained" type="submit" disabled={canSend()} onClick={(e) => handleSubmit()}>
          {t('common:startCheck')}
        </Button>
      </Box>

      {isFetching && <ProcessingSpinner message={t('common:processing')} />}
      {errorMessage !== '' && (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default OnboardingManualCheck
