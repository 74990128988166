import { Button, Link, Typography, Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../utilities/TableChip'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { DataGrid } from '@mui/x-data-grid'

const DatatableCases = ({
  hasPermissions,
  cases,
  setCaseToClose,
  setOpenCloseCase,
  setOpenCloseCaseReport,
  count,
  hasTabs,
  rowsPerPage,
  page,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  setPage,
  isFromDetail,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])

  const getUserAndNavigate = async (userId) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const userUrl = `${process.env.REACT_APP_BASEURL}/kyt/users/${userId}/${emailClient}`
    fetch(userUrl, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.success && res.data) {
          navigate(`/users/${userId}?type=${res.data.type}`)
        }
      })
      .catch((error) => {
        console.error('[CASES: caseUser] --> ', error)
      })
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => (
        <Link
          href={`/case-management/case/${params.row.id}`}
          title={params.row.maskId ? params.row.maskId : params.row.id}
        >
          <Typography variant="number">{params.row.maskId ? params.row.maskId : params.row.id}</Typography>
        </Link>
      ),
    },
    {
      field: 'userId',
      headerName: t('common:userId'),
      renderCell: (params) => (
        <Link
          onClick={() => {
            getUserAndNavigate(params.row.userId)
          }}
        >
          <Typography variant="number">{params.row.userId}</Typography>
        </Link>
      ),
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => params.row.name ?? '---',
    },
    {
      field: 'transactions',
      headerName: t('common:transactions'),
      renderCell: (params) => (
        <Typography variant="number">{params.row.transactions?.length || 0}</Typography>
      ),
    },
    {
      field: 'alerts',
      headerName: t('common:alerts'),
      flex: 1,
      minWidth: 80,
      maxWidth: 120,
      renderCell: (params) => (
        <Typography variant="number">{params.row.generalAlerts?.length || 0}</Typography>
      ),
    },
    {
      field: 'status',
      headerName: t('common:caseStatus'),
      renderCell: (params) => <TableChip noIcon={true} action={params.row.status} />,
    },
    {
      field: 'category',
      headerName: t('common:category'),
    },
    {
      field: 'label',
      headerName: t('common:label'),
    },
    {
      field: 'assignedUsers',
      headerName: t('common:assignedUsers'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box className="cellOverflow">
          {params.row.assignedUsers?.length
            ? params.row.assignedUsers.map((item) => item.email).join(', ')
            : '---'}
        </Box>
      ),
    },
    {
      field: 'checklistInstanceIds',
      headerName: t('common:checklists'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Typography variant="number">{params.row.checklistInstanceIds?.length || 0}</Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      ),
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      ),
    },
    {
      field: 'status2',
      headerName: t('common:actions'),
      flex: 1,
      minWidth: 100,
      hide: location?.pathname?.includes('case-management') && !hasPermissions,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Link
          size="small"
          onClick={() => {
            setCaseToClose({ maskId: params.row.maskId ?? '', id: params.row.id })
            setOpenCloseCase(true)
          }}
        >
          {t('common:update')}
        </Link>
      ),
    },
    ...(process.env.REACT_APP_IS_DEMO && typeof setOpenCloseCaseReport === 'function'
      ? [
          {
            field: 'status3',
            headerName: t('common:reports'),
            flex: 1,
            hide: !process.env.REACT_APP_IS_DEMO,
            minWidth: 150,
            sortable: false,
            filterable: false,
            renderCell: () => (
              <Button
                variant="label"
                size="small"
                onClick={() => {
                  setOpenCloseCaseReport(true)
                }}
              >
                {t('common:AIreport')}
              </Button>
            ),
          },
        ]
      : []),
  ]

  return isFromDetail ? (
    <DataGrid
      getRowId={(row) => row._id || row.id}
      rows={(cases && cases?.data) || []}
      columns={columns}
      hideFooter={true}
      autoHeight={true}
    />
  ) : (
    <DataGridComponent
      rows={(cases && cases?.data) || []}
      columns={columns}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
      hasTabs={hasTabs}
    />
  )
}

export default DatatableCases
