import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, GridColumnMenu, useGridApiRef } from '@mui/x-data-grid-pro'
import { Box, Button, TablePagination } from '@mui/material'
import { useSelector } from 'react-redux'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useTranslation } from 'react-i18next'

export const DataGridComponent = ({
  rows,
  columns,
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  hasTabs,
}) => {
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['common'])
  const { theme } = useSelector((state) => state.theme)

  const [columnWidths, setColumnWidths] = useState(
    columns.map((col) => ({
      field: col.field,
      width: col.width || 100,
    })),
  )

 /*  const handleColumnResize = useCallback((params) => {
    const { field, width } = params
    setColumnWidths((currentWidths) =>
      currentWidths.map((col) => {
        if (col.field === field) {
          return { ...col, width }
        }
        return col
      }),
    )
  }, []) */

/*   useEffect(() => {
    setIsLoading(true)
    const unsubscribeResize = apiRef.current.subscribeEvent('columnResize', handleColumnResize)

    return () => {
      unsubscribeResize()
    }
  }, [apiRef, handleColumnResize]) */

  const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    expand: true,
  }

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions)
    }
  }, [apiRef, rows, theme, autosizeOptions])

  const modifiedColumns = columns.map((col) => ({
    ...col,
    width: columnWidths.find((c) => c.field === col.field)?.width || col.width,
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuFilterItem: null,
          columnMenuSortItem: null,
        }}
      />
    )
  }

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <Button
          variant="label"
          endIcon={<MenuOutlinedIcon />}
          onClick={() => apiRef.current.showPreferences('columns')}
        >
          {t('common:showHideColumns')}
        </Button>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={rows}
        columns={modifiedColumns}
        apiRef={apiRef}
        slots={{ columnMenu: CustomColumnMenu }}
        hideFooter={true}
        sx={{
          height: hasTabs ? `calc(100vh - ${hasTabs})` : 'calc(100vh - 19.5rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}
