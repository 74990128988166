import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateBatches, updateSelectedBatch, updateTemporalItems } from '../../features/batch/batchSlice'

export const useBatches = (setIsLoading) => {
  const { accessToken } = useSelector((state) => state.login)
  const [rendered, setRendered] = useState(false)
  const batchesState = useSelector((state) => state.batch.batches)
  const selectedBatchState = useSelector((state) => state.batch.selectedBatch)

  const dispatch = useDispatch()

  const refreshBatches = () => {
    const baseUrlBatches = `${process.env.REACT_APP_BASEURL}/batch/userBatches`

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(baseUrlBatches, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.batches) {
          dispatch(updateBatches(res.batches))
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('[HOOK: useBatches | refreshBatches] --> ', error)
        setIsLoading(false)
      })
  }

  const createBatch = async (batchType, file) => {
    const urlForCreateBatch = `${process.env.REACT_APP_BASEURL}/batch/kyt/${batchType.toLocaleLowerCase()}`

    const formData = new FormData()
    formData.append('file', file)
    const options = {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await fetch(urlForCreateBatch, options)
      setIsLoading(false)

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.message || 'Network response was not ok')
      }

      const data = await response.json()

      refreshBatches()

      return {
        message: data.message,
        severity: false,
      }
    } catch (error) {
      console.error('[HOOK: useBatches | sendCorrectionFile] --> ', error)
      setIsLoading(false)
      return {
        message: error.message || 'An error occurred',
        severity: true,
      }
    }
  }

  const processBatch = async (batchId) => {
    const urlForProcessBatch = `${process.env.REACT_APP_BASEURL}/batch/process/${batchId}`

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await fetch(urlForProcessBatch, options)
      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.message || 'Network response was not ok')
      }
      const data = await response.json()

      refreshBatches()

      return {
        message: data.message,
        severity: false,
      }
    } catch (error) {
      console.error('[HOOK: useBatches | processBatch] --> ', error)
      setIsLoading(false)
      return {
        message: error.message || 'An error occurred',
        severity: true,
      }
    }
  }

  const sendCorrectionFile = async (batchId, file) => {
    const urlForCorrectionFile = `${process.env.REACT_APP_BASEURL}/batch/correctionFile/${batchId}`

    const formData = new FormData()
    formData.append('file', file)
    const options = {
      method: 'PATCH',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    try {
      const response = await fetch(urlForCorrectionFile, options)
      setIsLoading(false)

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.message || 'Network response was not ok')
      }

      const data = await response.json()

      refreshBatches()

      return {
        message: data.message,
        severity: false,
      }
    } catch (error) {
      console.error('[HOOK: useBatches | sendCorrectionFile] --> ', error)
      setIsLoading(false)
      return {
        message: error.message || 'An error occurred',
        severity: true,
      }
    }
  }

  useEffect(() => {
    if (rendered && !batchesState.length) {
      refreshBatches()
    }
  }, [rendered])

  useEffect(() => {
    setRendered(true)
  }, [])

  return {
    batchesState,
    selectedBatchState,
    refreshBatches,
    createBatch,
    processBatch,
    sendCorrectionFile,
  }
}

export const useBatchList = (setIsLoading, isLoading, id) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const batchesState = useSelector((state) => state.batch.temporalItems)
  const batches = useSelector((state) => state.batch)
  const { limit, offset, page, type, status } = batches.filters
  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?emailClient=${emailClient}&limit=${limit}&page=${page}&offset=${offset}`
    if (type !== '' && type !== undefined) url += `&type=${type}`
    if (status !== '' && status !== undefined) url += `&status=${status}`
    return url
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrlBatches = `${process.env.REACT_APP_BASEURL}/batch/userBatches`

      const url = buildUrl(baseUrlBatches)

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateBatches(aux))
          }
        })
        .catch((error) => {
          console.error('[HOOK: useBatches | refreshBatches] --> ', error)
        })
      setIsLoading(false)
    }
  }, [batches, isLoading])

  return batchesState.temporalItems
}

export const useBatchById = (isLoading, setIsLoading, batchId) => {
  const { accessToken } = useSelector((state) => state.login)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading) {
      const baseUrlBatches = `${process.env.REACT_APP_BASEURL}/batch/getBatch/${batchId}`
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(baseUrlBatches, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.batch) {
            dispatch(updateSelectedBatch(res.batch))
          }
        })
        .catch((error) => {
          console.error('[HOOK: useBatches | refreshBatches] --> ', error)
          return {
            message: error,
            severity: true,
          }
        })
      setIsLoading(false)
    }
  }, [isLoading, batchId])
}

export const useBatchTemporalItems = (setIsLoading, isLoading, id) => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const batchesState = useSelector((state) => state.batch.temporalItems)
  const temporalFilters = useSelector((state) => state.batch?.temporalFilters)
  const { limit, offset, page, status } = temporalFilters
  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?emailClient=${emailClient}&limit=${limit}&page=${page}&offset=${offset}`
    if (status !== '' && status !== undefined) url += `&status=${status}`
    return url
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrl = `${process.env.REACT_APP_BASEURL}/batch/temporalItems/${id}`

      let urlGetItems = buildUrl(baseUrl)

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlGetItems, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateTemporalItems(aux))
            setIsLoading(false)
          }
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [temporalFilters, isLoading])

  return batchesState.temporalItems
}

export default useBatches
