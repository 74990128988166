import './datatable.scss'
import { useEffect, useState } from 'react'
import { Box, Checkbox } from '@mui/material'
import Spinner from '../spinner/spinner'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { camelCaseToPhrase } from '../toRender/camelCAseToPhrase'

const DatatableRoleCreation = ({
  isInPreview,
  services,
  service,
  selectedRowIds,
  setSelectedRowIds,
  handleEditSubService,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const columns = [
    {
      field: 'checkbox',
      headerName: t('common:action'),
      flex: 0.5,
      minWidth: 60,
      renderCell: (params, idx) => (
        <Checkbox
          key={idx}
          checked={services.find((r) => r.subServices.find((ss) => ss.name === params.row.name)) !== undefined || false}
          onChange={(e) => {
            !isInPreview && handleEditSubService(e.target.checked, service, params.row)
          }}
          color="secondary"
        />
      ),
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 2,
      minWidth: 200,
      renderCell: (params) => <p>{camelCaseToPhrase(params.row.name)}</p>,
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <p textTransform="capitalize">{params.row.status}</p>,
    },
    {
      field: 'method',
      headerName: t('common:method'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <p textTransform="capitalize">{params.row.method}</p>,
    },
    {
      field: 'path',
      headerName: t('common:path'),
      flex: 3,
      minWidth: 350,
      renderCell: (params) => <p>{params.row.path}</p>,
    },
  ]

  return (
    <Box className={`${isLoading && 'spinner-transition'} role-creation-box`}>
      {isLoading && <Spinner noTransform />}

      <DataGrid
        getRowId={(row) => row.name}
        getRowHeight={() => 'auto'}
        rows={service && service?.subServices?.length > 0 && service.subServices}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
        selectionModel={selectedRowIds}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedRowIds(newSelectionModel)
        }}
      />
    </Box>
  )
}

export default DatatableRoleCreation
