import { useDispatch, useSelector } from 'react-redux'
import {
  updateCaseAdminPanel,
  updateCaseAdminToAssing,
  updateCaseDetails,
  updateCaseUser,
  updateCases,
  updateToAssign,
  updateUserCases,
} from '../../features/cases/caseSlice'
import { useEffect } from 'react'

export const useCases = (setIsLoading, isLoading, isMyCases) => {
  const { accessToken, emailClient, email } = useSelector((state) => state.login)
  const caseState = useSelector((state) => state.case)
  const caseQueryState = useSelector((state) => state.case.filters)
  const {
    limit,
    offset,
    page,
    fromDate,
    toDate,
    status,
    caseId,
    userId,
    firstName,
    lastName,
    legalName,
    userType,
    userKey,
    userValue,
    transactionKey,
    transactionValue,
    documentNumber,
  } = caseQueryState

  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?emailClient=${emailClient}&limit=${limit}&page=${page}&offset=${offset}`
    if (isMyCases === 'MyCases') url += `&assignedUsers=${email}`
    if (status) url += `&status=${status}`
    if (caseId) url += `&id=${caseId}`
    if (userId) url += `&userId=${userId}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (firstName) url += `&firstName=${firstName}`
    if (lastName) url += `&lastName=${lastName}`
    if (legalName) url += `&legalName=${legalName}`
    if (userType && userType !== 'ALL') url += `&userType=${userType}`
    if (userKey) url += `&userKey=${userKey}`
    if (userValue) url += `&userValue=${userValue}`
    if (transactionKey) url += `&transactionKey=${transactionKey}`
    if (transactionValue) url += `&transactionValue=${transactionValue}`
    if (documentNumber) url += `&documentNumber=${documentNumber}`
    return url
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrlCases = `${process.env.REACT_APP_BASEURL}/caseManagement`

      let url = buildUrl(baseUrlCases)

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(
        process.env.REACT_APP_IS_DEMO
          ? isMyCases === 'MyCases'
            ? '../../data/myCases.json'
            : '../../data/caseManagement.json'
          : url,
        options,
      )
        .then((res) => res.json())
        .then((res) => {
          if (res?.success) {
            const { data, pagination, unique } = res
            const aux = { data, pagination, unique }
            dispatch(updateCases(aux))
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.error('[HOOK: useCases] --> ', error)
          setIsLoading(false)
        })
    }
  }, [isLoading, isMyCases])

  return caseState.cases
}

export const useCaseDetails = (setIsLoading, isLoading, id) => {
  const dispatch = useDispatch()
  const { accessToken, emailClient } = useSelector((state) => state.login)

  useEffect(() => {
    if (isLoading) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement/${id}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/caseDetails.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateCaseDetails(res.data))
            const userUrl = `${process.env.REACT_APP_BASEURL}/kyt/users/${res.data?.userId}/${emailClient}`
            setIsLoading(false)
            fetch(process.env.REACT_APP_IS_DEMO ? '../../data/kyt.both.json' : userUrl, options)
              .then((res2) => res2.json())
              .then((res2) => {
                if (res2.success && res2.data) {
                  res2.data = process.env.REACT_APP_IS_DEMO
                    ? res2.data?.filter((element) => {
                        return element.userId === res.data?.userId
                      })[0]
                    : res2.data
                  dispatch(updateCaseUser(res2.data))
                }
                setIsLoading(false)
              })
              .catch((error) => {
                console.error('[HOOK: caseUser] --> ', error)
                setIsLoading(false)
              })
          }
        })
        .catch((error) => {
          console.error('[HOOK: useCaseDetails] --> ', error)
          setIsLoading(false)
        })

      const urlToAssing = `${process.env.REACT_APP_BASEURL}/user/toAssign`

      fetch(urlToAssing, options)
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateToAssign(res.data))
          }
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[HOOK: useCaseDetails] --> ', error)
          setIsLoading(false)
        })
    }
  }, [isLoading, id])
}

export const useUserCases = (setIsLoading, isLoading, userId, limit, page, offset, origin) => {
  const dispatch = useDispatch()
  const { accessToken, emailClient } = useSelector((state) => state.login)

  useEffect(() => {
    if (isLoading) {
      const url = `${process.env.REACT_APP_BASEURL}/caseManagement?emailClient=${emailClient}&userId=${userId}&limit=${limit}&page=${page}&offset=${offset}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(
        process.env.REACT_APP_IS_DEMO ? `${origin === 'case' ? '../' : ''}../data/caseManagement.json` : url,
        options,
      )
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateUserCases(res))
          }
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('[HOOK: useCaseDetails] --> ', error)
          setIsLoading(false)
        })
    }
  }, [isLoading, userId, limit, page, offset])
}

export const useCaseAdminPanel = (setIsLoading, isLoading) => {
  const dispatch = useDispatch()
  const { accessToken, emailClient } = useSelector((state) => state.login)

  useEffect(() => {
    if (isLoading) {
      const url = `${process.env.REACT_APP_BASEURL}/caseAdminPanel/${emailClient}`
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateCaseAdminPanel({ ...res.data }))
            setIsLoading(false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('[HOOK: useCaseAdminPanel] --> ', error)
        })

      const urlToAssing = `${process.env.REACT_APP_BASEURL}/user/toAssign`

      fetch(urlToAssing, options)
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateCaseAdminToAssing(res.data))
            setIsLoading(false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('[HOOK: useCaseDetails] --> ', error)
        })
    }
  }, [isLoading])
}
