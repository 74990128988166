export const dashboardRoleDetailDataMapperForPDF = (data) => {
  const header = ['Rule Id', 'Rule Name', 'Rule Description', 'Status', 'Action', 'Hit Rate']
  const parsedData = data.map(() => {
    return [
      // rule.idConcat,
      // rule.ruleNameAlias,
      // rule.description,
      // rule.status,
      // rule.action,
      // rule.hitRate
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const dashboardRoleDetailDataMapper = (data) => {
  return data.map(() => {
    return {
      // RuleId: rule.idConcat,
      // RuleName: rule.ruleNameAlias,
      // RuleDescription: rule.description,
      // Status: rule.status,
      // Action: rule.action,
      // HitRate: rule.hitRate
    }
  })
}
