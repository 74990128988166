import { useDispatch, useSelector } from 'react-redux'
import { updateReports } from '../../features/reports/reportSlice'
import { useEffect } from 'react'

export const useReports = (reportKey, setIsLoading, isLoading) => {
  const { accessToken } = useSelector((state) => state.login)
  const reportState = useSelector((state) => state.report)
  const reportQueryState = useSelector((state) => state.report.filters)
  const {
    limit,
    offset,
    page,
    fromDate,
    toDate,
    emailUser,
    actionResult,
    actionType,
    affectedUser,
    abmAction,
    reportRoleName,
    affectedUser2,
    status,
    extension,
    collection,
    userId,
    ruleId,
    ruleInstanceId
  } = reportQueryState
  const dispatch = useDispatch()

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`
    if (emailUser) url += `&emailUser=${emailUser}`
    if (actionResult) url += `&actionResult=${actionResult}`
    if (actionType) url += `&actionType=${actionType}`
    if (affectedUser) url += `&affectedUser=${affectedUser}`
    if (abmAction) url += `&abmAction=${abmAction}`
    if (reportRoleName) url += `&reportRoleName=${reportRoleName}`
    if (affectedUser2) url += `&affectedUser2=${affectedUser2}`
    if (status) url += `&status=${status}`
    if (extension) url += `&extension=${extension}`
    if (collection) url += `&data=${collection}`
    if (userId) url += `&userId=${userId}`
    if (ruleId) url += `&ruleId=${ruleId}`
    if (ruleInstanceId) url += `&ruleInstanceId=${ruleInstanceId}`
    return url
  }

  useEffect(() => {
    if (isLoading && reportKey) {
      let baseUrlReports
      const reportUrls = {
        'rules_report': `${process.env.REACT_APP_BASEURL}/reports/rules`,
        'rules_report_by_client': `${process.env.REACT_APP_BASEURL}/reports/rules-byuser`,
        'rules_total_report_by_client': `${process.env.REACT_APP_BASEURL}/reports/rulessummary-byuser`
      };

      baseUrlReports = reportUrls[reportKey] || `${process.env.REACT_APP_BASEURL}/reports/client/${reportKey}`;

      let urlGetReports = buildUrl(baseUrlReports)

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/reports.json' : urlGetReports, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.data) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateReports(aux))
            setIsLoading(false)
          }
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [reportState, reportKey, reportQueryState, isLoading])

  return reportState.reports
}

export const exportReports = (paramLimit, accessToken, queryStateReport, keyReports) => {
  const {
    limit,
    offset,
    page,
    fromDate,
    toDate,
    emailUser,
    actionResult,
    actionType,
    affectedUser,
    abmAction,
    reportRoleName,
    affectedUser2,
    status,
    extension,
    collection,
  } = queryStateReport

  const urlExporReportTransactions = `${process.env.REACT_APP_BASEURL}/dataExport/retrieveData/reports/?keyReports=${keyReports}`

  function buildUrl(baseUrl) {
    let url = baseUrl
    if (paramLimit !== undefined) {
      url += `&limit=${paramLimit}`
    }
    if (fromDate && toDate) {
      url += `&fromDate=${fromDate}&toDate=${toDate}`
    }
    if (emailUser) {
      url += `&emailUser=${emailUser}`
    }
    if (actionResult) {
      url += `&actionResult=${actionResult}`
    }
    if (actionType) {
      url += `&actionType=${actionType}`
    }
    if (affectedUser) {
      url += `&affectedUser=${affectedUser}`
    }
    if (abmAction) {
      url += `&abmAction=${abmAction}`
    }
    if (reportRoleName) {
      url += `&reportRoleName=${reportRoleName}`
    }
    if (affectedUser2) {
      url += `&affectedUser2=${affectedUser2}`
    }
    if (status) {
      url += `&status=${status}`
    }
    if (extension) {
      url += `&extension=${extension}`
    }
    if (collection) {
      url += `&collection=${collection}`
    }
    return url
  }

  let urlGetReports = buildUrl(urlExporReportTransactions)

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  fetch(process.env.REACT_APP_IS_DEMO ? '../../data/reports.json' : urlGetReports, options)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`)
      }
    })
    .catch((error) => {
      console.error('[HOOK: transactionExport] --> ', error)
    })
}
