import './datatable.scss'
import { Link, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Status } from '../../utilities/Status'
import { RiskLevel } from '../../utilities/RiskLevel'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { useDispatch, useSelector } from 'react-redux'

const DatatableKyb = ({ page, setPage, rowsPerPage, setRowsPerPage, kyb, setModalOpen, setIsLoading, hasTabs }) => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const {
    currentCountry
  } = useSelector(state => state.kybCountry)
  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      renderCell: (params) => {
        return <Typography variant="number">{params.row._id}</Typography>
      },
    },
    {
      field: 'name',
      headerName: t('common:companyName'),
      renderCell: (params) => {
        return params.row.companyDetails.name
      },
    },
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row.companyDetails.documentNumber}</Typography>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return <Status type={params.row.status} />
      },
    },
    {
      field: 'riskLevel',
      headerName: t('common:riskLevel'),
      renderCell: (params) => {
        return <RiskLevel type={params.row.riskLevel} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      renderCell: (params) => {
        return (
          <Link title={params.row._id} href={`/kyb/${currentCountry}/detail/${params.row._id}`} onClick={() => {
            dispatch(setIsLoading(true));
          }}>
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
  ]

  return (
    <DataGridComponent
      rows={Array.isArray(kyb?.data) && kyb?.data?.length && kyb?.data}
      columns={columns}
      count={kyb?.pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
      hasTabs={hasTabs}
    />
  )
}

export default DatatableKyb
