import { Box, Button, Collapse, Paper, Typography } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatatableBatch from '../../components/common/datatable/DatatableBatch'
import useBatches from '../pages-hooks/useBatches'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import Spinner from '../../components/common/spinner/spinner'
import ModalCreteBatch from './components/ModalCreateBatch'

import '../home/home.scss'
import './batch.scss'

function Batch() {
  const { t } = useTranslation(['common'])
  const [isLoading, setIsLoading] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const [alert, setAlert] = useState({})
  const [showCreateBatch, setShowCreateBatch] = useState(false)
  const { refreshBatches, createBatch } = useBatches(setIsLoading)

  const handleCreateBatch = async (batchType, file) => {
    const response = await createBatch(batchType, file)
    if (response) {
      setAlert({
        message: response.message,
        severity: response.severity,
      })
      setOpenAlert(true)
    }
  }

  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false)
      }, 2500)
    }
  }, [openAlert])

  useEffect(() => () => refreshBatches(), [])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      <Collapse in={openAlert}>
        <AlertFetchSpinner message={alert.message} spinner={isLoading} error={alert.severity} />
      </Collapse>
      <ModalCreteBatch
        showCreateBatch={showCreateBatch}
        setShowCreateBatch={setShowCreateBatch}
        handleCreateBatch={handleCreateBatch}
      />
      <Box>
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:batchLoading')}</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setShowCreateBatch(true)
              }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              {`${t('common:CREATE_BATCH')}`}
            </Button>
          </Box>
        </Box>
        <Box className="batch_table">
          <Paper elevation={0}>
            <DatatableBatch isLoading={isLoading} setIsLoading={setIsLoading} />
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

export default Batch
