/* eslint-disable react-hooks/exhaustive-deps */
import './rule.scss'

import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import '../home/home.scss'
import MyRules from '../../components/kyt/rules/myRules/MyRules'
import RequestRule from '../../components/kyt/rules/requestRule/RequestRule'
import { useRules } from '../pages-hooks/useRules'
import { useInstanceRules } from '../pages-hooks/useIntanceRules'
import { useDispatch, useSelector } from 'react-redux'
import { updateInstanceRules } from '../../features/rules/rulesSlice'
import RulesLibrary from '../../components/kyt/rules/rulesLibrary/RulesLibrary'
import { Button, Chip, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import Spinner from '../../components/common/spinner/spinner'

const Rule = () => {
  const { activeServices } = useSelector((state) => state.login)
  const { actualTab } = useParams()
  const [value, setValue] = useState('library')
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const [isLoading, setIsLoading] = useState(false)
  const [rules, setRules] = useState([])
  const [instanceRules, setInstanceRules] = useState([])

  useRules(isLoading, setIsLoading, rules, setRules)
  useInstanceRules(isLoading, setIsLoading, rules, instanceRules, setInstanceRules)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!actualTab || (actualTab !== 'library' && actualTab !== 'my-rules' && actualTab !== 'request-new')) {
      navigate('/rules/library')
      setValue('library')
    } else {
      navigate(`/rules/${actualTab}`)
      setValue(actualTab)
    }
  }, [])

  const handleChange = (event, newValue) => {
    setIsLoading(true)
    navigate(`/rules/${newValue}`)
    setValue(newValue)
    setIsLoading(false)
  }

  const handleChangeRemote = (newValue) => {
    navigate(`/rules/${newValue}`)
    setValue(newValue)
  }

  useEffect(() => {
    dispatch(updateInstanceRules([...instanceRules]))
  }, [instanceRules])

  const verifyCreation = () => {
    let aux = [false, false]
    activeServices?.map((path) => {
      if (path.includes('retrieveClientRules')) aux[0] = true
      else if (path.includes('retrieveClientRuleById')) aux[1] = true

      return aux
    })
    if (aux[0] && aux[1]) return true
    else return false
  }

  const verifyMyRules = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('retrieveClientInstanceRules')) aux = true
      return aux
    })
    return aux
  }

  const verifyRequestNew = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('sendSlackMessage')) aux = true
      return aux
    })
    return aux
  }

  const verifyEdition = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('updateClientInstanceRule')) aux = true
    })
    return aux
  }

  const verifyDeletion = () => {
    let aux = false
    activeServices?.map((path) => {
      if (path.includes('deleteClientInstanceRule')) aux = true
    })
    return aux
  }

  useEffect(() => {
    if (actualTab && actualTab === 'library' && !verifyCreation()) {
      navigate('/rules/my-rules')
      setValue('my-rules')
    } else if (actualTab && actualTab === 'my-rules' && !verifyMyRules()) {
      navigate('/rules/request-new')
      setValue('request-new')
    } else if (actualTab && actualTab === 'request-new' && !verifyRequestNew()) {
      navigate('/')
      setValue('')
    }
  }, [actualTab])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}
        <Box className="component-title-wrapper">
          <Box className="component-title">
            <Typography variant="h2">{t('common:rules')}</Typography>

            {/* A confirmar si se quiere exportar la lista de reglas */}
            {/* {activeServices.includes('reportsitem') && (
              <Button
                variant="outlined"
                disabled={!activeServices.includes('reportsitem')}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              >
                <Typography variant="title2">Export List</Typography>
              </Button>
            )} */}
          </Box>
          <Typography variant="subtitle3">
            {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
          </Typography>
        </Box>

        <TabContext value={value}>
          <TabList
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ marginBottom: '1rem' }}
            onChange={handleChange}
          >
            {verifyMyRules() && (
              <Tab
                label={
                  <Chip label={t('common:myRules')} variant={value === 'my-rules' ? 'sliderSelected' : 'slider'} />
                }
                value="my-rules"
              />
            )}
            {verifyCreation() && (
              <Tab
                label={
                  <Chip label={t('common:rulesLibrary')} variant={value === 'library' ? 'sliderSelected' : 'slider'} />
                }
                value="library"
              />
            )}
          </TabList>

          <Paper elevation={0}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
              <Typography variant="title">{t('common:rules')}</Typography>
              {verifyRequestNew() && (
                <Button onClick={() => setValue('request-new')} variant="contained">
                  Request Rule
                </Button>
              )}
            </Box>
            {verifyCreation() && (
              <TabPanel value="library">
                <RulesLibrary
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  rules={rules}
                  instanceRules={instanceRules}
                  setInstanceRules={setInstanceRules}
                  handleChangeRemote={handleChangeRemote}
                />
              </TabPanel>
            )}
            {verifyMyRules() && (
              <TabPanel value="my-rules">
                <MyRules
                  verifyEdition={verifyEdition}
                  verifyDeletion={verifyDeletion}
                  isLoading={isLoading}
                  rules={rules}
                  instanceRules={instanceRules}
                  setIsLoading={setIsLoading}
                  setInstanceRules={setInstanceRules}
                  handleChangeRemote={handleChangeRemote}
                />
              </TabPanel>
            )}
            {verifyRequestNew() && (
              <TabPanel value="request-new">
                <RequestRule aux={'P1'} />
              </TabPanel>
            )}
          </Paper>
        </TabContext>
      </Box>
    </Box>
  )
}

export default Rule
