import React, { useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import { useSelector } from 'react-redux'

function MyDatePicker({ selectedDate, setSelectedDate }) {
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
    >
      <DatePicker
        fullWidth
        value={dayjs(selectedDate)}
        inputFormat="es"
        onChange={(newValue) => setSelectedDate(newValue ? newValue['$d'] : null)}
      />
    </LocalizationProvider>
  )
}

export default MyDatePicker
