import '../home/home.scss'
import '../onboarding/onboarding.scss'
import './kyb.scss'
import { Autocomplete, Box, Chip, CircularProgress, Fab, Grid, InputAdornment, Modal, Paper, Tab, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateKybFilters } from '../../features/kyb/kybSlice'
import DatatableKyb from '../../components/common/datatable/DatatableKyb'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useKyb } from '../pages-hooks/useKyb'
import { useKyc } from '../pages-hooks/useKyc'
import DatatableKyc from '../../components/common/datatable/DatatableKyc'
import { updateKycFilters } from '../../features/kyc/kycSlice'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { kybStatusEnum } from './kyb.enums'
import CountrySelector from '../../components/kyb/countrySelector'
import {
  setCurrentCountry,
  setIsLoading
} from '../../features/kyb/kybCountrySlice'
import { FilterModal } from '../../components/utilities/FilterModal'
import Spinner from '../../components/common/spinner/spinner'
import useKybCountryCodes from '../pages-hooks/useKybCountryCodes'

const Kyb = () => {
  const { tab } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const kybQueryState = useSelector((state) => state.kyb.filters)
  const kycQueryState = useSelector((state) => state.kyc.filters)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [toDate, setToDate] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [actualTab, setActualTab] = useState('AllKybs')
  const [selectedTab, setSelectedTab] = useState('kyb')
  const isKybs = actualTab === 'MyKybs' || tab === 'AllKybs'
  const isKycs = actualTab === 'MyKycs' || tab === 'AllKycs'
  const [checkId, setCheckId] = useState(isKybs ? kybQueryState.checkId : kycQueryState.checkId)
  const [status, setStatus] = useState(isKybs ? kybQueryState.status : kycQueryState.status)
  const [name, setName] = useState(isKybs ? kybQueryState.name : kycQueryState.name)
  const [selectedDate, setSelectedDate] = useState(isKybs ? kybQueryState.fromDate : kycQueryState.fromDate)
  const [documentNumber, setDocumentNumber] = useState(
    isKybs ? kybQueryState.documentNumber : kycQueryState.documentNumber,
  )
  const [nationality, setNationality] = useState(kycQueryState.nationality)
  const [selectedFilters, setSelectedFilters] = useState([])
  useKybCountryCodes()
  const {
    currentCountry,
    listOfAvailableKybCountryCodes,
    isLoading
  } = useSelector((state) => state.kybCountry);
  useKyb(
    actualTab === 'MyKybs'
  )

  useKyc(actualTab === 'MyKycs')

  const kybs = useSelector((state) => state.kyb.kyb)
  const kycs = useSelector((state) => state.kyc.kyc)
  
  const handleResetButton = () => {
    if (checkId !== '') setCheckId('')
    if (status) setStatus('ALL')
    if (name) setName('')
    if (documentNumber) setDocumentNumber('')
    if (nationality) setNationality('')
    if (selectedDate) setSelectedDate(null)
    setPage(0)
    setIsLoading(true)
    setSelectedFilters([])

    if (isKybs) {
      return dispatch(
        updateKybFilters({
          page: 0,
          limit: 25,
          offset: 0,
          checkId: '',
          status: 'ALL',
          name: '',
          fromDate: null,
          toDate: '',
          documentNumber: '',
        }),
      )
    }

    if (isKycs) {
      return dispatch(
        updateKycFilters({
          page: 0,
          limit: 25,
          offset: 0,
          checkId: '',
          status: 'ALL',
          name: '',
          fromDate: null,
          toDate: '',
          documentNumber: '',
          nationality: '',
        }),
      )
    }
  }

  const handleDispatch = () => {
    setIsLoading(true)
    if (isKybs) {
      return dispatch(
        updateKybFilters({
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          page,
          checkId,
          status,
          name,
          fromDate: selectedDate ?? selectedDate?.toISOString(),
          toDate: new Date(toDate)?.toISOString(),
          documentNumber,
        }),
      )
    }

    if (isKycs) {
      return dispatch(
        updateKycFilters({
          limit: rowsPerPage,
          offset: rowsPerPage * page,
          page,
          checkId,
          status,
          name,
          fromDate: selectedDate ?? selectedDate?.toISOString(),
          toDate: new Date(toDate)?.toISOString(),
          documentNumber,
          nationality,
        }),
      )
    }
  }

  const handleChange = (event, newValue) => {
    handleResetButton()
    navigate(`/kyb/${newValue}`)
  }

  const handleChangeSelectedTab = (event, newValue) => {
    handleResetButton()
    setSelectedTab(newValue)
    if (newValue === 'kyb') {
      navigate(`/kyb/AllKybs`)
      setActualTab('AllKybs')
    } else {
      navigate(`/kyb/AllKycs`)
      setActualTab('AllKycs')
    }
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate)
      setToDate(selectedDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [selectedDate])

  useEffect(() => {
    if (!tab || (tab !== 'AllKybs' && tab !== 'MyKybs' && tab !== 'AllKycs' && tab !== 'MyKycs')) {
      setActualTab('AllKybs')
    } else {
      if (tab === 'AllKycs' || tab === 'MyKycs') {
        setSelectedTab('kyc')
      }
      if (tab === 'AllKybs' || tab === 'MyKybs') setSelectedTab('kyb')

      setActualTab(tab)
    }
  }, [tab])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}

        {selectedTab === 'kyb' ? (
          <Box className="component-title-wrapper">
            <Box className="component-title">
              <Typography variant="h2">
                {t('common:kyb')} - {t('common:knowYourBusiness')}
              </Typography>
              <CountrySelector
                  currentCountry={currentCountry}
                  listOfAvailableKybCountryCodes={listOfAvailableKybCountryCodes}
                  t={t}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
            </Box>
            <Typography variant="subtitle3">
              {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
            </Typography>
          </Box>
        ) : (
          <Box className="component-title-wrapper">
            <Box className="component-title">
              <Typography variant="h2">
                {t('common:kycM')} - {t('common:knowYourClient')}
              </Typography>
              <CountrySelector
                  currentCountry={currentCountry}
                  listOfAvailableKybCountryCodes={listOfAvailableKybCountryCodes}
                  t={t}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
            </Box>
            <Typography variant="subtitle3">
              {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
            </Typography>
          </Box>
        )}
        <TabContext value={selectedTab}>
          <TabList
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ marginBottom: '1rem' }}
            onChange={handleChangeSelectedTab}
          >
            <Tab
              label={
                <Chip
                  label={t('common:knowYourBusiness')}
                  variant={selectedTab === 'kyb' ? 'sliderSelected' : 'slider'}
                />
              }
              value="kyb"
            ></Tab>
            <Tab
              label={
                <Chip
                  label={t('common:knowYourClient')}
                  variant={selectedTab === 'kyc' ? 'sliderSelected' : 'slider'}
                />
              }
              value="kyc"
            />
          </TabList>

          <Paper elevation={0}>
            <TabPanel value="kyb">
              <TabContext value={actualTab}>
                <TabList onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                  <Tab label={t('common:allKYB')} value="AllKybs" />
                  <Tab label={t('common:myKYB')} value="MyKybs" />
                </TabList>
                <TabPanel value="AllKybs">
                  <DatatableKyb
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    kyb={kybs}
                    setModalOpen={setModalOpen}
                    hasTabs="27rem"
                  />
                </TabPanel>
                <TabPanel value="MyKybs">
                  <DatatableKyb
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    kyb={kybs}
                    setModalOpen={setModalOpen}
                    hasTabs="27rem"
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>

            <TabPanel value="kyc">
              <TabContext value={actualTab}>
                <TabList onChange={handleChange} sx={{ marginBottom: '1rem' }}>
                  <Tab label={t('common:allKYC')} value="AllKycs" />
                  <Tab label={t('common:myKYC')} value="MyKycs" />
                </TabList>

                <TabPanel value="AllKycs">
                  <DatatableKyc
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    kyc={kycs}
                    setModalOpen={setModalOpen}
                    hasTabs="27rem"
                  />
                </TabPanel>
                <TabPanel value="MyKycs">
                  <DatatableKyc
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    kyc={kycs}
                    setModalOpen={setModalOpen}
                    hasTabs="27rem"
                  />
                </TabPanel>
              </TabContext>
            </TabPanel>
          </Paper>
        </TabContext>

        <FilterModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleResetButton={handleResetButton}
          setPage={setPage}
          handleDispatch={handleDispatch}
        >
          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t('common:status')}:
              </Typography>
              <Grid
                container
                spacing={1}
                sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
              >
                {kybStatusEnum?.map((item, index) => (
                  <Grid item key={index}>
                    <Button
                      onClick={() => {
                        setSelectedFilters((prevFilters) => {
                          const updatedFilters = prevFilters.filter((filter) => !kybStatusEnum.includes(filter))
                          return item !== 'ALL' ? [...updatedFilters, item] : updatedFilters
                        })
                        return setStatus(item)
                      }}
                      variant={status === item ? 'action' : 'label'}
                    >
                      {t(`common:${item}`)}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
              <TextField
                placeholder={t('common:searchById')}
                required
                fullWidth
                value={checkId}
                onChange={(event) => setCheckId(event.target.value)}
                size="small"
              />
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t('common:document')}:
              </Typography>
              <TextField
                placeholder={t('common:searchByDocument')}
                required
                fullWidth
                value={documentNumber}
                onChange={(event) => setDocumentNumber(event.target.value)}
                size="small"
              />
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t('common:name')}:
              </Typography>
              <TextField
                placeholder={t('common:searchByName')}
                required
                fullWidth
                value={name}
                onChange={(event) => setName(event.target.value)}
                size="small"
              />
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:by')} {t('common:date')}:
              </Typography>

              <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            </Box>
          </Box>
        </FilterModal>
      </Box>
    </Box>
  )
}

export default Kyb
