import '../home/home.scss'
import '../aml/aml.scss'
import './manualCheck.scss'
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
} from '@mui/material'
import './kyb.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flags } from '../../components/common/flag/flags'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import { useTranslation } from 'react-i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { countryDictionary } from '../../components/common/flag/countries'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'
import CountrySelector from '../../components/kyb/countrySelector'
import useKybCountryCodes from '../pages-hooks/useKybCountryCodes'
import { kybChecksEnum, shareholderChecksEnum } from './kyb.enums'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'

const KybManualCheck = () => {
  const location = useLocation()
  const { accessToken } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])
  const { countrySegment, currentCountry, listOfAvailableKybCountryCodes, isLoading } = useSelector(
    (state) => state.kybCountry,
  )
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedTab, setSelectedTab] = useState(location.pathname.includes('kyc') ? 'person' : 'company')
  const navigate = useNavigate()
  const [documentId, setDocumentId] = useState('')
  const [documentIdPerson, setDocumentIdPerson] = useState('')
  const [name, setName] = useState('')
  const [nationality, setNationality] = useState('')
  const [finish, setFinish] = useState(false)
  const [result, setResult] = useState({})
  const dispatch = useDispatch()
  const [runChecks, setRunChecks] = useState(true)
  const [kybChecks, setKybChecks] = useState(kybChecksEnum[currentCountry])
  const [shareholderChecks, setShareholderChecks] = useState(shareholderChecksEnum[currentCountry])

  useKybCountryCodes()

  useEffect(() => {
    setKybChecks(kybChecksEnum[currentCountry])
    setShareholderChecks(shareholderChecksEnum[currentCountry])
    if(isLoading) dispatch(setIsLoading(false))
  }, [currentCountry])

  const handleSubmit = () => {
    setIsFetching(true)
    setFinish(false)
    setErrorMessage('')

    if (!process.env.REACT_APP_IS_DEMO) {
      let body

      let url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}`

      if (selectedTab === 'company') {
        body = {
          documentNumber: documentId,
        }
      } else {
        body = {
          documentNumber: documentIdPerson,
          name,
          nationality,
        }

        url += 'subject'
      }

      if (runChecks) {
        body.runChecks = runChecks
        body.kybChecks = kybChecks
        body.shareholderChecks = shareholderChecks
      }

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          if (res.success && res.data) {
            setResult(res.data)
            setFinish(true)
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? t('messages:documentNotFound') : t('messages:unexpectedErrorValues'))
        })
    } else {
      fetch('../../data/kybDetails.json')
        .then((res) => res.json())
        .then((res) => {
          setIsFetching(false)
          setResult(res.data)
          setFinish(true)
        })
        .catch((err) => {
          console.error('[DOWNLOAD REPORT ERROR] --> ', err)
          setIsFetching(false)
          setErrorMessage(err.message ? t('messages:documentNotFound') : t('messages:unexpectedErrorValues'))
        })
    }
  }

  const handleReset = () => {
    if (selectedTab === 'company') {
      setDocumentId('')
    } else if (selectedTab === 'person') {
      setName('')
      setNationality('')
      setDocumentIdPerson('')
    }

    setFinish(false)
    setErrorMessage('')
  }

  const handleChangeSelectedTab = (event, newValue) => {
    handleReset()
    setSelectedTab(newValue)
  }

  useEffect(() => {
    if (selectedTab === 'person') {
      navigate(`/kyc/manual`)
    } else if (selectedTab === 'company') {
      navigate(`/kyb/manual`)
    }
  }, [selectedTab])

  return (
    <Box className={`${isFetching && 'spinner-transition'} filter`}>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:manualCheck')}</Typography>
          <CountrySelector
            currentCountry={currentCountry}
            listOfAvailableKybCountryCodes={listOfAvailableKybCountryCodes}
            t={t}
            isLoading={isLoading}
          />
        </Box>
        <Typography variant="h5">{/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}</Typography>
      </Box>

      <TabContext value={selectedTab}>
        <TabList
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          onChange={handleChangeSelectedTab}
          sx={{ marginBottom: '1rem' }}
        >
          <Tab
            className="tab-heading"
            label={
              <Chip
                label={t('common:personManualCheck')}
                variant={selectedTab === 'person' ? 'sliderSelected' : 'slider'}
              />
            }
            value="person"
          ></Tab>
          <Tab
            className="tab-heading"
            label={
              <Chip
                label={t('common:companyManualCheck')}
                variant={selectedTab === 'company' ? 'sliderSelected' : 'slider'}
              />
            }
            value="company"
          />
        </TabList>

        <Paper elevation={0}>
          <TabPanel value="person">
            <Box className="kyb-manual">
              <Box className="search-bar">
                <TextField
                  size="small"
                  value={documentIdPerson}
                  fullWidth
                  className="forceWidth"
                  onChange={(event) => setDocumentIdPerson(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    placeholder: t('common:searchByDocument'),
                  }}
                />

                <TextField
                  size="small"
                  value={name}
                  className="forceWidth"
                  fullWidth
                  onChange={(event) => setName(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    placeholder: t('common:searchByName'),
                  }}
                />

                <Select
                  size="small"
                  className="forceWidth"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                  placeholder={`${t('common:select')} ${t('common:nationality')}`}
                  displayEmpty
                  fullWidth
                  renderValue={(value) =>
                    value?.length ? countryDictionary[value] : `${t('common:select')} ${t('common:nationality')}`
                  }
                >
                  {Object.keys(countryDictionary)?.map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {countryDictionary[key]}
                      </MenuItem>
                    )
                  })}
                </Select>
                {(runChecks && shareholderChecks) && (
                  <Select
                    className="forceWidth"
                    size="small"
                    multiple
                    fullWidth
                    value={shareholderChecks}
                    onChange={(e) =>
                      setShareholderChecks(
                        typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,
                      )
                    }
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {shareholderChecksEnum[currentCountry]?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={shareholderChecks.indexOf(name) > -1} />
                        <Typography>{name} </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <Tooltip placement="top" title={t('common:runChecks')}>
                  <Checkbox
                    checked={runChecks}
                    onChange={(e) => {
                      setRunChecks(!runChecks)
                    }}
                  />
                </Tooltip>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={documentIdPerson === ''}
                    onClick={() => handleSubmit()}
                  >
                    {t('common:search')}
                  </Button>
                </Box>
              </Box>

              {isFetching && <ProcessingSpinner message={t('common:processing')} />}
              {errorMessage !== '' && (
                <Box className="security-error">
                  <Button size="small" variant="outlined" color="error">
                    {errorMessage}
                  </Button>
                </Box>
              )}
              {result && finish && errorMessage === '' && (
                <Box className="kyb-manual-result">
                  <Box className="filters">
                    <Typography variant="title">{t('common:result')}:</Typography>
                  </Box>
                  <Box className="kyb-manual-result-grid">
                    <Box className="sub-item">
                      <InputLabel>{t('common:referenceId')}</InputLabel>
                      <Typography
                        variant="subtitle3"
                        className="id-action"
                        onClick={() => navigate(`/kyc/detail/${result._id}`)}
                      >
                        {result._id ? result._id : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:documentId')}</InputLabel>
                      <Typography variant="subtitle3">
                        {result?.documentNumber ? result.documentNumber : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:name')}</InputLabel>
                      <Typography variant="subtitle3">{result?.name ? result.name : '---'}</Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:nationality')}</InputLabel>
                      <Typography variant="subtitle3">{result?.nationality ? result.nationality : '---'}</Typography>
                    </Box>
                  </Box>
                  <Box className="kyb-manual-button">
                    <Button
                      onClick={() => {
                        dispatch(setIsLoading(true))
                        navigate(`/kyc/${currentCountry}/detail/${result._id}`)
                      }}
                      size="small"
                      variant="contained"
                    >
                      {t('common:viewDetails')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </TabPanel>

          <TabPanel value="company">
            <Box className="kyb-manual">
              <Box className="search-bar">
                <TextField
                  id="outlined-start-adornment"
                  size="small"
                  fullWidth
                  className="forceWidth"
                  value={documentId}
                  onChange={(event) => {
                    if (currentCountry === 'CO') {
                      if (event.target.value.length > 9) return
                      const onlyNumbers = new RegExp('^[0-9]+$')
                      // validacion de solo numeros y permitir que el string vacio sea un caso
                      if (!onlyNumbers.test(event.target.value) && event.target.value !== '') return
                    }
                    setDocumentId(event.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                    placeholder: t('common:searchByDocument'),
                  }}
                />
                {(runChecks && kybChecks) && (
                  <Select
                    className="forceWidth"
                    size="small"
                    multiple
                    fullWidth
                    value={kybChecks}
                    onChange={(e) =>
                      setKybChecks(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
                    }
                    placeholder={t('common:kybChecks')}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {kybChecksEnum[currentCountry]?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={kybChecks.indexOf(name) > -1} />
                        <Typography>{name} </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <Tooltip placement="top" title={t('common:runChecks')}>
                  <Checkbox
                    checked={runChecks}
                    onChange={(e) => {
                      setRunChecks(!runChecks)
                    }}
                  />
                </Tooltip>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={documentId === ''}
                    onClick={() => handleSubmit()}
                  >
                    {t('common:search')}
                  </Button>
                </Box>
              </Box>

              {isFetching && <ProcessingSpinner message={t('common:processing')} />}
              {errorMessage !== '' && (
                <Box className="security-error">
                  <Button size="small" variant="outlined" color="error">
                    {errorMessage}
                  </Button>
                </Box>
              )}
              {result && finish && errorMessage === '' && (
                <Box className="kyb-manual-result">
                  <Box className="filters">
                    <Typography variant="title">{t('common:result')}:</Typography>
                  </Box>
                  <Box className="kyb-manual-result-grid">
                    <Box className="sub-item">
                      <InputLabel>{t('common:referenceId')}</InputLabel>
                      <Typography
                        variant="subtitle3"
                        className="id-action"
                        onClick={() => navigate(`/kyb/detail/${result._id}`)}
                      >
                        {result._id ? result._id : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:documentNumber')}</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.documentNumber ? result.companyDetails.documentNumber : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:companyName')}</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.name ? result.companyDetails.name : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:country')}</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.country ? Flags(result.companyDetails.country) : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>CNPJ</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.cnpj ? result.companyDetails.cnpj : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:dateOfRegistration')}</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.dateOfRegistration ? result.companyDetails.dateOfRegistration : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>Emails</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.emails?.length
                          ? result.companyDetails?.emails.map((email) => {
                              return <p>{email}</p>
                            })
                          : '---'}
                      </Typography>
                    </Box>
                    <Box className="sub-item">
                      <InputLabel>{t('common:addresses')}</InputLabel>
                      <Typography variant="subtitle3">
                        {result.companyDetails?.addresses?.length
                          ? result.companyDetails?.addresses.map((element, index) => {
                              return (
                                <Box style={{ marginBottom: '1rem' }} key={index}>
                                  {Object.keys(element).map((item, index) => {
                                    return (
                                      <Box key={index}>
                                        <Typography variant="subtitle3">{camelCaseFormatter(item)}:</Typography>
                                        {element[item]}
                                      </Box>
                                    )
                                  })}
                                </Box>
                              )
                            })
                          : '---'}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="kyb-manual-button">
                    <Button
                      onClick={() => {
                        dispatch(setIsLoading(true))
                        navigate(`/kyb/${currentCountry}/detail/${result._id}`)
                      }}
                      size="small"
                      variant="contained"
                    >
                      {t('common:viewDetails')}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </TabPanel>
        </Paper>
      </TabContext>
    </Box>
  )
}

export default KybManualCheck
