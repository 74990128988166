import { createSlice } from '@reduxjs/toolkit';
import { getCountrySegment } from '../../pages/kyb/kyb.hook-helpers';

export const kybCountrySlice = createSlice({
  name: 'kybCountry',
  initialState: {
    currentCountry: '',
    listOfAvailableKybCountryCodes: [],
    countrySegment: '',
    isLoading: false,
    fetchError: false,
    fetchMessage: '',
    errorMessage: '',
  },
  reducers: {
    setCurrentCountry(state, action) {
      state.currentCountry = action.payload;
      state.countrySegment = getCountrySegment(action.payload);
      state.isLoading = true;
    },
    setListOfAvailableKybCountryCodes: (state, action) => {
      state.listOfAvailableKybCountryCodes = action.payload;
    },
    setCountrySegment: (state, action) => {
      state.countrySegment = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFetchError: (state, action) => {
      state.fetchError = action.payload;
    },
    setFetchMessage: (state, action) => {
      state.fetchMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setCurrentCountry,
  setListOfAvailableKybCountryCodes,
  setCountrySegment,
  setIsLoading,
  setFetchError,
  setFetchMessage,
  setErrorMessage,
} = kybCountrySlice.actions;

export default kybCountrySlice.reducer;
