import { useEffect, useCallback } from 'react'
import { performSetSecurityParameters } from '../../features/auth/loginSlice'
import { useSelector, useDispatch } from 'react-redux'
import { getCountrySegment } from '../kyb/kyb.hook-helpers'
import {
  setCurrentCountry,
  setListOfAvailableKybCountryCodes,
  setIsLoading,
  setFetchError,
  setFetchMessage,
  setErrorMessage,
  setCountrySegment,
} from '../../features/kyb/kybCountrySlice'

const useKybCountryCodes = () => {
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const { currentCountry, listOfAvailableKybCountryCodes } = useSelector((state) => state.kybCountry)
  const dispatch = useDispatch()

  const handleFetch = (error, message) => {
    dispatch(setIsLoading(true))
    dispatch(setFetchError(error))
    dispatch(setFetchMessage(message))
    setTimeout(() => {
      dispatch(setIsLoading(false))
    }, 3000)
  }

  const getParameters = useCallback(() => {
    dispatch(setIsLoading(true))
    const urlUserMaster = `${process.env.REACT_APP_BASEURL}/usermaster/${emailClient}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/usermaster.json' : urlUserMaster, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.data === 'Forbidden resource') {
          dispatch(setErrorMessage('You do not have permission to access this resource.'))
        }
        if (!res.success && res.message && res.message.length !== 0) {
          handleFetch(true, res.message || 'Error updating security parameters')
          throw new Error(res.message)
        } else {
          dispatch(setErrorMessage(''))
          dispatch(setListOfAvailableKybCountryCodes(res.kybCountries ?? ['BR']))
          dispatch(performSetSecurityParameters(res))
        }
        dispatch(setIsLoading(false))
      })
      .catch((error) => {
        console.error('[GET SECURITY PARAMETERS ERROR] --> ', error)
        handleFetch(true, error.message || 'Error updating security parameters')
        dispatch(setIsLoading(false))
      })
  }, [accessToken, emailClient])

  useEffect(() => {
    if (listOfAvailableKybCountryCodes?.length === 0) {
      getParameters()
    }
  }, [])

  useEffect(() => {
    if (currentCountry === '' && listOfAvailableKybCountryCodes?.length > 0) {
      if (listOfAvailableKybCountryCodes.length > 0) {
        dispatch(setCurrentCountry(listOfAvailableKybCountryCodes[0]))
      } else {
        dispatch(setCurrentCountry('BR'))
      }
    }
  }, [listOfAvailableKybCountryCodes])

  useEffect(() => {
    if (currentCountry !== '') {
      const newCountrySegment = getCountrySegment(currentCountry)
      dispatch(setCountrySegment(newCountrySegment))
    }
  }, [currentCountry])
}

export default useKybCountryCodes
