import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useEffect } from 'react'
import { MenuProps } from '../../../../utilities/MenuProps'
import { Box } from '@mui/material'
import './selectMultiple.scss'

const SelectMultiple = ({ propertie, ruleParameters, setRuleParameters, posibleValues, isEditing, filterTemplate }) => {
  const [selectedValue, setSelectedValue] = useState([])
  const [change, setChange] = useState(false)

  const getIndex = () => {
    if (Array.isArray(ruleParameters) && ruleParameters?.length === 0) return -1
    if (Array.isArray(ruleParameters)) return ruleParameters?.map((e) => e.filterTemplate).indexOf(filterTemplate)
    return -1
  }

  useEffect(() => {
    const aux = ruleParameters.find((e) => e.filterTemplate === filterTemplate)
    if (aux) {
      setSelectedValue(aux[propertie])
    }
  }, [propertie])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    setSelectedValue(typeof value === 'string' ? value.split(',') : value)
    setChange(true)
  }

  useEffect(() => {
    const index = getIndex()

    if (change) {
      let all = [...ruleParameters]
      let previous = index >= 0 && all[index] ? { ...all[index] } : {}

      previous[propertie] = selectedValue
      previous.filterTemplate = filterTemplate

      if (index !== -1) {
        all.splice(index, 1)
      }
      all.push(previous)

      setRuleParameters([...all])
    }
    setChange(false)
  }, [selectedValue])

  return (
    <Select
      fullWidth
      size="small"
      multiple
      displayEmpty
      disabled={!isEditing}
      value={selectedValue}
      onChange={handleChange}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        return selected.join(', ')
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {posibleValues?.map((name) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectMultiple
