import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateKybList } from '../../features/kybLists/kybListsSlice';

export const useKybList = (setIsLoading, isLoading, type) => {
  const queryState = useSelector((state) => state.kybList?.filters);
  const { limit, offset, fromDate, toDate, page, id, status, name, documentNumber } = queryState || {};
  const { accessToken } = useSelector((state) => state.login);
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry);

  const dispatch = useDispatch();

  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`;
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`;
    if (id?.length) url += `&id=${id}`;
    if (status?.length) url += `&status=${status}`;
    if (name?.length) url += `&name=${name}`;
    if (documentNumber?.length) url += `&documentNumber=${documentNumber}`;

    return url;
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrlKyb = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}list/g${type}`;
      let url = buildUrl(baseUrlKyb);

      fetch(
        process.env.REACT_APP_IS_DEMO
          ? type === 'CNPJ'
            ? '../../data/kybListCnpj.json'
            : '../../data/kybListCpf.json'
          : url,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const { data, pagination } = res;
            const aux = { data, pagination };
            dispatch(updateKybList(aux));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error('[HOOK: useKybList] --> ', error);
          setIsLoading(false);
        });
    }
  }, [isLoading, type]);
};
