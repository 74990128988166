import './user.scss'
import '../../home/home.scss'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import {
  Box,
  TextField,
  Autocomplete,
  Paper,
  Typography,
  Tab,
  Chip,
  TableContainer,
  InputAdornment,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Button from '@mui/material/Button'
import DatatableUser from '../../../components/common/datatable/DatatableUser'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUsersFilters } from '../../../features/users/userSlice'
import { useSearchParams } from 'react-router-dom'
import Spinner from '../../../components/common/spinner/spinner'
import { countryDictionary } from '../../../components/common/flag/countries'
import { useTranslation } from 'react-i18next'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  kytConsumerUserDataMapper,
  kytConsumerUserDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/kytConsumerUserDataMapper'
import {
  kytBusinessUserDataMapper,
  kytBusinessUserDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/kytBusinessUserDataMapper'
import dayjs from 'dayjs'
import { FilterModal } from '../../../components/utilities/FilterModal'

const userStatePossible = ['UNACCEPTABLE', 'TERMINATED', 'ACTIVE', 'DORMANT', 'CREATED', 'SUSPENDED', 'BLOCKED']
const userKycStatusPossible = ['SUCCESSFUL', 'FAILED', 'NOT_STARTED', 'IN_PROGRESS', 'MANUAL_REVIEW']

const User = () => {
  const queryState = useSelector((state) => state.user.filters)
  const { activeServices } = useSelector((state) => state.login)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [userId, setUserId] = useState(queryState.userId)
  const [fromDate, setFromDate] = useState(null)
  const [fromTime, setFromTime] = useState('00:00')
  const [toDate, setToDate] = useState(null)
  const [toTime, setToTime] = useState('23:59')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [countryOfResidence, setCountryOfResidence] = useState('')
  const [countryOfResidenceView, setCountryOfResidenceView] = useState('')
  const [countryOfNationality, setCountryOfNationality] = useState('')
  const [countryOfNationalityView, setCountryOfNationalityView] = useState('')
  const [countryOfRegistration, setCountryOfRegistration] = useState('')
  const [countryOfRegistrationView, setCountryOfRegistrationView] = useState('')
  const [legalName, setLegalName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [userKey, setUserKey] = useState(queryState.userKey)
  const [userKeyPossible, setUserKeyPossible] = useState([])
  const [userValue, setUserValue] = useState(queryState.userValue)
  const [userValuePossible, setUserValuePossible] = useState([])
  const [selectedUserState, setSelectedUserState] = useState(queryState.userState)
  const [userKycStatus, setUserKycStatus] = useState(queryState.userKycStatus)
  const [documentNumber, setDocumentNumber] = useState(queryState.documentNumber)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [users, setUsers] = useState({})
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation(['common'])
  const [modalOpen, setModalOpen] = useState(false)
  const userState = useSelector((state) => state.user)
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  useEffect(() => {
    if (searchParams && searchParams.get('type') === 'consumerUsers') {
      setUserKeyPossible(userState?.consumerUsers?.unique?.uniqueKeys)
      setUserValuePossible(userState?.consumerUsers?.unique?.uniqueValues)
    }
    if (searchParams && searchParams.get('type') === 'businessUsers') {
      setUserKeyPossible(userState?.businessUsers?.unique?.uniqueKeys)
      setUserValuePossible(userState?.businessUsers?.unique?.uniqueValues)
    }
  }, [searchParams, userState])

  let isQueryOk =
    !userId &&
    !fromDate &&
    !firstName &&
    !middleName &&
    !lastName &&
    !countryOfResidence &&
    !countryOfNationality &&
    !legalName &&
    !countryOfRegistration &&
    !userKey &&
    !userValue &&
    !selectedUserState &&
    !userKycStatus &&
    !documentNumber

  const handleResetButton = () => {
    if (userId !== '') setUserId('')
    if (fromDate) setFromDate(null)
    if (toDate) setToDate(null)
    if (firstName !== '') setFirstName('')
    if (middleName !== '') setMiddleName('')
    if (lastName !== '') setLastName('')
    if (countryOfResidence !== '') setCountryOfResidence('')
    if (countryOfResidenceView !== '') setCountryOfResidenceView('')
    if (countryOfNationality !== '') setCountryOfNationality('')
    if (countryOfNationalityView !== '') setCountryOfNationalityView('')
    if (legalName !== '') setLegalName('')
    if (countryOfRegistration !== '') setCountryOfRegistration('')
    if (countryOfRegistrationView !== '') setCountryOfRegistrationView('')
    if (userKey !== '') setUserKey('')
    if (userValue !== '') setUserValue('')
    if (selectedUserState !== '') setSelectedUserState('')
    if (userKycStatus !== '') setUserKycStatus('')
    if (documentNumber !== '') setDocumentNumber('')
    setPage(0)
    setIsLoading(true)

    dispatch(
      updateUsersFilters({
        userId: '',
        page: 0,
        fromDate: null,
        toDate: null,
        viewMode: true,
        limit: 50,
        offset: 0,
        firstName: '',
        middleName: '',
        lastName: '',
        countryOfResidence: '',
        countryOfNationality: '',
        legalName: '',
        countryOfRegistration: '',
        userKey: '',
        userValue: '',
        documentNumber: '',
        selectedUserState: '',
        userKycStatus: '',
      }),
    )
  }

  const handleDispatch = () => {
    return dispatch(
      updateUsersFilters({
        userId,
        page,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        viewMode: true,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        firstName,
        middleName,
        lastName,
        countryOfResidence,
        countryOfNationality,
        legalName,
        countryOfRegistration,
        userKey,
        userValue,
        selectedUserState,
        userKycStatus,
        documentNumber,
      }),
    )
  }

  const handleTabChange = (newValue) => {
    setIsLoading(true)
    setSearchParams({ type: newValue })
    handleResetButton()
  }

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate?.setHours(0)
      formattedDate?.setMinutes(0)
      formattedDate?.setSeconds(0)
      setFromDate(formattedDate)
    }
  }, [toDate])

  useEffect(() => {
    if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate?.setHours(23)
      formattedDate?.setMinutes(59)
      formattedDate?.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate])

  const handleFromTimeChange = (time) => {
    let newDate = new Date(fromDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setFromTime(time)
    setFromDate(newDate)
  }

  const handleToTimeChange = (time) => {
    let newDate = new Date(toDate)
    const [hs, mins] = time.split(':')
    newDate.setHours(hs)
    newDate.setMinutes(mins)
    setToTime(time)
    setToDate(newDate)
  }

  const getKeyByValue = (value) => {
    for (const key in countryDictionary) {
      if (countryDictionary[key] === value) {
        return key
      }
    }
    return null
  }

  useEffect(() => {
    if (searchParams.get('type') === 'businessUsers') {
      setUsers({ ...userState.businessUsers })
    } else {
      setUsers({ ...userState.consumerUsers })
    }
    setIsLoading(false)
  }, [userState])

  return (
    <Box>
      <Box className={`${isLoading && 'spinner-transition'} filter`}>
        {isLoading && <Spinner noTransform />}

        {searchParams.get('type') === 'businessUsers' ? (
          <Box className="component-title-wrapper">
            <Box className="component-title">
              <Typography variant="h2">{t('common:business')}</Typography>
              {activeServices.includes('reportsitem') && (
                <Button
                  variant="outlined"
                  disabled={!activeServices.includes('reportsitem')}
                  onClick={() => setShowDownloadModal(true)}
                  endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                >
                  <Typography variant="title2">{t('common:exportReport')}</Typography>
                </Button>
              )}
            </Box>
            <Typography variant="subtitle3">
              {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
            </Typography>
          </Box>
        ) : (
          <Box className="component-title-wrapper">
            <Box className="component-title">
              <Typography variant="h2">{t('common:consumer')}</Typography>
              {activeServices.includes('reportsitem') && (
                <Button
                  variant="outlined"
                  disabled={!activeServices.includes('reportsitem')}
                  onClick={() => setShowDownloadModal(true)}
                  endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                >
                  <Typography variant="title2">{t('common:exportReport')}</Typography>
                </Button>
              )}
            </Box>
            <Typography variant="subtitle3">
              {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
            </Typography>
          </Box>
        )}

        <TabContext value={searchParams.get('type') ? searchParams.get('type') : 'consumerUsers'}>
          <TabList
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ marginBottom: '1rem' }}
            onChange={(e, newValue) => handleTabChange(newValue)}
          >
            <Tab
              label={
                <Chip
                  label={t('common:consumer')}
                  variant={searchParams.get('type') === 'consumerUsers' ? 'sliderSelected' : 'slider'}
                />
              }
              value="consumerUsers"
            />
            <Tab
              label={
                <Chip
                  label={t('common:business')}
                  variant={searchParams.get('type') === 'businessUsers' ? 'sliderSelected' : 'slider'}
                />
              }
              value="businessUsers"
            />
          </TabList>

          <Paper elevation={0}>
            <TabPanel value="consumerUsers">
              <TableContainer>
                <DatatableUser
                  setIsLoading={setIsLoading}
                  queryState={queryState}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  handleDispatch={handleDispatch}
                  setModalOpen={setModalOpen}
                  users={users}
                  hasTabs="23rem"
                />
              </TableContainer>
            </TabPanel>

            <TabPanel value="businessUsers">
              <TableContainer>
                <DatatableUser
                  setIsLoading={setIsLoading}
                  queryState={queryState}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  handleDispatch={handleDispatch}
                  setModalOpen={setModalOpen}
                  users={users}
                  hasTabs="23rem"
                />
              </TableContainer>
            </TabPanel>
          </Paper>
        </TabContext>
      </Box>

      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={users.data}
        fileName={searchParams.get('type')}
        dataMapper={
          searchParams.get('type') === 'consumerUsers' ? kytConsumerUserDataMapper : kytBusinessUserDataMapper
        }
        dataMapperForPDF={
          searchParams.get('type') === 'consumerUsers'
            ? kytConsumerUserDataMapperForPDF
            : kytBusinessUserDataMapperForPDF
        }
        typeDataExport={searchParams.get('type') === 'consumerUsers' ? 'consumerUsers' : 'businessUsers'}
      />

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              placeholder={t('common:searchById')}
              required
              fullWidth
              value={userId}
              onChange={(event) => setUserId(event.target.value)}
              size="small"
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:initialDate')}:
            </Typography>

            <LocalizationProvider
              size="small"
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <Box>
                <DatePicker
                  size="small"
                  value={dayjs(fromDate)}
                  onChange={(newValue) => {
                    setFromDate(newValue ? newValue['$d'] : null)
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
                <TextField
                  disabled={!fromDate}
                  type="time"
                  step="1"
                  value={fromTime}
                  onChange={(e) => handleFromTimeChange(e.target.value)}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:endDate')}:
            </Typography>
            <LocalizationProvider
              size="small"
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <Box>
                <DatePicker
                  size="small"
                  value={dayjs(toDate)}
                  onChange={(newValue) => {
                    let aux = newValue ? new Date(newValue['$d']) : null
                    aux?.setHours(23)
                    aux?.setMinutes(59)
                    aux?.setSeconds(59)
                    setToDate(aux)
                  }}
                  renderInput={(params) => <TextField size="small" {...params} />}
                />
                <TextField
                  disabled={!toDate}
                  type="time"
                  step="1"
                  value={toTime}
                  onChange={(e) => handleToTimeChange(e.target.value)}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          {searchParams.get('type') === 'consumerUsers' && (
            <>
              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:firstName')}:</Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:firstName')}`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:middleName')}:</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={middleName}
                  onChange={(event) => setMiddleName(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:middleName')}`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:lastName')}:</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:lastName')}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:countryOfResidence')}:</Typography>
                <Autocomplete
                  size="small"
                  value={countryOfResidenceView}
                  onChange={(event, value) => {
                    if (value) {
                      setCountryOfResidence(getKeyByValue(value))
                      setCountryOfResidenceView(value)
                    } else {
                      setCountryOfResidence(null)
                      setCountryOfResidenceView(null)
                    }
                  }}
                  disablePortal
                  options={Object.values(countryDictionary)}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} placeholder={t('common:countryOfResidence')} />
                  )}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:countryOfNationality')}:</Typography>
                <Autocomplete
                  size="small"
                  value={countryOfNationalityView}
                  onChange={(event, value) => {
                    if (value) {
                      setCountryOfNationality(getKeyByValue(value))
                      setCountryOfNationalityView(value)
                    } else {
                      setCountryOfNationality(null)
                      setCountryOfNationalityView(null)
                    }
                  }}
                  disablePortal
                  options={Object.values(countryDictionary)}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} placeholder={t('common:countryOfNationality')} />
                  )}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:documentNumber')}:</Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={documentNumber}
                  onChange={(event) => setDocumentNumber(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:documentNumber')}`}
                />
              </Box>
            </>
          )}

          {searchParams.get('type') === 'businessUsers' && (
            <>
              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:legalName')}:</Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  value={legalName}
                  onChange={(event) => setLegalName(event.target.value)}
                  placeholder={`${t('common:insert')} ${t('common:legalName')}`}
                />
              </Box>

              <Box className="modal-box">
                <Typography variant="subtitle3">{t('common:countryOfRegistration')}:</Typography>
                <Autocomplete
                  size="small"
                  value={countryOfRegistrationView}
                  onChange={(event, value) => {
                    if (value) {
                      setCountryOfRegistration(getKeyByValue(value))
                      setCountryOfRegistrationView(value)
                    } else {
                      setCountryOfRegistration(null)
                      setCountryOfRegistrationView(null)
                    }
                  }}
                  disablePortal
                  options={Object.values(countryDictionary)}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder={`${t('common:searchBy')} ${t('common:countryOfRegistration')}`}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:user')} KYC {t('common:status')}
            </Typography>
            <Autocomplete
              size="small"
              value={userKycStatus}
              onChange={(event, value) => {
                if (value) {
                  setUserKycStatus(value)
                } else {
                  setUserKycStatus(null)
                }
              }}
              disablePortal
              options={userKycStatusPossible}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:searchBy')} ${t('common:status')}`}
                />
              )}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:user')} {t('common:state')}
            </Typography>
            <Autocomplete
              size="small"
              value={selectedUserState}
              onChange={(event, value) => {
                if (value) {
                  setSelectedUserState(value)
                } else {
                  setSelectedUserState(null)
                }
              }}
              disablePortal
              options={userStatePossible}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...params}
                  placeholder={`${t('common:searchBy')} ${t('common:state')}`}
                />
              )}
            />
          </Box>

          {userKeyPossible && (
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:user')} {t('common:tagKey')}:
              </Typography>
              <Autocomplete
                size="small"
                value={userKey}
                onChange={(event, value) => {
                  if (value) {
                    setUserKey(value)
                  } else {
                    setUserKey(null)
                  }
                }}
                disablePortal
                options={userKeyPossible}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    placeholder={`${t('common:searchBy')} ${t('common:tagKey')}`}
                  />
                )}
              />
            </Box>
          )}

          {userValuePossible && (
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('common:user')} {t('common:tagValue')}:
              </Typography>
              <Autocomplete
                size="small"
                value={userValue}
                onChange={(event, value) => {
                  if (value) {
                    setUserValue(value)
                  } else {
                    setUserValue(null)
                  }
                }}
                disablePortal
                options={userValuePossible}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    placeholder={`${t('common:searchBy')} ${t('common:tagValue')}`}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </FilterModal>
    </Box>
  )
}

export default User
