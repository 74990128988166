import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateKyc, updateKycDetail, updateKycToAssign } from '../../features/kyc/kycSlice';
import {
  setIsLoading,
} from '../../features/kyb/kybCountrySlice';

export const useKyc = (isMyKyc) => {
  const queryState = useSelector((state) => state.kyc?.filters);
  const { limit, offset, fromDate, toDate, page, checkId, status, name, documentNumber, nationality } = queryState || {};
  const { accessToken, email } = useSelector((state) => state.login);

  const dispatch = useDispatch();

  const {
    currentCountry,
    countrySegment,
    listOfAvailableKybCountryCodes,
    isLoading,
  } = useSelector((state) => state.kybCountry);
  function buildUrl(baseUrl) {
    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`;
    if (isMyKyc) url += `&assignedUsers=${email}`;
    if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`;
    if (checkId?.length) url += `&id=${checkId}`;
    if (status !== 'ALL' && status?.length) url += `&status=${status}`;
    if (name?.length) url += `&name=${name}`;
    if (documentNumber?.length) url += `&documentNumber=${documentNumber}`;
    if (nationality?.length) url += `&nationality=${nationality}`;

    return url;
  }

  useEffect(() => {
    if (isLoading) {
      const baseUrlKyc = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}all/subjects`;
      let url = buildUrl(baseUrlKyc);

      fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/kybKycs.json' : url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const { data, pagination } = res;
            const aux = { data, pagination };
            dispatch(updateKyc(aux));
            dispatch(setIsLoading(false));
          }
        })
        .catch((error) => {
          console.error('[HOOK: useKyc] --> ', error);
          dispatch(setIsLoading(false));
        });
    }
  }, [isLoading, isMyKyc, currentCountry]);

};

export const useKycDetail = (id) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.login);
  const languageState = useSelector((state) => state.language.lang);

  const {
    currentCountry,
    listOfAvailableKybCountryCodes,
    countrySegment,
    isLoading,
  } = useSelector((state) => state.kybCountry);

  useEffect(() => {
    if (isLoading) {
      let url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`;
      
      if (languageState === 'pt-BR') url += '?lang=PT';
      
      fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/kybKycDetails.json' : url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => res.json())
      .then((res) => {
        dispatch(updateKycDetail(res.data));
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        console.error('[HOOK: useKycDetail] --> ', error);
        dispatch(updateKycDetail({ statusCode: 404 }));
          dispatch(setIsLoading(false));
        });

      const urlToAssign = `${process.env.REACT_APP_BASEURL}/user/toAssign`;

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/usersToAssign.json' : urlToAssign, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateKycToAssign(res.data));
          }
          dispatch(setIsLoading(false));
        })
        .catch((error) => {
          console.error('[HOOK: useKycDetail] --> ', error);
          dispatch(setIsLoading(false));
        });
    }
  }, [isLoading, id, currentCountry]);

};
