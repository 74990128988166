import { Box, Button, Chip, Fab, Link, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertFetchSpinner from '../../common/alertFetchSpinner/alertFetchSpinner'
import { useNavigate } from 'react-router-dom'
import { updateRoles } from '../../../features/roles/rolesSlice'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useTranslation } from 'react-i18next'
import { CloseOutlined, DeleteOutlined } from '@mui/icons-material'
import { camelCaseToPhrase } from '../toRender/camelCAseToPhrase'
import '../../../pages/admin/roles/roles.scss'
import { DataGridComponent } from '../datagrid/DataGridComponent'

const DatatableRoles = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { accessToken, activeServices, emailClient } = useSelector((state) => state.login)
  const rolesState = useSelector((state) => state.role?.roles)
  const queryState = useSelector((state) => state.role.filters)
  const [roleState, setRoleState] = useState([])
  const [idToDelete, setIdToDelete] = useState('')
  const [message, setMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [open2, setOpen2] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])

  const handleClose2 = () => {
    setIsLoading(false)
    setOpen2(false)
    setMessage('')
    setIdToDelete('')
  }

  const handleRoleView = (name) => {
    navigate(`/admin/roles/${name}`)
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleOpen2 = (roleName) => {
    setOpen2(true)
    setMessage(`Are you sure you want to delete role: ${roleName}? This action can not be undone!`)
  }

  const handleDelete = (id) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminRoles = `${process.env.REACT_APP_BASEURL}/admin/roles/${id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlAdminRoles, options)
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
            updateRolesList()
            handleClose2()
            handleFetch(false, res.message)
          }
        })
        .catch((error) => {
          console.error('[DELETE ROLE ERROR] --> ', error)
          handleClose2()
          handleFetch(true, error.message ? error.message : 'Role deletion error')
        })
    } else {
      updateRolesList()
      handleClose2()
      handleFetch(false, 'Role deletion success')
    }
  }

  const updateRolesList = () => {
    const { viewMode, limit, offset, page, status, roleName } = queryState

    const baseUrlRoles = `${process.env.REACT_APP_BASEURL}/admin/roles/emailClient/${emailClient}`
    let urlGetRoles = buildUrl(baseUrlRoles)

    function buildUrl(baseUrl) {
      let url = `${baseUrl}?viewMode=${viewMode}&limit=${limit}&page=${page}&offset=${offset}`

      if (status?.length) url += `&status=${status}`

      if (roleName?.length) url += `&roleName=${roleName}`

      return url
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/roles.json' : urlGetRoles, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const { data, pagination } = res
          const aux = { data, pagination }
          dispatch(updateRoles(aux))

          if (!res.data.length) {
            dispatch(updateRoles([]))
            navigate('/')
          }
        }
      })
      .catch((error) => console.error('[HOOK: useRoles] --> ', error))
  }

  useEffect(() => {
    setRoleState(rolesState)
    setIsLoading(false)
  }, [rolesState])

  const columns = [
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return <p>{params.row.status ? params.row.status.toUpperCase() : '---'}</p>
      },
    },
    {
      field: 'roleName',
      headerName: t('common:roleName'),
      renderCell: (params) => {
        return <p>{params.row.roleName ?? '---'}</p>
      },
    },
    {
      field: 'superRoleName',
      headerName: t('common:superRoleName'),
      renderCell: (params) => {
        return <p>{params.row.superRoleName ?? '---'}</p>
      },
    },
    {
      field: 'description',
      headerName: t('common:description'),
      renderCell: (params) => {
        return <p>{params.row.description ?? '---'}</p>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      field: 'services',
      headerName: t('common:services'),
      renderCell: (params) => {
        return <ServicesCell params={params} />
      },
    },
    {
      field: 'details',
      headerName: t('common:actions'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <Link onClick={() => handleRoleView(params.row.roleName)}>{t('common:viewDetails')}</Link>
      },
    },
    {
      field: 'edit',
      headerName: '',
      hide: !activeServices.includes('adminroles:id'),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          activeServices.includes('adminroles:id') &&
          params.row.roleName !== 'MANAGER' && (
            <Fab
              variant="close"
              onClick={() => {
                const id = roleState?.data?.find((r) => r.roleName === params.row.roleName)._id
                setIdToDelete(id)
                handleOpen2(params.row.roleName)
              }}
            >
              <DeleteOutlined style={{ height: '1.45rem' }} />
            </Fab>
          )
        )
      },
    },
  ]

  return (
    <Box>
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <DataGridComponent
        rows={(roleState && Array.isArray(roleState?.data) && roleState?.data) || []}
        columns={columns}
        count={roleState?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
      />

      <Modal open={open2} onClose={handleClose2}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{`${t('common:delete')} ${t('common:role')}`}</Typography>
            </Box>
            <Fab
              variant="close"
              onClick={() => {
                handleClose2()
              }}
            >
              <CloseOutlined />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            {message && <Box>{message}</Box>}
            <Box className="modal-filter-buttons">
              <Button size="large" variant="outlined" onClick={() => handleDelete(idToDelete)}>
                {t('common:yes')}
              </Button>
              <Button size="large" variant="outlined" onClick={handleClose2}>
                {t('common:no')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

const ServicesCell = ({ params }) => {
  const [showAllChipServices, setShowAllChipServices] = useState(false)
  const { t } = useTranslation(['common'])

  return (
    <Box className="role-service-wrapper">
      <Box className="role-service-wrap">
        {params.row.services.map((service, index) =>
          index < 5 || showAllChipServices ? (
            <Chip variant="transparent" key={service.name} label={camelCaseToPhrase(service.name)} />
          ) : null,
        )}
      </Box>
      {params.row.services.length > 5 && (
        <Link
          onClick={() => {
            setShowAllChipServices((previous) => !previous)
          }}
          sx={{ paddingLeft: '.5rem' }}
        >
          {!showAllChipServices ? t('common:showAll') : t('common:showLess')}
        </Link>
      )}
    </Box>
  )
}

export default DatatableRoles
