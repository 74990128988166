import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRules } from '../../features/rules/rulesSlice'

export const useRules = (isLoading, setIsLoading, rules, setRules) => {
  const { accessToken } = useSelector((state) => state.login)
  const rulesState = useSelector((state) => state.rule)
  const dispatch = useDispatch()

  useEffect(() => {
    if (rules.length === 0 && !isLoading && !rulesState.rules.length) {
      const urlKytRules = `${process.env.REACT_APP_BASEURL}/kyt/retrieveClientRules`
      const urlGuenoRules = `${process.env.REACT_APP_BASEURL}/rulesTemplates?viewMode=true`

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }

      let allRules = []

      if (setIsLoading) {
        setIsLoading(true)
      }

      const kytRulesPromise = fetch(
        process.env.REACT_APP_IS_DEMO ? '../../data/clientRules.json' : urlKytRules,
        options,
      )
        .then((res) => res.json())
        .then((res) => res)
        .catch((error) => {
          console.error('[HOOK: useRules] --> ', error)
        })

      const guenoRulesPromise = fetch(
        process.env.REACT_APP_IS_DEMO ? '../../data/rule-templates.json' : urlGuenoRules,
        options,
      )
        .then((res) => res.json())
        .then((res) => res)
        .catch((error) => {
          console.error('[HOOK: useRules] --> ', error)
        })

      Promise.all([guenoRulesPromise, kytRulesPromise]).then((responses) => {
        if (responses[0]) {
          const { data } = responses[0]
          if (data && Array.isArray(data)) {
            const guenoData = data.map((item) => ({ ...item, gueno: true }))
            const aux = guenoData.sort((a, b) => {
              return a.id?.split('-')[1] - b.id?.split('-')[1]
            })
            allRules.push(...aux)
          }
        }
        if (responses[1]) {
          const { data } = responses[1]
          if (data && Array.isArray(data)) {
            allRules.push(...data)
          }
        }

        dispatch(updateRules(allRules))
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (rulesState?.rules?.length !== 0) {
      setRules(rulesState.rules)
      setIsLoading(false)
    }
  }, [rulesState])

  return rulesState.rules
}
