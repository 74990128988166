import { Box, Button, Fab, Modal, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'

export const FilterModal = ({ children, modalOpen, setModalOpen, handleResetButton, setPage, handleDispatch }) => {
  const { t } = useTranslation(['common'])

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box className="modal">
        <Box className="modal-top">
          <Box className="modal-titles">
            <Typography variant="title">{t('common:filters')}</Typography>
          </Box>
          <Fab variant="close" onClick={() => setModalOpen(false)}>
            <CloseOutlinedIcon />
          </Fab>
        </Box>

        {children}

        <Box className="modal-filter-buttons">
          <Button variant="outlinedGrey" size="small" onClick={() => handleResetButton()}>
            {t('common:clearAll')}
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setPage(0)
              handleDispatch()
              return setModalOpen(false)
            }}
          >
            {t('common:apply')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
