import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { countryDictionary } from '../common/flag/countries'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentCountry, setIsLoading } from '../../features/kyb/kybCountrySlice'

const CountrySelector = ({ currentCountry, listOfAvailableKybCountryCodes, t, isLoading }) => {
  const dispatch = useDispatch()

  const handleValueChange = useCallback(
    (newValue) => {
      dispatch(setIsLoading(true))
      const newCountryCode = Object.keys(countryDictionary).find((key) => countryDictionary[key] === newValue)
      if (newCountryCode) {
        dispatch(setCurrentCountry(newCountryCode))
      }
    },
    [dispatch, setIsLoading],
  )

  const currentCountryValue = useMemo(() => {
    return countryDictionary[currentCountry] || ''
  }, [currentCountry])

  if (isLoading) {
    return <CircularProgress />
  }

  return listOfAvailableKybCountryCodes?.length > 1 ? (
    <Autocomplete
      size="small"
      className="kyb-country-selector"
      value={currentCountryValue}
      onChange={(event, newValue) => handleValueChange(newValue || '')}
      disablePortal
      options={listOfAvailableKybCountryCodes.map((countryCode) => countryDictionary[countryCode])}
      renderInput={(params) => (
        <TextField variant="outlined" {...params} placeholder={`${t('common:selectCountry')}`} />
      )}
    />
  ) : (
    <Autocomplete
      size="small"
      className="kyb-country-selector"
      value={currentCountryValue}
      onChange={(event, newValue) => handleValueChange(newValue || '')}
      disablePortal
      options={['BR']}
      renderInput={(params) => (
        <TextField variant="outlined" {...params} placeholder={`${t('common:selectCountry')}`} />
      )}
    />
  )
}

export default CountrySelector
