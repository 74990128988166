import './flags.scss'
import { countryDictionary } from './countries'
import { Box, Tooltip } from '@mui/material'

export const Flags = (country, noName, shortName) => {
  return (
    <Box className="flag-child">
      {noName 
      ? '' 
      : shortName 
      ? <p>{country}</p> 
      : (
        <Tooltip title={countryDictionary[country]}>
          <p>
            {countryDictionary[country]?.split('(')[0].trim() || ''}
          </p>
        </Tooltip>
        )
      }
      {country && (
        <img alt="img" src={`https://flagcdn.com/16x12/${country?.toLowerCase()}.png`} width="16" height="12" />
      )}
      {!country && <p>---</p>}
    </Box>
  )
}
