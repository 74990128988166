import './datatable.scss'
import { Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Status } from '../../utilities/Status'
import { Flags } from '../flag/flags'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from '../../../features/kyb/kybCountrySlice'

const DatatableKyc = ({ page, setPage, rowsPerPage, setRowsPerPage, kyc, setModalOpen, hasTabs }) => {
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    currentCountry
  } = useSelector(state => state.kybCountry)
  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      renderCell: (params) => {
        return <Typography variant="number">{params.row._id}</Typography>
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      renderCell: (params) => {
        return <p title={params.row.name ?? '---'}>{params.row.name ?? '---'}</p>
      },
    },
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row.documentNumber ?? '---'}</Typography>
      },
    },
    {
      field: 'nationality',
      headerName: t('common:nationality'),
      renderCell: (params) => {
        return Flags(params.row.nationality === 'Brazil' ? 'BR' : params.row.nationality)
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return <Status type={params.row.status} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      renderCell: (params) => {
        return (
          <Link to={`/kyc/${currentCountry}/detail/${params.row._id}`} onClick={() => {
            dispatch(setIsLoading(true))
            return navigate(`/kyc/${currentCountry}/detail/${params.row._id}`)}
            }>
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
  ]

  return (
    <DataGridComponent
      rows={Array.isArray(kyc?.data) && kyc?.data?.length && kyc?.data}
      columns={columns}
      count={kyc?.pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
      hasTabs={hasTabs}
    />
  )
}

export default DatatableKyc
