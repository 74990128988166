import { Box, Fab, Link, TableContainer, Typography } from '@mui/material'
import Switch from '@mui/material/Switch'
import { useState, useEffect } from 'react'
import { TableChip } from '../../../utilities/TableChip'
import { useTranslation } from 'react-i18next'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { DataGrid } from '@mui/x-data-grid'

const MyRulesTable = ({
  handler,
  rows,
  handleSwitchChange,
  deleteHandler,
  message,
  isFetching,
  verifyEdition,
  verifyDeletion,
  rules,
}) => {
  const [rowsToShow, setRowsToShow] = useState([])
  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (rows.length !== 0 || (message && message.includes('deletion success.'))) setRowsToShow([...rows])
  }, [rows])

  const handleSwitch = (id, status, row) => {
    const idx = rowsToShow.findIndex((element) => {
      return element.id === id
    })

    let statusString = status ? 'ACTIVE' : 'INACTIVE'
    let aux = [...rowsToShow]
    let auxItem = rowsToShow[idx]
    aux[idx] = { ...auxItem, status: statusString }
    setRowsToShow([...aux])

    handleSwitchChange(id, statusString, row, row.ruleTemplate ? true : false, rows, setRowsToShow)
  }

  const getDescription = (row) => {
    let desc = ''
    if (row.ruleDescriptionAlias) {
      desc = row.ruleDescriptionAlias
    } else if (row.description) {
      desc = row.description
    } else {
      desc = rules?.find((r) => r.id === row.ruleId)?.description || ''
    }

    return desc
  }

  const columns = [
    {
      field: 'id',
      headerName: t('common:active'),
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return verifyEdition() ? (
          <Switch
            disabled={isFetching}
            checked={params.row.status === 'ACTIVE' ? true : false}
            onChange={() => handleSwitch(params.row.id, params.row.status === 'ACTIVE' ? false : true, params.row)}
            color="secondary"
          />
        ) : (
          <Box>{params.row.status}</Box>
        )
      },
    },
    {
      field: 'shadowMode',
      headerName: t('common:shadowMode'),
      flex: 1,
      hide: !process.env.REACT_APP_IS_DEMO,
      minWidth: 120,
      renderCell: (params) => {
        return verifyEdition() ? <Switch checked={true} color="secondary" /> : <Box>{params.row.status}</Box>
      },
    },
    {
      field: 'ruleId',
      headerName: t('common:ruleId'),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return `${params.row.ruleId} (${params.row.id})`
      },
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      flex: 1,
      minWidth: 350,
      renderCell: (params) => {
        return `${params.row.ruleNameAlias || params.row.name}`
      },
    },
    {
      field: 'ruleDescription',
      headerName: t('common:ruleDescription'),
      flex: 1,
      minWidth: 450,
      renderCell: (params) => {
        return <Typography variant="subtitle3">{getDescription(params.row)}</Typography>
      },
    },
    {
      field: 'action',
      headerName: t('common:action'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <TableChip action={params.row.action} noIcon={true} />
      },
      valueGetter: (params) => {
        return params.row.action
      },
    },
    {
      field: 'hitRate',
      headerName: t('common:hitRate'),
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return (
          <Typography variant="subtitle1">
            {(params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed() + '%'}
          </Typography>
        )
      },
      valueGetter: (params) => {
        return (params.row.runCount === 0 ? 0 : (params.row.hitCount * 100) / params.row.runCount).toFixed()
      },
    },
    {
      field: 'details',
      headerName: t('common:details'),
      flex: 1,
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <Link onClick={() => handler(params.row.id)}>{t('common:seeDetails')}</Link>
      },
    },
    {
      field: 'delete',
      headerName: t('common:delete'),
      flex: 1,
      minWidth: 80,
      hide: !verifyDeletion(),
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Fab variant="close" className="icon-fab" onClick={() => deleteHandler(params.row.id, params.row.gueno)}>
            <DeleteOutlineOutlinedIcon />
          </Fab>
        )
      },
    },
  ]

  return (
    <Box>
      <TableContainer>
        <DataGrid
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          rows={(Array.isArray(rowsToShow) && rowsToShow?.length > 0 && rowsToShow) || []}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
        />
      </TableContainer>
    </Box>
  )
}

export default MyRulesTable
