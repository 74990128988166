import { Box, Button, Divider, IconButton, Input, InputAdornment, InputLabel, Modal, TextField } from '@mui/material'
import './profile.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'

const Profile = () => {
  const { email, accessToken, roles } = useSelector((state) => state.login)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const [inputType0, setInputType0] = useState('password')
  const [inputType, setInputType] = useState('password')
  const [inputType2, setInputType2] = useState('password')
  const [successMsg, setSuccessMsg] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['common', 'messages'])
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setError(false)
    setErrorMessage('')
    setPassword('')
    setOldPassword('')
    setSuccessMsg('')
  }
  const regex = /(^[A-Z]{4,20}(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        email,
        oldPassword,
        password,
        confirmNewPassword,
      }

      const urlUser = `${process.env.REACT_APP_BASEURL}/user/changePassword`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) {
            setError(true)
            setErrorMessage(typeof res.data === 'string' ? res.data : t('messages:unexpectedError'))
          } else {
            setError(false)
            setErrorMessage('')
            setSuccessMsg(t('messages:passwordChanged'))
            setOpen(false)
            setTimeout(() => {
              handleClose()
            }, 3000)
          }
        })
        .catch((error) => console.error('[CHANGE PASSWORD ERROR] --> ', error))
    } else {
      setError(false)
      setErrorMessage('')
      setSuccessMsg(t('messages:passwordChanged'))
      setOpen(false)
      setTimeout(() => {
        handleClose()
      }, 3000)
    }
  }

  const renderRoles = (roles) => {
    let result = ''
    roles.forEach((role) => {
      if (role !== 'APIKEYROLE') result += role + ', '
    })
    result = result.substring(0, result.length - 2)
    return result
  }

  useEffect(() => {
    if (open && (password !== '' || confirmNewPassword !== '')) {
      if (!regex.test(password) && password !== confirmNewPassword) {
        setErrorMessage(`${t('messages:passwordTooWeak')}. ${t('messages:passwordDontMatch')}`)
        setError(true)
      } else if (!regex.test(password)) {
        setErrorMessage(t('messages:passwordTooWeak'))
        setError(true)
      } else if (password !== confirmNewPassword) {
        setErrorMessage(t('messages:passwordDontMatch'))
        setError(true)
      } else {
        setError(false)
        setErrorMessage('')
      }
    }
  }, [password, confirmNewPassword])

  return (
    <>
      <Box className="profile-wrapper">
        <Box className="profile">
          <Box className="title-general">
            <p>{t('common:profile')}</p>
          </Box>
          <Divider style={{ margin: '1.5rem 0rem 2rem 0rem' }} />

          <Box className="data">
            <span>
              {t('common:email')}: {email}
            </span>

            <span>
              {`Role${roles.length > 1 ? 's' : ''}:`} {renderRoles(roles)}
            </span>
          </Box>

          {successMsg && (
            <Button style={{ marginBottom: '2rem' }} size="small" variant="outlined" color="secondary">
              {successMsg}
            </Button>
          )}

          <Box>
            <Button onClick={handleOpen} size="large" variant="outlined" type="submit">
              {t('messages:changePassword')}
            </Button>
          </Box>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="change-password">
            <Box component="form" onSubmit={(e) => handleSubmit(e)}>
              <Box className="form">
                <InputLabel htmlFor="old-password">{t('messages:oldPassword')}:</InputLabel>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            inputType0 === 'password' ? setInputType0('text') : setInputType0('password')
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={inputType0}
                  style={{ width: '100%' }}
                  required
                  id="oldPassword"
                  onChange={(e) => {
                    setOldPassword(e.target.value)
                  }}
                />

                <InputLabel htmlFor="password">{t('messages:newPassword')}:</InputLabel>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => (inputType === 'password' ? setInputType('text') : setInputType('password'))}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={inputType}
                  style={{ width: '100%' }}
                  required
                  id="password"
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                />

                <InputLabel htmlFor="confirmPassword">{t('messages:confirmNewPassword')}:</InputLabel>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            inputType2 === 'password' ? setInputType2('text') : setInputType2('password')
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={inputType2}
                  style={{ width: '100%' }}
                  required
                  id="confirmPassword"
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value)
                  }}
                />
              </Box>

              {errorMessage ? (
                <Button style={{ marginBottom: '2rem' }} size="small" variant="outlined" color="error">
                  {errorMessage}
                </Button>
              ) : (
                ''
              )}

              {successMsg ? (
                <Box style={{ display: 'flex', gap: '1rem' }}>
                  <Button onClick={handleClose} size="large" color="error" variant="outlined">
                    {t('common:close')}
                  </Button>
                </Box>
              ) : (
                <Box style={{ display: 'flex', gap: '1rem' }}>
                  <Button disabled={error || oldPassword === ''} size="large" variant="outlined" type="submit">
                    {t('common:save')}
                  </Button>

                  <Button onClick={handleClose} size="large" color="error" variant="outlined">
                    {t('common:cancel')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  )
}

export default Profile
