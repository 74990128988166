import React, { useState, useRef, useCallback } from 'react'
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  Node,
  Connection,
} from 'react-flow-renderer'
// import { OnInit } from 'react-flow-renderer'
import './Flow.scss'
import { generateNodeId } from '../../utils/helper'
import { Nodes, NodeType } from '../../data/Nodes.js'
import NodeCategories from './../../data/NodeCategories.js'
import { StandardCustomNode } from '../Nodes/StandardNode/index.jsx'
// import { message } from 'antd'

const customNodeTypes = {
  customNode: StandardCustomNode, // 'customNode' es el identificador de tu nodo personalizado
}

const Flow = () => {
  const reactFlowWrapper = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [reactFlowInstance, setReactFlowInstance] = useState(null)

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [])

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event) => {
      event.preventDefault()

      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect()
      const type = event.dataTransfer.getData('application/reactflow')

      const nodeData = Nodes[type]
      const position = reactFlowInstance?.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      })

      const id = generateNodeId()
      const newNode = {
        id,
        type: 'customNode',
        position,
        data: { ...nodeData, key: id, category: NodeCategories[nodeData.category] },
      }

      setNodes((nds) => nds.concat(newNode))
    },
    [reactFlowInstance],
  )

  return (
    <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ width: '100vw', height: '75vh' }}>
      <ReactFlow
        nodeTypes={customNodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        fitView
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  )
}

export default Flow
