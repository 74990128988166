/* eslint-disable no-self-assign */
import { useNavigate, useParams } from 'react-router-dom'
import './kyb.scss'
import { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Chip,
  Fab,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Spinner from '../../components/common/spinner/spinner'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { Flags } from '../../components/common/flag/flags'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import { Status } from '../../components/utilities/Status'
import { KybChip } from '../../components/utilities/KybChip'
import RecursiveProperty from '../../components/utilities/RenderJSON'
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/common/Accordion/Accordion'
import { useKybDetail } from '../pages-hooks/useKyb'
import { updateKybDetail, updateKybShareholder } from '../../features/kyb/kybSlice'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import KybToPdf from './KybToPdf'
import DatatableAssignUsers from '../../components/common/datatable/DatatableAssignedUsers'
import { renderJap, renderLawsuits } from '../../components/utilities/MapLawsuits'
import { renderNegativeMedia } from '../../components/utilities/MapNegativeMedia'
import { useTranslation } from 'react-i18next'
import { RiskLevelSlider } from '../../components/utilities/RiskLevelSlider'
import { StatusIndicator } from '../../components/utilities/StatusIndicator'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { FileUploader } from 'react-drag-drop-files'
import { updateKycDetail } from '../../features/kyc/kycSlice'
import { camelCaseFormatter } from '../../components/utilities/formatters/camelCase'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'
import CustomNoRowsOverlay from '../../components/common/datagrid/CustomNoRowsOverlay'
import { kybRiskLevelEnum, kybStatusEnum } from './kyb.enums'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'
import { addAdditionalParams, getCountrySegment } from './kyb.hook-helpers'

const KybDetail = ({ isShareholder, isPerson }) => {
  const { id, country } = useParams()
  const currentCountry = country.toUpperCase()
  const countrySegment = getCountrySegment(currentCountry)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const languageState = useSelector((state) => state.language.lang)
  const { emailClient, email, accessToken } = useSelector((state) => state.login)
  const { theme } = useSelector((state) => state.theme)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const [newStatus, setNewStatus] = useState('')
  const [newCheckStatus, setNewCheckStatus] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [modalOpen3, setModalOpen3] = useState(false)
  const [modalOpen4, setModalOpen4] = useState(false)
  const [modalOpen5, setModalOpen5] = useState(false)
  const [editFile, setEditFile] = useState(false)
  const [checkId, setCheckId] = useState('')
  const [checkStatus, setCheckStatus] = useState('')
  const [checkIndex, setCheckIndex] = useState('')
  const [checkIndexMain, setCheckIndexMain] = useState('')
  const [newCommentMessage, setNewCommentMessage] = useState('')
  const [toAssign, setToAssign] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [isEditingAssign, setIsEditingAssign] = useState(false)
  const [isRunningKyb, setIsRunningKyb] = useState('')
  const [errorAssign, setErrorAssign] = useState(false)
  const [isProcessingFile, setIsProcessingFile] = useState(false)
  const [shareholderEntity, setShareholderEntity] = useState('')
  const [shareholderDocumentNumber, setShareholderDocumentNumber] = useState('')
  const [newRiskLevel, setNewRiskLevel] = useState('')
  const [selectedTab, setSelectedTab] = useState('checks')

  const { t } = useTranslation(['common', 'messages'])

  const entityType = isShareholder || isPerson ? 'PERSON' : 'BUSINESS'

  const checksMap = {
    BR: {
      BUSINESS: [
        {
          title: t('common:regulatoryCompliance'),
          subtitle: t('messages:regulatoryCompliance'),
          items: [
            { name: 'aml', key: 'KYC_BUSINESS', title: t('common:aml') },
            { name: 'cnep', key: 'SANCTIONS_CNEP', title: t('common:cnep') },
            { name: 'ceis', key: 'SANCTIONS_CEIS', title: t('common:ceis') },
          ],
        },
        {
          title: t('common:legalProcesses'),
          subtitle: t('messages:legalProcesses'),
          items: [
            {
              name: 'jap',
              key: 'JUDICIAL_ADMINISTRATIVE_PROCESSES',
              title: `${t('common:judicialAdministrativeProcesses')} (${t('common:company')})`,
            },
            {
              name: 'lawsuits',
              key: 'OWNERS_LAWSUITS',
              title: `${t('common:judicialAdministrativeProcesses')} (${t('common:shareholder')})`,
            },
            { name: 'leniencyAgreements', key: 'SANCTIONS_LENIENCY_AGREEMENTS', title: t('common:leniencyAgreements') },
          ],
        },
        {
          title: t('common:governmentConnections'),
          subtitle: t('messages:governmentConnections'),
          items: [
            { name: 'debtors', key: 'GOVERNMENT_DEBTORS', title: t('common:governmentDebtors') },
            { name: 'cade', key: 'CADE', title: t('common:cade') },
          ],
        },
        {
          title: t('common:identityVerification'),
          subtitle: t('messages:identityVerification'),
          items: [
            { name: 'kyc', key: 'KYC_BUSINESS', title: `${t('common:kyc')} (${t('common:knowYourCostumer')})` },
            { name: 'cdn', key: 'NEGATIVE_DEBT_CERTIFICATES', title: t('common:cdn') },
            { name: 'cepim', key: 'SANCTIONS_CEPIM', title: t('common:cepim') },
          ],
        },
        {
          title: t('common:riskAssestment'),
          subtitle: t('messages:riskAssestment'),
          items: [
            { name: 'negativeMedia', key: 'NEGATIVE_MEDIA_BUSINESS', title: t('common:negativeMedia') },
            { name: 'serasa', key: 'SERASA_SCORE', title: t('common:serasaScore') },
          ],
        },
        {
          title: t('common:blocklistsWatchlists'),
          subtitle: t('messages:blocklistsWatchlists'),
          items: [
            { name: 'blacklists', key: 'BLACKLISTS', title: t('common:blocklists') },
            { name: 'domains', key: 'DOMAINS', title: t('common:domains') },
          ],
        },
      ],
      PERSON: [
        {
          title: t('common:regulatoryCompliance'),
          subtitle: t('messages:regulatoryCompliance'),
          items: [
            { name: 'aml', key: 'KYC_PERSON', title: t('common:aml') },
            { name: 'cnep', key: 'SANCTIONS_CNEP', title: t('common:cnep') },
            { name: 'ceis', key: 'SANCTIONS_CEIS', title: t('common:ceis') },
          ],
        },
        {
          title: t('common:riskAssestment'),
          subtitle: t('messages:riskAssestment'),
          items: [{ name: 'negativeMedia', key: 'NEGATIVE_MEDIA_PERSON', title: t('common:negativeMedia') }],
        },
        {
          title: t('common:identityVerification'),
          subtitle: t('messages:identityVerification'),
          items: [
            { name: 'registrationStatus', key: 'REGISTRATION_STATUS', title: t('common:registrationStatus') },
            { name: 'relatedCompanies', key: 'RELATED_COMPANIES', title: t('common:relatedCompanies') },
            { name: 'kyc', key: 'KYC_PERSON', title: `${t('common:kyc')} (${t('common:knowYourCostumer')})` },
            { name: 'ceaf', key: 'SANCTIONS_CEAF', title: t('common:ceaf') },
          ],
        },
      ],
    },
    CO: {
      BUSINESS: [
        {
          title: t('common:regulatoryCompliance'),
          subtitle: t('messages:regulatoryCompliance'),
          items: [
            { name: 'simit', key: 'simit-co', title: t('common:simit') },
            { name: 'bdme', key: 'bdme-co', title: t('common:bdme') },
          ],
        },
        {
          title: t('common:legalProcesses'),
          subtitle: t('messages:legalProcesses'),
          items: [
            { name: 'fiscalia', key: 'fiscalia-co', title: t('common:fiscalia') },
            { name: 'csj', key: 'judicatura-co', title: t('common:csj') },
            { name: 'procesosJudiciales', key: 'procesos-judiciales-co', title: t('procesosJudiciales') },
          ],
        },
        {
          title: t('common:governmentConnections'),
          subtitle: t('messages:governmentConnectionsCO'),
          items: [
            { name: 'secop', key: 'secop-co', title: t('common:secop') },
            { name: 'rues', key: 'rues-co', title: t('common:rues') },
          ],
        },
        // {
        //   title: t('common:riskAssestment'),
        //   subtitle: t('messages:riskAssestment'),
        //   items: [{ name: 'identityBusiness', key: 'identity-business-co', title: t('common:identityBusiness') }],
        // },
      ],
      PERSON: [
        {
          title: t('common:regulatoryCompliance'),
          subtitle: t('messages:regulatoryCompliance'),
          items: [
            { name: 'inpec', key: 'inpec-co', title: t('common:inpec') },
            { name: 'sigep', key: 'sigep-co', title: t('common:sigep') },
          ],
        },
        {
          title: t('common:legalProcesses'),
          subtitle: t('messages:legalProcesses'),
          items: [
            { name: 'policia', key: 'policia-co', title: t('common:policiaNacionalCO') },
            { name: 'fiscalia', key: 'fiscalia-co', title: t('common:fiscalia') },
            { name: 'csj', key: 'judicatura-co', title: t('common:csj') },
            { name: 'procesosJudiciales', key: 'procesos-judiciales-co', title: t('procesosJudiciales') },
            { name: 'contraloria', key: 'controlaria-co', title: t('common:contraloria') },
            { name: 'procuraduria', key: 'procuraduria-co', title: t('common:procuraduria') },
          ],
        },
        {
          title: t('common:governmentConnections'),
          subtitle: t('messages:governmentConnectionsCO'),
          items: [
            { name: 'bdme', key: 'bdme-co', title: t('common:bdme') },
            { name: 'simit', key: 'simit-co', title: t('common:simit') },
          ],
        },
        {
          title: t('common:riskAssestment'),
          subtitle: t('messages:riskAssestment'),
          items: [
            { name: 'cadaveres', key: 'cadaveres-co', title: t('common:cadaveres') },
            { name: 'menores', key: 'menores-co', title: t('delitoAMenores') },
            { name: 'desaparecidos', key: 'desaparecidos-co', title: t('common:personasDesaparecidas') },
            { name: 'rnmc', key: 'rnmc-co', title: t('common:rnmc') },
          ],
        },
        {
          title: t('common:identityVerification'),
          subtitle: t('messages:identityVerificationCO'),
          items: [
            { name: 'registraduria', key: 'registraduria-co', title: t('common:registraduria') },
            { name: 'fosyga', key: 'adres-co', title: t('common:fosyga') },
            { name: 'situacionMilitar', key: 'situacion-militar-co', title: t('common:situacionMilitar') },
            { name: 'experianHcpn', key: 'experian-hcpn-co', title: t('common:experianHcpn') },
          ],
        },
      ],
    },
  }

  const checks = checksMap[currentCountry][entityType] || checksMap.BR.BUSINESS

  const [expanded, setExpanded] = useState({})
  const [expandedMain, setExpandedMain] = useState([false, false, false, false, false, false])

  useKybDetail(id, isShareholder, isPerson)

  const CustomTabList = styled(TabList)({
    borderBottom: '1px solid #1C282026',
    '& ::-webkit-scrollbar-thumb': {
      background: theme === 'light' ? '#c9d2cc' : '#57635b',
    },
  })

  const kybBase = useSelector((state) => (isShareholder ? state.kyb.kybDetail : isPerson ? state.kyc : state.kyb))
  const kyb = useSelector((state) =>
    isShareholder ? state.kyb.kybShareholder : isPerson ? state.kyc.kycDetail : state.kyb.kybDetail,
  )
  // usually with state.kybCountry we would lower down string 'countrySegment',
  // but since it is not used in kybDetail (for scope limitations on link sharing) we define it below these
  const { isLoading } = useSelector((state) => state.kybCountry)

  const reversedTimeline = () => {
    if (isShareholder) {
      return kybBase?.timeline ? [...kybBase?.timeline]?.reverse() : []
    } else if (isPerson) {
      return kyb?.timeline ? [...kyb?.timeline]?.reverse() : []
    } else {
      return kyb?.timeline ? [...kyb?.timeline]?.reverse() : []
    }
  }

  const getStyles = (type) => {
    let color
    let backgroundColor

    switch (type.toLowerCase()) {
      case 'creation':
        backgroundColor = '#4184c3'
        color = '#fafafa'
        break
      case 'complete':
        backgroundColor = '#00B85F'
        color = '#FBFFFC'
        break
      default:
        backgroundColor = '#FF8A00'
        color = '#FBFFFC'
        break
    }

    return { color, backgroundColor }
  }
  const closeAccordions = (activePosition) => {
    const newState = {}

    Object.keys(expanded).forEach((key) => {
      newState[key] = false
    })

    newState[activePosition] = true

    setExpanded(newState)
  }

  const handleChangeExpanded = (indexMain, index) => {
    const key = `${indexMain}-${index}`
    const newState = { ...expanded, [key]: !expanded[key] }
    setExpanded(newState)
  }

  const handleChangeExpandedMain = (indexMain) => {
    const newState = { ...expandedMain }
    newState[indexMain] = !newState[indexMain]
    setExpandedMain(newState)
  }

  const handleFetch = (error, message) => {
    setFetchError(error)
    setFetchMessage(message)
    setModalOpen5(false)
    setModalOpen4(false)
    setModalOpen3(false)
    setModalOpen2(false)
    setModalOpen(false)
    setIsProcessing(false)

    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const updateDetail = () => {
    let baseUrl
    if (isShareholder) {
      baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else if (isPerson) {
      baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else {
      baseUrl = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../../../data/kybDetails.json' : baseUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (isShareholder) {
          dispatch(updateKybShareholder(res.data))
        } else if (isPerson) {
          dispatch(updateKycDetail(res.data))
        } else {
          dispatch(updateKybDetail(res.data))
        }
        dispatch(setIsLoading(false))
      })
      .catch((error) => {
        console.error('[HOOK: useKybDetail] --> ', error)
        if (isShareholder) {
          dispatch(updateKybShareholder({ statusCode: 404 }))
        } else if (isPerson) {
          dispatch(updateKycDetail({ statusCode: 404 }))
        } else {
          dispatch(updateKybDetail({ statusCode: 404 }))
        }
        dispatch(setIsLoading(false))
      })
  }

  // STATUS KYB

  const handleUpdate = (e, data) => {
    e.preventDefault()
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))
    //closeAccordions(null)

    let urlUser

    if (isShareholder) {
      urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else if (isPerson) {
      urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else {
      urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
    }

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:updateSuccess'))
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE STATUS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateError'))
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
    }
  }

  // RISK LEVEL

  const handleUpdateRiskLevel = (e, level) => {
    e.preventDefault()
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))
    //closeAccordions(null)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/riskLevel/${level}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:updateSuccess'))
          updateDetail()
        })
        .catch((error) => {
          console.error('[UPDATE RISK LEVEL ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateError'))
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
      updateDetail()
    }
  }

  const handleCheck = (e, type, index, indexMain) => {
    e.preventDefault()
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))
    closeAccordions(`${indexMain}-${index}`)

    const requiresNameAndLast = type === 'procesos-judiciales-co' || type === 'fiscalia-co'

    const requiresOnlyLastName = type === 'inpec-co' || type === 'experian-hcpn-co' || type === 'secop-co'

    let data = {
      id: id,
      documentNumber: isShareholder || isPerson ? kyb?.documentNumber : kyb?.companyDetails?.documentNumber,
      entity: isShareholder || isPerson ? 'PERSON' : 'BUSINESS',
      type,
      ...(requiresNameAndLast && {
        name:
          kyb?.name?.split(' ')[0] ??
          kyb?.companyDetails?.name.slice(0, Math.floor(kyb?.companyDetails?.name.length / 2)),
      }),
      ...((requiresOnlyLastName || requiresNameAndLast) && {
        last_name:
          kyb?.name?.split(' ')[-1] ??
          kyb?.companyDetails?.name.slice(Math.floor(kyb?.companyDetails?.name.length / 2)),
      }),
      date_expedition:
        type === 'registraduria-co' || type === 'inhabilidades-co'
          ? kyb?.companyDetails?.dateOfRegistration ?? '1994-05-03'
          : undefined,
    }

    data = addAdditionalParams(type, data)
    const urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}check`
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(urlUser, options)
        .then(async (res) => {
          const jsonRes = await res.json()
          return jsonRes
        })
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:creationSuccess'))
          updateDetail()
        })
        .catch((error) => {
          console.error('[CREATE CHECK ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:creationError'))
        })
    } else {
      handleFetch(false, t('messages:creationSuccess'))
    }
  }

  /**
   * Handles the update of a check by sending a PATCH request to the specified endpoint.
   * @param {Event} e - The event object.
   * @param {string} checkId - The ID of the check to update.
   * @param {Object} data - The data object containing the updated check information.
   * @param {number} index - The index of the check within its category.
   * @param {number} indexMain - The index of the main category of checks.
   * @param {string} type - The type of check being updated.
   */
  const handleCheckUpdate = (e, checkId, data, index, indexMain, type) => {
    e.preventDefault()
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))
    closeAccordions(`${indexMain}-${index}`)

    data = addAdditionalParams(type, data)

    const urlUser = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}check/${checkId}`
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(urlUser, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          handleFetch(false, t('messages:updateSuccess'))
          updateDetail()
          setCheckId('')
          setCheckStatus('')
          setNewCheckStatus('')
          setCheckIndex('')
          setCheckIndexMain('')
        })
        .catch((error) => {
          console.error('[UPDATE CHECK STATUS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateError'))
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
      setCheckId('')
      setCheckStatus('')
      setNewCheckStatus('')
      setCheckIndex('')
      setCheckIndexMain('')
    }
  }

  // COMMENTS
  const postNewComment = () => {
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))
    let url

    if (isPerson || isShareholder) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}/comments`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/comments`
    }

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      method: 'POST',
      body: JSON.stringify({
        emailClient: emailClient,
        email: email,
        description: newCommentMessage,
      }),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then(() => {
          handleFetch(false, t('messages:createCommentSuccess'))
          dispatch(setIsLoading(false))
          updateDetail()
        })
    } else {
      handleFetch(false, t('messages:createCommentSuccess'))
      dispatch(setIsLoading(false))
    }
  }

  // FILES
  const uploadFile = (file) => {
    const formData = new FormData()
    formData.append('file', file)

    setIsFetching(true)
    setIsProcessingFile(true)

    let url

    if (isPerson) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}/file`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/file`
    }

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            handleFetch(false, t('messages:uploadFileSuccess'))
            dispatch(setIsLoading(false))
            setEditFile(false)
            updateDetail()
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
          setIsProcessingFile(false)
        })
        .catch((error) => {
          console.error('[UPLOAD KYB FILE ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:uploadFileError'))
          setIsProcessingFile(false)
        })
    } else {
      handleFetch(false, t('messages:uploadFileSuccess'))
      dispatch(setIsLoading(false))
      setEditFile(false)
      setIsProcessingFile(false)
    }
  }

  const deleteFile = (e, file) => {
    e.preventDefault()
    setIsFetching(true)
    setIsProcessingFile(true)
    const splittedName = file.split('/kyb/')[1].split('?')
    const key = splittedName[splittedName.length - 2]

    let url

    if (isPerson) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}/file/${key}`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/file/${key}`
    }

    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            handleFetch(false, t('messages:deletionSuccess'))
            dispatch(setIsLoading(false))
            setIsProcessingFile(false)
            updateDetail()
          } else {
            throw new Error(
              res.data?.responseBody?.message
                ? res.data?.responseBody?.message
                : res.data?.info
                ? res.data?.info
                : res.message
                ? res.message
                : 'Unexpected',
            )
          }
          setIsProcessingFile(false)
        })
        .catch((error) => {
          console.error('[DELETE KYB FILE ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:deletionError'))
          setIsProcessingFile(false)
        })
    } else {
      handleFetch(false, t('messages:deletionSuccess'))
      dispatch(setIsLoading(false))
    }
  }

  // ASSIGNS
  const updateAssigns = (assignedUsers) => {
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))

    let url

    if (isPerson) {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}subject/${id}`
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}`
    }

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ assignedUsers }),
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          updateDetail()
          handleFetch(false, t('messages:updateSuccess'))
          setErrorAssign(false)
        })
        .catch((error) => {
          console.error('[UPDATE CASE ASSIGNED USERS ERROR]', error)
          handleFetch(true, t('messages:editAssigUserError'))
          setErrorAssign(true)
        })
    } else {
      handleFetch(false, t('messages:updateSuccess'))
      setErrorAssign(false)
    }
  }

  // RUN KYB
  const handleRunKyb = (e, documentNumber, subjectId, index) => {
    e.preventDefault()
    setIsRunningKyb(index)
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))
    if (!process.env.REACT_APP_IS_DEMO) {
      const body = {
        documentNumber: documentNumber,
        subjectId: subjectId,
      }

      let url = `${process.env.REACT_APP_BASEURL}/kyb/${countrySegment}`

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      if (!process.env.REACT_APP_IS_DEMO) {
        fetch(url, options)
          .then((res) => res.json())
          .then((res) => {
            setIsFetching(false)
            setIsRunningKyb('')
            if (res.success && res.data) {
              handleFetch(false, t('messages:runKybSuccess'))
              updateDetail()
            } else {
              throw new Error(
                res.data?.responseBody?.message
                  ? res.data?.responseBody?.message
                  : res.data?.info
                  ? res.data?.info
                  : res.message
                  ? res.message
                  : 'Unexpected',
              )
            }
          })
          .catch((err) => {
            console.error('[DOWNLOAD REPORT ERROR] --> ', err)
            setIsRunningKyb('')
            handleFetch(true, t('messages:documentNotFound'))
          })
      } else {
        setIsFetching(false)
        setIsRunningKyb('')
        handleFetch(false, t('messages:runKybSuccess'))
        updateDetail()
      }
    } else {
      setIsFetching(false)
      setIsRunningKyb('')
      handleFetch(false, t('messages:runKybSuccess'))
    }
  }

  // ADD SHAREHOLDER
  const addShareholder = (e, documentNumber, entity) => {
    e.preventDefault()
    setIsFetching(true)
    setIsProcessing(true)
    setFetchMessage(t('messages:processing'))

    if (!process.env.REACT_APP_IS_DEMO) {
      const data = {
        documentNumber,
        entity,
      }

      const url = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}${id}/subject`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
          updateDetail()
          handleFetch(false, t('messages:creationSuccess'))
          setErrorAssign(false)
        })
        .catch((error) => {
          console.error('[UPDATE CREATE SHAREHOLDER ERROR]', error)
          handleFetch(true, t('messages:editShareholderError'))
          setErrorAssign(true)
        })
    } else {
      handleFetch(false, t('messages:creationSuccess'))
      setErrorAssign(false)
    }
  }

  useEffect(() => {
    if (kyb?.riskLevel) {
      setNewRiskLevel(kyb?.riskLevel)
    } else {
      setNewRiskLevel('LOW')
    }

    if (kyb?.status) {
      setNewStatus(kyb?.status)
    } else {
      setNewStatus('NOT_STARTED')
    }

    if (kyb?.assignedUsers) {
      setAssignedUsers(kyb?.assignedUsers)
    } else {
      setAssignedUsers([])
    }
  }, [kyb])

  useEffect(() => {
    if (kybBase?.toAssign?.length) {
      setToAssign(kybBase.toAssign)
    }
  }, [kybBase])

  useEffect(() => {
    dispatch(setIsLoading(true))
  }, [languageState])

  return (
    <Box className={`${isLoading && 'spinner-transition'}`}>
      {/* Show Alert Fetch Spinner if data is being fetched */}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}

      {/* Show Spinner while data is loading */}
      {isLoading && <Spinner />}

      {/* Main KYB Detail Component */}
      {!isLoading && (
        <Box className="kyb-detail">
          {/* Header Section */}
          <Box className="kyb-detail-head">
            <Paper elevation={0} sx={{ mr: '1rem' }}>
              <Box className="kyb-info">
                <Box className="kyb-info-section-head">
                  <Box className="kyb-info-section-1">
                    {/* Company/Person Name */}
                    <Typography variant="h4">
                      {kyb?.companyDetails?.name ? kyb.companyDetails.name : kyb?.name ? kyb?.name : '---'}
                    </Typography>
                    {/* Account Type Chip */}
                    {isShareholder || isPerson ? (
                      <Chip
                        label={t('common:personAccount')}
                        icon={<PersonOutlineOutlinedIcon />}
                        sx={{ backgroundColor: '#d6f8ff' }}
                      />
                    ) : (
                      <Chip
                        label={t('common:businessAccount')}
                        icon={<WorkOutlineOutlinedIcon />}
                        sx={{ backgroundColor: '#6ede92' }}
                      />
                    )}
                  </Box>
                </Box>
                <Box className="kyb-info-section-2">
                  {/* Email */}
                  <Paper elevation={0} variant="colorPrimary">
                    <Typography variant="subtitle1">{t('common:email')}</Typography>
                    <p>
                      {kyb?.companyDetails?.emails?.length
                        ? kyb.companyDetails.emails[0]
                        : kybBase?.companyDetails?.emails?.length
                        ? kybBase?.companyDetails?.emails[0]
                        : '---'}
                    </p>
                  </Paper>

                  {/* Document Number */}
                  <Paper elevation={0} variant="colorPrimary">
                    <Typography variant="subtitle1">{t('common:documentNumber')}</Typography>
                    <Box className="copy-container">
                      <p>
                        {kyb?.companyDetails?.documentNumber
                          ? kyb.companyDetails.documentNumber
                          : kyb?.documentNumber
                          ? kyb?.documentNumber
                          : '---'}
                      </p>
                      {(kyb?.companyDetails?.documentNumber || kyb?.documentNumber) && (
                        <IconButton
                          style={{ padding: '0' }}
                          onClick={() => {
                            navigator.clipboard.writeText(kyb.companyDetails.documentNumber)
                          }}
                        >
                          <ContentCopyOutlinedIcon style={{ height: '1rem', width: '1rem' }} />
                        </IconButton>
                      )}
                    </Box>
                  </Paper>
                  <Paper elevation={0} variant="colorPrimary">
                    <Typography variant="subtitle1">{t('common:dateOfRegistration')}</Typography>
                    <p style={{ textWrap: 'nowrap' }}>
                      {kyb?.createdAt ? buildTimeStringFromTimestamp(kyb.createdAt) : '---'}
                    </p>
                  </Paper>
                  <Paper elevation={0} variant="colorPrimary">
                    <Typography variant="subtitle1">{t('common:country')}</Typography>
                    {kyb?.companyDetails?.country
                      ? Flags(kyb.companyDetails.country === 'Brazil' ? 'BR' : kyb.companyDetails.country, false, false)
                      : kyb?.nationality
                      ? Flags(kyb?.nationality === 'Brazil' ? 'BR' : kyb?.nationality, false, false)
                      : '---'}
                  </Paper>
                  <Paper elevation={0} variant="colorPrimary">
                    <Typography variant="subtitle1">{t('common:lastUpdated')}</Typography>
                    <p style={{ textWrap: 'nowrap' }}>
                      {kyb?.updatedAt ? buildTimeStringFromTimestamp(kyb.updatedAt) : '---'}
                    </p>
                  </Paper>
                  <Paper elevation={0} variant="colorPrimary">
                    <Typography variant="subtitle1">{t('common:createdOn')}</Typography>
                    <p style={{ textWrap: 'nowrap' }}>
                      {kyb?.createdAt ? buildTimeStringFromTimestamp(kyb.createdAt) : '---'}
                    </p>
                  </Paper>
                  <Box className="kyb-info-section-span">
                    {!isShareholder && !isPerson && (
                      <Paper elevation={0} variant="colorPrimary">
                        <Typography variant="subtitle1">{t('common:phone')}</Typography>
                        <Typography variant="number">
                          {Array.isArray(kyb?.companyDetails?.telefones) &&
                            kyb.companyDetails.telefones?.length > 1 &&
                            kyb.companyDetails.telefones?.map((t) => {
                              return `${t.ddd && t.ddd !== null ? t.ddd : ''} ${
                                t.numero && t.numero !== null ? t.numero : ''
                              }`
                            })}
                        </Typography>
                      </Paper>
                    )}
                    {isShareholder && (
                      <Paper elevation={0} variant="colorPrimary">
                        <Typography variant="subtitle1">{t('common:relationship')}</Typography>
                        <p>{kyb?.relationship?.name ? kyb?.relationship?.name : '---'}</p>
                      </Paper>
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
            <Box className="details-sub">
              <Button sx={{ width: '100%' }} size="small" onClick={() => setModalOpen(true)} variant="contained">
                {t('common:postComment')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)!important' }} />}
              >
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>
              {!isShareholder && !isPerson && (
                <Paper elevation={0} sx={{ p: '1rem' }} className="risk-level">
                  <Box className="risk-level-top">
                    <Typography variant="subtitle2">{t('common:riskLevel')}</Typography>
                    <Button size="small" onClick={() => setModalOpen5(true)} variant="outlined">
                      {t('common:edit')}
                    </Button>
                  </Box>
                  {kyb?.riskLevel ? <RiskLevelSlider type={kyb?.riskLevel} /> : <RiskLevelSlider type={'LOW'} />}
                </Paper>
              )}
              <Paper elevation={0} sx={{ p: '1rem' }} className="status-level">
                <Box className="status-level-top">
                  <Typography variant="subtitle2">{t('common:status')}</Typography>
                  <Button size="small" onClick={() => setModalOpen2(true)} variant="outlined">
                    {t('common:edit')}
                  </Button>
                </Box>
                {kyb?.status ? <StatusIndicator type={kyb?.status} /> : <StatusIndicator type={'NOT_STARTED'} />}
              </Paper>
            </Box>
          </Box>
          <Box className="kyb-subdetail">
            <Box sx={{ paddingRight: '1rem' }}>
              <Paper className="kyb-detail-timeline" elevation={0} sx={{ padding: '1rem' }}>
                <Typography variant="h4">{t('common:timelineAndActivity')}</Typography>
                {Array.isArray(reversedTimeline()) && (
                  <Box className="timeline-wrapper">
                    {reversedTimeline().map((timeline) => {
                      const { color, backgroundColor } = getStyles(timeline.type)
                      return (
                        <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                          <Box className="timeline-box">
                            <Box title={t(`common:${timeline.type}`)}>
                              <KybChip
                                action={t(`common:${timeline.type}`)}
                                color={color}
                                backgroundColor={backgroundColor}
                              />
                            </Box>
                          </Box>
                          <p>{buildTimeStringFromTimestamp(timeline.createdAt)}</p>
                          <Box className="timeline-box">
                            <p>{t('common:status')}:</p>
                            <Typography variant="text2">{t(`common:${timeline.status}`)}</Typography>
                          </Box>
                          <Box className="timeline-box">
                            <p>{t('common:operationalAgent')}:</p>
                            <Typography variant="text2">{timeline.email}</Typography>
                          </Box>
                        </Paper>
                      )
                    })}
                  </Box>
                )}
              </Paper>
            </Box>
            <Box className="kyb-subgrid">
              <TabContext value={selectedTab}>
                <CustomTabList
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  onChange={(e, newValue) => setSelectedTab(newValue)}
                  variant="scrollable"
                >
                  <Tab
                    label={
                      <Chip
                        label={t('common:checks')}
                        variant={selectedTab === 'checks' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    sx={{ maxWidth: isShareholder ? '50%' : '100%' }}
                    value="checks"
                  ></Tab>
                  {!isShareholder && (
                    <Tab
                      label={
                        <Chip
                          label={t('common:documents')}
                          variant={selectedTab === 'documents' ? 'sliderBlack' : 'sliderWhite'}
                        />
                      }
                      value="documents"
                    />
                  )}
                  {!isShareholder && !isPerson && (
                    <Tab
                      label={
                        <Chip
                          label={t('common:shareholders')}
                          variant={selectedTab === 'shareholders' ? 'sliderBlack' : 'sliderWhite'}
                        />
                      }
                      value="shareholders"
                    />
                  )}
                  {!isShareholder && (
                    <Tab
                      label={
                        <Chip
                          label={t('common:assignedUsers')}
                          variant={selectedTab === 'assignedUsers' ? 'sliderBlack' : 'sliderWhite'}
                        />
                      }
                      value="assignedUsers"
                    />
                  )}
                  <Tab
                    label={
                      <Chip
                        label={t('common:comments')}
                        variant={selectedTab === 'comments' ? 'sliderBlack' : 'sliderWhite'}
                      />
                    }
                    value="comments"
                  />
                </CustomTabList>
                <Box>
                  <TabPanel value="checks">
                    <Box className="kyb-checks-flex">
                      {checks.map((mainChecks, indexMain) => (
                        <Accordion
                          key={indexMain}
                          expanded={expandedMain[indexMain]}
                          onChange={() => handleChangeExpandedMain(indexMain)}
                        >
                          <AccordionSummary expandIcon={null}>
                            <Box className="accordion-wrapper">
                              <Box className="accordion-status">
                                <Typography variant="title">{mainChecks?.title}</Typography>
                                <Typography variant="subtitle3">{mainChecks?.subtitle}</Typography>
                              </Box>
                              <Button
                                variant="outlinedBlank"
                                onChange={(e) => {
                                  e.stopPropagation()
                                  return handleChangeExpandedMain(indexMain)
                                }}
                              >
                                {expandedMain[indexMain] ? (
                                  <KeyboardDoubleArrowDownIcon />
                                ) : (
                                  <KeyboardDoubleArrowUpIcon />
                                )}
                              </Button>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            {mainChecks?.items?.map((check, index) => {
                              const key = `${indexMain}-${index}`
                              return (
                                <Accordion
                                  key={key}
                                  expanded={expanded[key] || false}
                                  onChange={(e) => {
                                    e.stopPropagation()
                                    handleChangeExpanded(indexMain, index)
                                  }}
                                  variant="medium"
                                >
                                  <AccordionSummary expandIcon={null}>
                                    <Box className="accordion-wrapper">
                                      <Box className="accordion-status">
                                        <Typography variant="text2">{check.title}</Typography>
                                        <Box>
                                          {kyb[check.name] ? (
                                            <Box className="accordion-status-icon">
                                              <Status
                                                type={kyb[check.name].status}
                                                noIcon={true}
                                                sizeBig={true}
                                                variant="colorSecondary"
                                              />
                                            </Box>
                                          ) : (
                                            <Box className="accordion-status-icon">
                                              <Status
                                                type={'NOT_STARTED'}
                                                noIcon={true}
                                                sizeBig={true}
                                                variant="colorSecondary"
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                      <Button
                                        variant="outlinedBlank"
                                        onChange={(e) => {
                                          e.stopPropagation()
                                          return handleChangeExpanded(index)
                                        }}
                                      >
                                        {expanded[index] ? (
                                          <KeyboardDoubleArrowDownIcon />
                                        ) : (
                                          <KeyboardDoubleArrowUpIcon />
                                        )}
                                      </Button>
                                    </Box>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Box>
                                      {isProcessing && <ProcessingSpinner message={t('common:processing')} />}
                                      {kyb[check.name] && !isProcessing && check.name === 'jap' ? (
                                        <Box>
                                          {renderJap(
                                            Array.isArray(kyb[check.name]?.checkDetails)
                                              ? kyb[check.name]?.checkDetails[0]?.Lawsuits
                                              : kyb[check.name]?.checkDetails,
                                          )}
                                        </Box>
                                      ) : kyb[check.name] && !isProcessing && check.name === 'lawsuits' ? (
                                        <Box>
                                          {renderLawsuits(
                                            Array.isArray(kyb[check.name]?.checkDetails)
                                              ? kyb[check.name]?.checkDetails[0]?.OwnersLawsuits
                                              : kyb[check.name]?.checkDetails,
                                          )}
                                        </Box>
                                      ) : kyb[check.name] && !isProcessing && check.name === 'negativeMedia' ? (
                                        <Box>
                                          <h3>{t('common:source')} 1</h3>
                                          <Box>{renderNegativeMedia(kyb[check.name]?.checkDetails?.source1)}</Box>
                                          <br />
                                          {kyb[check.name]?.checkDetails?.source2 && <h3>{t('common:source')} 2</h3>}
                                          {kyb[check.name]?.checkDetails?.source2 && (
                                            <Box>
                                              <RecursiveProperty
                                                property={kyb[check.name]?.checkDetails?.source2}
                                                excludeBottomBorder={false}
                                                rootProperty={true}
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      ) : kyb[check.name] && !isProcessing ? (
                                        <Box
                                          className="recursive-property"
                                          sx={{ backgroundColor: theme === 'light' ? '#FCFFFD' : '#020D06' }}
                                        >
                                          <RecursiveProperty
                                            property={
                                              Array.isArray(kyb[check.name]?.checkDetails)
                                                ? kyb[check.name]?.checkDetails[0]
                                                : kyb[check.name]?.checkDetails
                                            }
                                            excludeBottomBorder={false}
                                            rootProperty={true}
                                          />
                                        </Box>
                                      ) : (
                                        <Box>
                                          {!isFetching && (
                                            <Button
                                              size="small"
                                              variant="contained"
                                              color="secondary"
                                              disabled={isFetching}
                                              onClick={(e) => handleCheck(e, check.key, index, indexMain)}
                                            >
                                              {t('common:run')} {camelCaseFormatter(check.name)}
                                            </Button>
                                          )}
                                        </Box>
                                      )}
                                      {!isFetching && (
                                        <Box className="kyb-close">
                                          {kyb[check.name] && (
                                            <Box className="kyb-check-status">
                                              <Button
                                                size="small"
                                                fullWidth
                                                onClick={() => {
                                                  setModalOpen3(!modalOpen3)
                                                  setCheckId(kyb[check.name]?._id)
                                                  setCheckStatus(kyb[check.name]?.status)
                                                  setCheckIndex(index)
                                                  setCheckIndexMain(indexMain)
                                                }}
                                                variant="contained"
                                              >
                                                {t('common:changeStatus')}
                                              </Button>
                                              <Button
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                disabled={isFetching}
                                                onClick={(e) => handleCheck(e, check.key, index, indexMain)}
                                              >
                                                {t('common:run')} {camelCaseFormatter(check.name)} {t('common:again')}
                                              </Button>
                                            </Box>
                                          )}
                                        </Box>
                                      )}
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              )
                            })}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </TabPanel>
                  {!isShareholder && (
                    <TabPanel value="documents">
                      <Paper elevation={0}>
                        <Box className="kyb-detail-documents">
                          <Box className="kyb-documents-top">
                            <Typography variant="title">{t('common:documents')}</Typography>
                            <Typography variant="subtitle3">{t('messages:updatePDFRelated')}</Typography>
                          </Box>
                          <Box className="kyb-files-drag">
                            <Typography variant="title2">{t('common:addAFile')}</Typography>
                            <FileUploader
                              handleChange={(file) => uploadFile(file)}
                              name="file"
                              label={t('common:fileUploaderLabel')}
                              types={['JPG', 'JPEG', 'PNG', 'GIF', 'PDF', 'DOC', 'CSV']}
                              children={
                                <Paper elevation={0} variant="rootDashedTransparent" className="draggable">
                                  <Box className="draggable-icon"></Box>
                                  <Typography variant="title2">{t('common:fileUploaderLabel')}</Typography>
                                  <Box>
                                    <Typography variant="subtitle4">{t('common:or')} </Typography>
                                    <Typography
                                      sx={{ textDecoration: 'underline' }}
                                      variant="subtitle4"
                                      color="secondary"
                                    >
                                      {t('common:browseMedia')}
                                    </Typography>
                                  </Box>
                                </Paper>
                              }
                            />
                            <Typography variant="subtitle4">
                              {t('common:supportedMedia')}: JPG, PNG, GIF, MP4, MPEG, WEBM, MP3, WAV, PDF.
                            </Typography>
                          </Box>
                          <Box className="kyb-file-list-wrapper">
                            <Typography variant="title"> {t('common:uploadedDocuments')}</Typography>
                            <Box className="kyb-file-list">
                              {isProcessingFile ? (
                                <ProcessingSpinner message={t('common:processing')} />
                              ) : (
                                <Box>
                                  {Array.isArray(kyb?.files) && kyb.files.length > 0 && (
                                    <Box className="kyb-files">
                                      {kyb?.files[0] instanceof String &&
                                        kyb.files.map((file, index) => {
                                          const splittedName = file.split('/kyb/')[1].split('_')
                                          const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                                          return (
                                            <Paper
                                              elevation={0}
                                              variant="colorPrimary"
                                              className="kyb-file"
                                              key={index}
                                            >
                                              <Box className="kyb-file-description">
                                                <Box className="color-button">
                                                  <Typography variant="subtitle1" sx={{ color: '#FF2B1B' }}>
                                                    DOC
                                                  </Typography>
                                                </Box>
                                                <Typography variant="text2" title={fileName}>
                                                  {fileName}
                                                </Typography>
                                              </Box>
                                              <Box className="kyb-file-icons">
                                                <Button
                                                  variant="outlined"
                                                  size="small"
                                                  onClick={() => window.open(file, '_blank')}
                                                >
                                                  {t('common:view')}
                                                </Button>
                                                <Button variant="outlinedBlank" onClick={(e) => deleteFile(e, file)}>
                                                  <DeleteOutlineIcon />
                                                </Button>
                                              </Box>
                                            </Paper>
                                          )
                                        })}
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </TabPanel>
                  )}
                  {!isShareholder && !isPerson && (
                    <TabPanel value="shareholders">
                      <Paper elevation={0}>
                        <Box className="kyb-card">
                          <Box className="card-top">
                            <Box className="card-titles">
                              <Typography variant="title">{t('common:listOfShareholders')}</Typography>
                              <Typography variant="subtitle3">{t('messages:viewOwnership')}</Typography>
                            </Box>
                            <Button
                              onClick={() => setModalOpen4(true)}
                              size="small"
                              variant="contained"
                              color="secondary"
                            >
                              {t('common:addShareholder')}
                            </Button>
                          </Box>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('common:actions')}</TableCell>
                                  <TableCell>{t('common:status')}</TableCell>
                                  <TableCell>{t('common:name')}</TableCell>
                                  <TableCell>{t('common:documentNumber')}</TableCell>
                                  <TableCell>{t('common:nationality')}</TableCell>
                                  <TableCell>{t('common:role')}</TableCell>
                                  <TableCell>{t('common:roleType')}</TableCell>
                                  <TableCell>{t('common:relationship')}</TableCell>
                                  <TableCell>{t('common:creationTime')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Array.isArray(kyb?.shareHolders) && kyb?.shareHolders.length !== 0 ? (
                                  kyb?.shareHolders?.map((row, index) => {
                                    const back = index % 2 === 0 ? '' : 'gray-back'
                                    return (
                                      <TableRow className={back} key={index}>
                                        <TableCell>
                                          <Box>
                                            {row.type === 'PERSON' ? (
                                              <Link
                                                to={`/kyb/${currentCountry ?? 'BR'}/detail/shareholder/${row._id}`}
                                                onClick={() => {
                                                  setSelectedTab('checks')
                                                  dispatch(setIsLoading(true))
                                                  return navigate(
                                                    `/kyb/${currentCountry ?? 'BR'}/detail/shareholder/${row._id}`,
                                                  )
                                                }}
                                              >
                                                <p>{t('common:viewDetails')}</p>
                                              </Link>
                                            ) : row.type === 'BUSINESS' && row.kyb ? (
                                              <Link
                                                to={`/kyb/${currentCountry ?? 'BR'}/detail/${row.kyb}`}
                                                onClick={() => {
                                                  setSelectedTab('checks')
                                                  dispatch(setIsLoading(true))
                                                  return navigate(`/kyb/${currentCountry ?? 'BR'}/detail/${row.kyb}`)
                                                }}
                                              >
                                                <p>{t('common:viewDetails')}</p>
                                              </Link>
                                            ) : row.type === 'BUSINESS' && isRunningKyb === index ? (
                                              <ProcessingSpinner message={t('common:processing')} />
                                            ) : (
                                              <Button
                                                onClick={(e) => handleRunKyb(e, row.documentNumber, row._id, index)}
                                                size="small"
                                                variant="contained"
                                              >
                                                {t('common:run')} KYB
                                              </Button>
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          <Box>
                                            {row.status ? <Status type={row.status} variant="colorSecondary" /> : '---'}
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          <Box>{row.name ?? '---'}</Box>
                                        </TableCell>
                                        <TableCell>{row.documentNumber ?? '---'}</TableCell>
                                        <TableCell>
                                          {row.nationality
                                            ? Flags(row.nationality === 'Brazil' ? 'BR' : row.nationality)
                                            : '---'}
                                        </TableCell>
                                        <TableCell>
                                          {row.type === 'PERSON' ? t('common:person') : t('common:business')}
                                        </TableCell>
                                        <TableCell>{row.relationship?.type ? row.relationship?.type : '---'}</TableCell>
                                        <TableCell>{row.relationship?.name ? row.relationship?.name : '---'}</TableCell>
                                        <TableCell>
                                          {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })
                                ) : (
                                  <Box className="table-custom-overlay">
                                    <CustomNoRowsOverlay />
                                  </Box>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Paper>
                    </TabPanel>
                  )}
                  {!isShareholder && (
                    <TabPanel value="assignedUsers">
                      <Paper elevation={0}>
                        <Box className="kyb-card">
                          <Box className="card-top">
                            <Typography variant="title">{t('common:assignedUsers')}</Typography>
                            {!isEditingAssign ? (
                              <Button
                                onClick={() => {
                                  setIsEditingAssign(true)
                                }}
                                size="small"
                                variant="contained"
                              >
                                {t('common:addUser')}
                              </Button>
                            ) : (
                              <Fab variant="close" onClick={() => setIsEditingAssign(false)}>
                                <CloseOutlinedIcon />
                              </Fab>
                            )}
                          </Box>
                          <DatatableAssignUsers
                            rows={assignedUsers}
                            toAssign={toAssign}
                            isEditing={isEditingAssign}
                            updateAssigns={updateAssigns}
                            setIsEditingAssign={setIsEditingAssign}
                            errorAssign={errorAssign}
                          />
                        </Box>
                      </Paper>
                    </TabPanel>
                  )}
                  <TabPanel value="comments">
                    <Paper elevation={0}>
                      <Box className="kyb-card">
                        <Box className="card-top">
                          <Typography variant="title">{t('common:comments')}</Typography>
                        </Box>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t('common:email')}</TableCell>
                                <TableCell>{t('common:createdAt')}</TableCell>
                                <TableCell>{t('common:comment')}</TableCell>
                              </TableRow>
                            </TableHead>

                            {Array.isArray(kyb?.comments) && kyb?.comments?.length > 0 ? (
                              kyb.comments.map((row, index) => (
                                <TableBody key={index}>
                                  <TableRow>
                                    <TableCell>
                                      <Box>{row.email ?? '---'}</Box>
                                    </TableCell>
                                    <TableCell>
                                      {row.createdAt ? buildTimeStringFromTimestamp(row.createdAt) : '---'}
                                    </TableCell>
                                    <TableCell>{row.description ?? '---'}</TableCell>
                                  </TableRow>
                                </TableBody>
                              ))
                            ) : (
                              <Box className="table-custom-overlay">
                                <CustomNoRowsOverlay />
                              </Box>
                            )}
                          </Table>
                        </TableContainer>
                      </Box>
                    </Paper>
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </Box>

          <KybToPdf show={showDownloadModal} setShow={setShowDownloadModal} kyb={kyb} />

          <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
            {isProcessing ? (
              <ProcessingSpinner message={t('common:processing')} />
            ) : (
              <Box className="modal">
                <Box className="modal-top">
                  <Box className="modal-titles">
                    <Typography variant="title">{t('common:postComment')}</Typography>
                    <Typography variant="subtitle3">{t('messages:commentDesc')}</Typography>
                  </Box>
                  <Fab variant="close" onClick={() => setModalOpen(false)}>
                    <CloseOutlinedIcon />
                  </Fab>
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:comment')}:</InputLabel>
                  <TextField
                    multiline
                    placeholder={t('messages:minCharacters')}
                    required
                    onChange={(e) => setNewCommentMessage(e.target.value)}
                  />
                </Box>
                <Box className="modal-button">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    disabled={newCommentMessage.length < 5}
                    onClick={() => postNewComment()}
                  >
                    {t('common:publishComment')}
                  </Button>
                </Box>
              </Box>
            )}
          </Modal>

          <Modal open={modalOpen2} onClose={() => setModalOpen2(false)}>
            {isProcessing ? (
              <ProcessingSpinner message={t('common:processing')} />
            ) : (
              <Box className="modal">
                <Box className="modal-top">
                  <Box className="modal-titles">
                    <Typography variant="title">
                      {t('common:edit')} KYB {t('common:status')}
                    </Typography>
                  </Box>
                  <Fab variant="close" onClick={() => setModalOpen2(false)}>
                    <CloseOutlinedIcon />
                  </Fab>
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:status')}:</InputLabel>
                  <Select
                    size="small"
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {kybStatusEnum.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`common:${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="modal-button">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleUpdate(e, { status: newStatus })}
                  >
                    {t('common:save')}
                  </Button>
                </Box>
              </Box>
            )}
          </Modal>

          <Modal open={modalOpen3} onClose={() => setModalOpen3(false)}>
            {isProcessing ? (
              <ProcessingSpinner message={t('common:processing')} />
            ) : (
              <Box className="modal">
                <Box className="modal-top">
                  <Box className="modal-titles">
                    <Typography variant="title">
                      {t('common:edit')} {t('common:check')} {t('common:status')}
                    </Typography>
                  </Box>
                  <Fab variant="close" onClick={() => setModalOpen2(false)}>
                    <CloseOutlinedIcon />
                  </Fab>
                </Box>
                <Box className="modal-box">
                  <InputLabel htmlFor="status">{t('common:status')}:</InputLabel>
                  <Select
                    id="status"
                    size="small"
                    value={newCheckStatus ? newCheckStatus : checkStatus}
                    onChange={(e) => setNewCheckStatus(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {kybStatusEnum.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`common:${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="modal-button">
                  <Button size="small" variant="outlinedGrey" onClick={() => setModalOpen3(false)}>
                    {t('common:cancel')}
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(e) =>
                      handleCheckUpdate(e, checkId, { status: newCheckStatus }, checkIndex, checkIndexMain)
                    }
                  >
                    {t('common:save')}
                  </Button>
                </Box>
              </Box>
            )}
          </Modal>

          <Modal
            open={modalOpen4}
            onClose={() => setModalOpen4(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {isProcessing ? (
              <ProcessingSpinner message={t('common:processing')} />
            ) : (
              <Box className="modal">
                <Box className="modal-top">
                  <Box className="modal-titles">
                    <Typography variant="title">{t('messages:addNewShareholder')}</Typography>
                    <Typography variant="subtitle3">{t('messages:editLater')}</Typography>
                  </Box>
                  <Fab variant="close" onClick={() => setModalOpen4(false)}>
                    <CloseOutlinedIcon />
                  </Fab>
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:documentNumber')}:</InputLabel>
                  <TextField
                    placeholder={t('messages:insertDocHere')}
                    required
                    value={shareholderDocumentNumber}
                    onChange={(e) => setShareholderDocumentNumber(e.target.value)}
                  />
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:entityType')}:</InputLabel>
                  <Select
                    size="small"
                    value={shareholderEntity}
                    onChange={(e) => setShareholderEntity(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {['PERSON', 'BUSINESS']?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="subtitle4">{t('messages:shareholderDesc')}</Typography>
                </Box>
                <Box className="modal-button">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={(e) => addShareholder(e, shareholderDocumentNumber, shareholderEntity)}
                  >
                    {t('common:save')}
                  </Button>
                  <Button size="small" color="secondary" variant="outlined" onClick={() => setModalOpen4(false)}>
                    {t('common:cancel')}
                  </Button>
                </Box>
              </Box>
            )}
          </Modal>

          <Modal
            open={modalOpen5}
            onClose={() => setModalOpen5(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {isProcessing ? (
              <ProcessingSpinner message={t('common:processing')} />
            ) : (
              <Box className="modal">
                <Box className="modal-top">
                  <Box className="modal-titles">
                    <Typography variant="title">
                      {t('common:edit')} KYB {t('common:riskLevel')}
                    </Typography>
                  </Box>
                  <Fab variant="close" onClick={() => setModalOpen5(false)}>
                    <CloseOutlinedIcon />
                  </Fab>
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:riskLevel')}:</InputLabel>
                  <Select
                    id="risk-level"
                    size="small"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={newRiskLevel}
                    onChange={(e) => setNewRiskLevel(e.target.value)}
                  >
                    {kybRiskLevelEnum.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`common:${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="modal-button">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleUpdateRiskLevel(e, newRiskLevel)}
                  >
                    {t('common:save')}
                  </Button>
                </Box>
              </Box>
            )}
          </Modal>
        </Box>
      )}
    </Box>
  )
}

export default KybDetail
