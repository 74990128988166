import './CreateRuleEditParameters2.scss'
import '../rulesLibrary/rulesDrawer.scss'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import TransactionAmountThreshold from '../ruleParameters2/transactionAmountThreshold/transactionAmountThreshold'
import SelectSingle from '../ruleParameters2/selectSingle/selectSingle'
import SelectMultiple from '../ruleParameters2/selectMultiple/selectMultiple'
import NumberInput from '../ruleParameters2/numberInput/numberInput'
import LowTransactionValues from '../ruleParameters2/lowTransactionValues/lowTransactionValues'
import TimeWindow from '../ruleParameters2/timeWindow/timeWindow'
import ListOfStrings from '../ruleParameters2/listOfStrings/listOfStrings'
import MinMaxValues from '../ruleParameters2/minMaxValues/minMaxValues'
import CheckboxItem from '../ruleParameters2/checkbox/checkbox'
import ListOfObjects from '../ruleParameters2/listOfObjects/listOfObjects'
import NumberAndStringInputs from '../ruleParameters2/numberAndStringInputs/numberAndStringInputs'
import StringInput from '../ruleParameters2/stringInput/stringInput'
import Comparator from '../ruleParameters2/comparator/comparator'
import TransactionCountThreshold from '../ruleParameters2/transactionCountThreshold/transactionCountThreshold'
import NumberAndCheck from '../ruleParameters2/numberAndCheck/numberAndCheck'
import { Box, Divider, Paper, Typography } from '@mui/material'
import { camelCaseToPhrase } from '../../../common/toRender/camelCAseToPhrase'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../../utilities/TableChip'

const CreateRuleEditParameters2 = ({
  rule = { name: '' },
  ruleParameters,
  setRuleParameters,
  selectedAction,
  setSelectedAction,
  setError,
  errorList,
  setErrorList,
  isEditing,
}) => {
  //Rule parameters
  const getRuleParameters = (rule) => {
    if (rule.parametersSchema['ui:schema']) {
      return rule.parametersSchema['ui:schema']['ui:order']
    } else {
      return Object.keys(rule.parametersSchema?.properties)
    }
  }
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box>
      {(!rule.parametersSchema ||
        Object.keys(rule.parametersSchema).length === 0 ||
        rule.parametersSchema.additionalProperties === false) && (
        <Box className="Label">
          <span>No editable parameters...</span>
        </Box>
      )}
      {rule.parametersSchema && Object.keys(rule.parametersSchema).length !== 0 && (
        <Box>
          {Object.keys(rule.parametersSchema.properties).length !== 0 && (
            <Box>
              {getRuleParameters(rule).map((item, index) => (
                <Box key={index}>
                  <Box>
                    <Box className="title-parameter">
                      {rule.parametersSchema.required?.indexOf(item) > -1 && <span className="mandatory">* </span>}
                      <Typography variant="title3">
                        {rule.parametersSchema.properties[item].title
                          ? camelCaseToPhrase(rule.parametersSchema.properties[item].title)
                          : camelCaseToPhrase(item)}
                      </Typography>
                    </Box>
                    {rule.parametersSchema.properties[item].type === 'object' &&
                      (rule.parametersSchema.properties[item].additionalProperties?.type === 'integer' ||
                        rule.parametersSchema.properties[item].additionalProperties?.type === 'number') && (
                        <TransactionAmountThreshold
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          setError={setError}
                          errorList={errorList}
                          setErrorList={setErrorList}
                          isRequired={rule.parametersSchema.required?.indexOf(item) > -1}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'string' &&
                      rule.parametersSchema.properties[item].enum && (
                        <SelectSingle
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          posibleValues={rule.parametersSchema.properties[item].enum}
                          desc={rule.parametersSchema.properties[item].description}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'array' &&
                      rule.parametersSchema.properties[item].items?.type === 'string' &&
                      rule.parametersSchema.properties[item].items.enum && (
                        <SelectMultiple
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          posibleValues={rule.parametersSchema.properties[item].items.enum}
                        />
                      )}
                    {(rule.parametersSchema.properties[item].type === 'integer' ||
                      rule.parametersSchema.properties[item].type === 'number' ||
                      rule.parametersSchema.properties[item]?.component === 'NumberInput') && (
                      <NumberInput
                        isEditing={isEditing}
                        propertie={item}
                        ruleParameters={ruleParameters}
                        setRuleParameters={setRuleParameters}
                        schema={rule.parametersSchema.properties[item]}
                      />
                    )}
                    {rule.parametersSchema.properties[item].type === 'string' &&
                      !rule.parametersSchema.properties[item].enum && (
                        <StringInput
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          schema={rule.parametersSchema.properties[item]}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'array' &&
                      rule.parametersSchema.properties[item].items?.type === 'string' &&
                      !rule.parametersSchema.properties[item].items.enum && (
                        <ListOfStrings
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          defaultListValues={rule.defaultParameters[item] ? rule.defaultParameters[item] : []}
                          setError={setError}
                          errorList={errorList}
                          setErrorList={setErrorList}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'array' &&
                      rule.parametersSchema.properties[item].items?.type === 'object' && (
                        <ListOfObjects
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          defaultListValues={[]}
                        />
                      )}
                    {item === 'lowTransactionValues' && (
                      <LowTransactionValues
                        isEditing={isEditing}
                        propertie={item}
                        ruleParameters={ruleParameters}
                        setRuleParameters={setRuleParameters}
                        setError={setError}
                        errorList={errorList}
                        setErrorList={setErrorList}
                        isRequired={rule.parametersSchema.required?.indexOf(item) > -1}
                      />
                    )}
                    {rule.parametersSchema.properties[item].type === 'object' &&
                      rule.parametersSchema.properties[item].properties?.granularity && (
                        <TimeWindow
                          isEditing={isEditing}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          granularity={rule.parametersSchema.properties[item].properties.granularity.enum}
                          desc={rule.parametersSchema.properties[item].properties.rollingBasis.description}
                          schema={rule.parametersSchema.properties[item].properties.units}
                          setError={setError}
                          errorList={errorList}
                          setErrorList={setErrorList}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'object' &&
                      (rule.parametersSchema.properties[item].properties?.min ||
                        rule.parametersSchema.properties[item].properties?.minAge ||
                        rule.parametersSchema.properties[item].properties?.from) && (
                        <MinMaxValues
                          isEditing={isEditing}
                          rule={rule}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                        />
                      )}
                    {(rule.parametersSchema.properties[item].type === 'boolean' ||
                      rule.parametersSchema.properties[item]?.component === 'CheckboxItem') && (
                      <CheckboxItem
                        isEditing={isEditing}
                        propertie={item}
                        ruleParameters={ruleParameters}
                        setRuleParameters={setRuleParameters}
                        label={rule.parametersSchema.properties[item].title}
                        text={rule.parametersSchema.properties[item].description}
                      />
                    )}
                    {rule.parametersSchema.properties[item].type === 'object' &&
                      rule.parametersSchema.properties[item].properties?.value &&
                      rule.parametersSchema.properties[item].properties?.currency && (
                        <NumberAndStringInputs
                          isEditing={isEditing}
                          schema={rule.parametersSchema.properties[item]}
                          propertie={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'object' &&
                      rule.parametersSchema.properties[item].properties?.comparator && (
                        <Comparator
                          isEditing={isEditing}
                          property={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          setError={setError}
                          setErrorList={setErrorList}
                          errorList={errorList}
                          possibleValues={rule.parametersSchema.properties[item].properties?.comparator.enum}
                          desc={'Comparator'}
                          schema={rule.parametersSchema.properties[item].properties?.value}
                        />
                      )}
                    {rule.parametersSchema.properties[item].type === 'object' &&
                      rule.parametersSchema.properties[item].properties?.threshold &&
                      rule.parametersSchema.properties[item].properties?.timeWindow && (
                        <TransactionCountThreshold
                          isEditing={isEditing}
                          property={item}
                          ruleParameters={ruleParameters}
                          setRuleParameters={setRuleParameters}
                          setError={setError}
                          setErrorList={setErrorList}
                          errorList={errorList}
                          desc={
                            rule.parametersSchema?.properties[item].properties?.timeWindow?.rollingBasis?.description
                          }
                          granularity={
                            rule.parametersSchema?.properties[item].properties?.timeWindow?.properties.granularity.enum
                          }
                          numberInputSchema={rule.parametersSchema?.properties[item].properties?.threshold}
                          timeWindowSchema={
                            rule.parametersSchema?.properties[item].properties?.timeWindow?.properties.units
                          }
                        />
                      )}
                    {item === 'transactionsCounterPartiesThreshold' && (
                      <NumberAndCheck
                        isEditing={isEditing}
                        schema={rule.parametersSchema.properties[item]}
                        propertie={item}
                        ruleParameters={ruleParameters}
                        setRuleParameters={setRuleParameters}
                        setError={setError}
                        errorList={errorList}
                        setErrorList={setErrorList}
                      />
                    )}

                    <Divider sx={{ margin: '1rem 0' }} />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}

      <Box className="drawer-block">
        <Typography variant="title">{t('common:ruleActions')}</Typography>
        <FormControl>
          <Box className="actions-grid">
            <Paper
              elevation={0}
              variant="colorPrimary"
              className="actions-flex"
              sx={{ border: selectedAction === 'FLAG' ? '1px solid var(--alert-dark)' : 'none' }}
            >
              <FormControlLabel
                disabled={!isEditing}
                checked={selectedAction === 'FLAG'}
                value="FLAG"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--alert-dark)',
                      },
                    }}
                  />
                }
                label={<TableChip action="FLAG" noIcon={true} />}
                onChange={() => setSelectedAction('FLAG')}
                labelPlacement="start"
              />
              <Typography variant="subtitle4">
                Allow the transaction to continue but mark as FLAGGED. Recommended for AML Rules.
              </Typography>
            </Paper>

            <Paper
              elevation={0}
              variant="colorPrimary"
              className="actions-flex"
              sx={{ border: selectedAction === 'SUSPEND' ? '1px solid var(--calm-dark)' : 'none' }}
            >
              <FormControlLabel
                disabled={!isEditing}
                checked={selectedAction === 'SUSPEND'}
                value="SUSPEND"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--calm-dark)',
                      },
                    }}
                  />
                }
                label={<TableChip action="SUSPEND" noIcon={true} />}
                onChange={() => setSelectedAction('SUSPEND')}
                labelPlacement="start"
              />
              <Typography variant="subtitle4">Put the transaction on hold till investigation is complete.</Typography>
            </Paper>

            <Paper
              elevation={0}
              variant="colorPrimary"
              className="actions-flex"
              sx={{ border: selectedAction === 'BLOCK' ? '1px solid var(--error-dark)' : 'none' }}
            >
              <FormControlLabel
                disabled={!isEditing}
                checked={selectedAction === 'BLOCK'}
                value="BLOCK"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--error-dark)',
                      },
                    }}
                  />
                }
                label={<TableChip action="BLOCK" noIcon={true} />}
                onChange={() => setSelectedAction('BLOCK')}
                labelPlacement="start"
              />
              <Typography variant="subtitle4">
                Stop transaction from continuing. Recommended for Fraud Rules.
              </Typography>
            </Paper>
          </Box>
        </FormControl>
      </Box>
    </Box>
  )
}

export default CreateRuleEditParameters2
