import '../home/home.scss'
import '../onboarding/onboarding.scss'
import './kyb.scss'
import { Box, Tab, Select, MenuItem, Modal, Typography, InputLabel, Paper, Link, Fab } from '@mui/material'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import { buildTimeStringFromTimestamp } from '../../components/common/time/timeHelper'
import { RiskLevel } from '../../components/utilities/RiskLevel'
import { useKybNotifications } from '../pages-hooks/useKybNotifications'
import { updateKybNotificationsFilters } from '../../features/kybNotifications/kybNotificationsSlice'
import Spinner from '../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { kybRiskLevelEnum } from '../../pages/kyb/kyb.enums'
import { TableIcons } from '../../components/utilities/TableIcons'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { FilterModal } from '../../components/utilities/FilterModal'
import { DataGridComponent } from '../../components/common/datagrid/DataGridComponent'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CountrySelector from '../../components/kyb/countrySelector'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'

const CustomTabList = styled(TabList)({
  borderBottom: '1px solid #1C282026',
  '& .MuiTabs-flexContainer': {
    gap: '1rem !important',
    display: 'flex',
  },
});

const statusEnum = ['COMPLETED', 'PENDING', 'CANCELED'];

const KybNotifications = () => {
  const { tab } = useParams();
  const { accessToken } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryState = useSelector((state) => state.kybNotifications.filters);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [toDate, setToDate] = useState(queryState.toDate);
  const [id, setId] = useState(queryState.id);
  const [status, setStatus] = useState(queryState.status ?? 'INACTIVE');
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate);
  const [riskLevel, setRiskLevel] = useState(queryState.riskLevel ?? '');
  const [fetchError, setFetchError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLocallyLoading, setIsLocallyLoading] = useState(false);
  const [fetchMessage, setFetchMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [activeNotification, setActiveNotification] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { t } = useTranslation(['common', 'messages']);
  const [actualTab, setActualTab] = useState('pending');

  const {
    currentCountry,
    listOfAvailableKybCountryCodes,
    isLoading,
  } = useSelector((state) => state.kybCountry);


  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      renderCell: (params) => {
        return <Typography variant="number">{params.row._id}</Typography>;
      },
    },
    {
      field: 'kyb',
      headerName: 'KYB ID',
      renderCell: (params) => {
        return (
          <Button onClick={() => {
            dispatch(setIsLoading(true))
            navigate(`/kyb/${params?.row?.countryCode ?? 'BR'}/detail/${params.row.kyb}`)
          }}>
            <Typography variant="number">{params.row.kyb}</Typography>
          </Button>
        );
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      renderCell: (params) => {
        return <TableIcons type={params.row.status.toUpperCase()} />
      },
    },
    {
      field: 'riskLevel',
      headerName: t('common:riskLevel'),
      renderCell: (params) => {
        return <Box>{params.row.riskLevel ? <RiskLevel type={params.row.riskLevel} /> : ''}</Box>
      },
    },
    {
      field: 'expirationDate',
      headerName: t('common:expirationDate'),
      renderCell: (params) => {
        return <Typography variant="number">{params.row.expirationDate}</Typography>;
      },
    },
    {
      field: 'action',
      headerName: t('common:actions'),
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setActiveNotification(params.row);
              setModalOpen(!modalOpen);
            }}
          >
            {t('common:edit')}
          </Link>
        );
      },
    },
  ];

  useKybNotifications(isLoading, tab);

  const notifications = useSelector((state) => state.kybNotifications.notifications);

  const handleResetButton = () => {
    if (id !== '') setId('');
    if (status) setStatus('');
    if (riskLevel) setRiskLevel('');
    if (selectedDate) setSelectedDate(null);
    setPage(0);
    dispatch(setIsLoading(true));

    return dispatch(
      updateKybNotificationsFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        status: null,
        riskLevel: null,
        name: '',
        fromDate: null,
        toDate: null,
      })
    );
  };

  const handleDispatch = () => {
    dispatch(setIsLoading(true));
    return dispatch(
      updateKybNotificationsFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        status,
        riskLevel,
        fromDate: selectedDate instanceof Date ? selectedDate.toISOString() : null,
        toDate: toDate ? new Date(toDate)?.toISOString() : null,
      }),
    )
  }

  const handleChange = (event, newValue) => {
    handleResetButton();
    navigate(`/kyb/notifications/${newValue}`);
  };

  useEffect(() => {
    handleDispatch()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (!tab || (tab !== 'completed' && tab !== 'pending' && tab !== 'canceled')) {
      navigate('/kyb/notifications/pending');
      setActualTab('pending');
    } else {
      setActualTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (activeNotification) {
      setNewStatus(activeNotification.status);
    }
  }, [activeNotification]);

  const handleUpdate = (e, data, id) => {
    e.preventDefault();
    setIsFetching(true);
    setFetchMessage('');
    setIsProcessing(true);
    dispatch(setIsLoading(true));

    if (!process.env.REACT_APP_IS_DEMO) {
      let url = `${process.env.REACT_APP_BASEURL}/kyb/${currentCountry}/notification/${id}`;

      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setIsProcessing(false);
            setModalOpen(false);
            setFetchMessage('Update success.');
            setNewStatus('');
            setActiveNotification('');
            dispatch(setIsLoading(false));
            setPage(0);
          } else {
            throw new Error(res.message ? res.message : t('messages:unexpectedError'));
          }
        })
        .catch((err) => {
          console.error('[UPDATE NOTIFICATION ERROR] --> ', err);
          setIsProcessing(false);
          setFetchError(true);
          setModalOpen(false);
          setNewStatus('');
          dispatch(setIsLoading(false));
          setActiveNotification('');
          setFetchMessage(err.message ? err.message : t('messages:unexpectedErrorValues'));
        });
    } else {
      setIsProcessing(false);
      setModalOpen(false);
      setFetchMessage('Update success.');
      setNewStatus('');
      setActiveNotification('');
      dispatch(setIsLoading(false));
    }

    setTimeout(() => {
      setIsFetching(false);
    }, 3000);
  };

  useEffect(() => {
    if(isLoading !== isLocallyLoading) dispatch(setIsLoading(isLocallyLoading))
  }, [isLoading, isLocallyLoading])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}
      <Box className="component-title-wrapper">
        <Box className="component-title" display="flex">
          <Typography variant="h2">{t('common:companyNotifications')}</Typography>
          {/* <CountrySelector
            currentCountry={currentCountry}
            listOfAvailableKybCountryCodes={listOfAvailableKybCountryCodes}
            t={t}
            isLoading={isLoading}
            setIsLoading={setIsLocallyLoading}
        /> */}
        </Box>
        <Typography variant="subtitle3">{/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}</Typography>
      </Box>
      <Paper elevation={0}>
        <TabContext value={actualTab}>
          <Box className="component-title" sx={{ marginBottom: '1.5rem' }}>
            <CustomTabList onChange={handleChange}>
              <Tab label={t('common:pending')} value="pending" />
              <Tab label={t('common:completed')} value="completed" />
              <Tab label={t('common:canceled')} value="canceled" />
            </CustomTabList>
          </Box>
          <TabPanel
            value="pending"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
            }}
          >
            <Paper elevation={0} variant="colorSecondary" className="kyb-detail">
              <Typography variant="title">{t('common:outdated')}</Typography>

              <DataGridComponent
                rows={
                  Array.isArray(notifications[0]?.outdated?.data) && notifications[0]?.outdated?.data?.length
                    ? notifications[0]?.outdated?.data
                    : []
                }
                columns={columns}
                count={notifications[0]?.outdated?.pagination?.totalElements || 0}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setIsLoading={setIsLocallyLoading}
                setModalOpen={setFilterModalOpen}
                hasTabs="30rem"
              />
            </Paper>

            <Paper elevation={0} variant="colorSecondary" className="kyb-detail">
              <Typography variant="title">{t('common:next')}</Typography>

              <DataGridComponent
                rows={
                  Array.isArray(notifications[1]?.next?.data) && notifications[1]?.next?.data?.length
                    ? notifications[1]?.next?.data
                    : []
                }
                columns={columns}
                count={notifications[0]?.next?.pagination?.totalElements || 0}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setIsLoading={setIsLocallyLoading}
                setModalOpen={setFilterModalOpen}
                hasTabs="30rem"
              />
            </Paper>
          </TabPanel>
          <TabPanel value="completed">
            <DataGridComponent
              rows={Array.isArray(notifications?.data) && notifications?.data?.length ? notifications?.data : []}
              columns={columns}
              count={notifications?.pagination?.totalElements || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setIsLoading={setIsLocallyLoading}
              setModalOpen={setFilterModalOpen}
              hasTabs="24rem"
            />
          </TabPanel>
          <TabPanel value="canceled">
            <DataGridComponent
              rows={Array.isArray(notifications?.data) && notifications?.data?.length ? notifications?.data : []}
              columns={columns}
              count={notifications?.pagination?.totalElements || 0}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              setIsLoading={setIsLocallyLoading}
              setModalOpen={setFilterModalOpen}
              hasTabs="24rem"
            />
          </TabPanel>
        </TabContext>
      </Paper>

      <FilterModal
        modalOpen={filterModalOpen}
        setModalOpen={setFilterModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={id}
              onChange={(event) => setId(event.target.value)}
              placeholder={t('common:searchById')}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:riskLevel')}:
            </Typography>
            <Select
              size="small"
              value={riskLevel}
              fullWidth
              displayEmpty
              onChange={(e) => setRiskLevel(e.target.value)}
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:riskLevel')}
                </Typography>
              </MenuItem>
              {kybRiskLevelEnum.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`common:${item}`)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </FilterModal>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className="modal">
          {isProcessing ? (
            <ProcessingSpinner message={t('common:processing')} />
          ) : (
            <Box className="send-btn">
              <Box className="modal-top">
                <Box className="modal-titles">
                  <Typography variant="title">
                    {t('common:edit')} {t('common:status')}
                  </Typography>
                </Box>
                <Fab variant="close" onClick={() => setModalOpen(false)}>
                  <CloseOutlinedIcon />
                </Fab>
              </Box>
              <Box>
                <Box className="notifications-row">
                  <p> KYB ID:</p>
                  <Box className="id-action" onClick={() => navigate(`/kyb/detail/${activeNotification.kyb}`)}>
                    {activeNotification.kyb}
                  </Box>
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:createdAt')}:</p> {buildTimeStringFromTimestamp(activeNotification?.createdAt)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:expirationDate')}:</p>
                  {buildTimeStringFromTimestamp(activeNotification?.expirationDate)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:updatedAt')}:</p> {buildTimeStringFromTimestamp(activeNotification?.updatedAt)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:riskLevel')}:</p>
                  {activeNotification?.riskLevel ? <RiskLevel type={activeNotification?.riskLevel} /> : ''}
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="status" sx={{ mb: 2 }}>
                  {t('common:status')}:
                </InputLabel>
                <Select
                  id="status"
                  size="small"
                  fullWidth
                  displayEmpty
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <MenuItem disabled value="">
                    <Typography variant="subtitle3">
                      {t('common:select')} {t('common:status')}
                    </Typography>
                  </MenuItem>
                  {statusEnum.map((item) => (
                    <MenuItem key={item} value={item}>
                      {t(`common:${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box className="modal-filter-buttons">
                <Button size="small" variant="outlinedGrey" onClick={() => setModalOpen(false)}>
                  {t('common:cancel')}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => handleUpdate(e, { status: newStatus }, activeNotification?._id)}
                >
                  {t('common:save')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default KybNotifications;
