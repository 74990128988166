import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { updateKybNotifications } from '../../features/kybNotifications/kybNotificationsSlice'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'

export const useKybNotifications = (isLoading, tab) => {
  const queryState = useSelector((state) => state.kybNotifications?.filters)
  const { limit, offset, fromDate, toDate, page, id, status, riskLevel, expirationDate } = queryState || {}
  const { accessToken } = useSelector((state) => state.login)
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)

  const dispatch = useDispatch()

  function buildUrl(baseUrl, type) {
    const today = new Date().setDate(new Date().getDate() + 1)
    const formattedDate = new Date(today)
      .toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' })
      .replaceAll('/', '-')

    let url = `${baseUrl}?limit=${limit}&page=${page}&offset=${offset}`
    if (type === 'outdated') {
      url += `&toDate=${formattedDate}`
    } else if (type === 'next') {
      url += `&fromDate=${formattedDate}`
    } else if (!type) {
      if (fromDate) url += `&fromDate=${fromDate}`
      if (toDate) url += `&toDate=${toDate}`
    }
    if (id?.length) url += `&id=${id}`
    if (status?.length || tab?.length) url += `&status=${tab?.length ? tab : status}`
    if (riskLevel?.length) url += `&riskLevel=${riskLevel}`
    if (expirationDate?.length) url += `&expirationDate=${expirationDate}`

    return url
  }

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  useEffect(() => {
    const baseUrlKyb = `${process.env.REACT_APP_BASEURL}/kyb${countrySegment}all/notifications`
    if (isLoading && tab !== undefined && tab !== 'pending') {
      let url = buildUrl(baseUrlKyb)

      fetch(process.env.REACT_APP_IS_DEMO ? '../../data/notificationCompleted.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const { data, pagination } = res
            const aux = { data, pagination }
            dispatch(updateKybNotifications(aux))
            dispatch(setIsLoading(false))
          }
        })
        .catch((error) => {
          console.error('[HOOK: useKybNotifications] --> ', error)
          dispatch(setIsLoading(false))
        })
    } else if (isLoading && tab !== undefined && tab === 'pending') {
      let urlOutdated = buildUrl(baseUrlKyb, 'outdated')
      let urlNext = buildUrl(baseUrlKyb, 'next')

      let allPendingNotifications = []

      const outdatedNotifications = fetch(
        process.env.REACT_APP_IS_DEMO ? '../../data/notificationsPendingOutdated.json' : urlOutdated,
        options,
      )
        .then((res) => res.json())
        .then((res) => res)
        .catch((error) => {
          console.error('[HOOK: useKybNotifications] --> ', error)
        })

      const nextNotifications = fetch(
        process.env.REACT_APP_IS_DEMO ? '../../data/notificationsPendingNext.json' : urlNext,
        options,
      )
        .then((res) => res.json())
        .then((res) => res)
        .catch((error) => {
          console.error('[HOOK: useKybNotifications] --> ', error)
        })

      Promise.all([outdatedNotifications, nextNotifications]).then((responses) => {
        if (responses[1]) {
          allPendingNotifications.push({ outdated: responses[1] })
        }
        if (responses[0]) {
          allPendingNotifications.push({ next: responses[0] })
        }

        dispatch(updateKybNotifications(allPendingNotifications))
        dispatch(setIsLoading(false))
      })
    }
  }, [isLoading, tab])
}
