import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Box, Modal, Button, Checkbox, Collapse, Typography, Paper, Chip, Fab, TextField, Link } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useRolesByEmailClient } from '../../pages-hooks/useRoles'
import Spinner from '../../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import MenuItem from '@mui/material/MenuItem'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import { ArrowBack, CloseOutlined } from '@mui/icons-material'
import DatatableRoleCreation from '../../../components/common/datatable/DatatableRoleCreation'

const RoleCreationModal = ({ show, setShow }) => {
  const roles = useSelector((state) => state.role?.roles)
  const { accessToken, emailClient, email } = useSelector((state) => state.login)
  const [servicesToRender, setServicesToRender] = useState([])
  const [serviceCategoriesToRender, setServiceCategoriesToRender] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [services, setServices] = useState([])
  const [open, setOpen] = useState([])
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [description, setDescription] = useState('')
  const [askForAnother, setAskForAnother] = useState(false)
  const { t } = useTranslation(['common', 'messages'])
  const [rolesNames, setRolesNames] = useState([])
  const [superRoleName, setSuperRoleName] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [fetchError, setFetchError] = useState(false)
  const [selectedTab, setSelectedTab] = useState('basic')
  const [isInPreview, setIsInPreview] = useState(false)

  useRolesByEmailClient(emailClient)

  const returnServiceCategory = (serviceName) => {
    switch (serviceName.toUpperCase()) {
      case 'ROLES':
      case 'USERS':
      case 'HEALTHCHECK':
      case 'USERMASTER':
      case 'REPORTS':
      case 'ALERTSETTINGS':
      case 'WEBHOOKS':
      case 'CHECKLISTINSTANCES':
      case 'CHECKLISTTEMPLATES':
      case 'LISTS':
      case 'RULES':
      case 'REQUESTTOAPPROVEORREJECT':
      case 'RISKMATRIX':
        return 'admin'
      case 'KYT':
      case 'CLIENT':
        return 'transactionMonitoring'
      case 'VALIDATION':
      case 'GOVCHECK':
      case 'AMLCRYPTO':
      case 'AML':
      case 'ONBOARDING':
      case 'KYB':
        return 'onboarding'
      case 'CASEMANAGEMENT':
      case 'ALERTS':
      case 'GENERALALERTS':
        return 'intelligence'
      default: // comments & info should not be displayed
        return 'Uncategorized'
    }
  }

  useEffect(() => {
    setRolesNames(
      roles.data?.map((r) => {
        return r.roleName
      }),
    )
  }, [roles.data])

  useEffect(() => {
    if (roles && roles?.data?.length !== 0) {
      let aux = roles?.data?.filter((r) => r.roleName === 'MANAGER')
      if (aux && aux?.length !== 0 && aux[0]?.services && aux[0]?.services?.length !== 0) {
        setServicesToRender(aux[0].services)
        setError(false)
      } else {
        setError(true)
      }
    }
  }, [roles])

  const handleOpen = (idx, value) => {
    let aux = [...open]
    aux[idx] = value
    setOpen([...aux])
  }

  const handleClose = () => {
    setServices([])
    setError(false)
    setErrorMessage('')
    setRoleName('')
    setDescription('')
    setAskForAnother(false)
  }

  const handleEditService = (checked, service, subService) => {
    let arrServices = []
    if (service && checked && !subService) {
      arrServices = [...services]
      arrServices.push(service)
      setServices(arrServices)
    } else if (service && !checked && !subService) {
      arrServices = [...services]
      arrServices = arrServices.filter((a) => a.name !== service.name)
      setServices(arrServices)
    }
  }

  const handleEditSubService = (checked, service, subService) => {
    let arrServices = []
    if (subService && checked) {
      const exist = services.filter((s) => s.name === service.name)
      if (!exist.length) {
        let custom = {
          name: service.name,
          status: 'active',
          subServices: [],
        }

        custom.subServices.push(subService)
        arrServices = [...services]

        arrServices.push(custom)
        setServices(arrServices)
      } else {
        arrServices = [...services]
        const mapped = arrServices.map((s) => {
          if (s.name === service.name) {
            s.subServices.push(subService)
          }

          return s
        })

        arrServices = mapped
        setServices(arrServices)
      }
    } else if (subService && !checked) {
      arrServices = [...services]
      let deletedFilter = arrServices.filter((a) => a.name !== service.name)

      let custom = {
        name: service.name,
        status: 'active',
        subServices: [],
      }

      let subserviceCustom = arrServices
        .find((s) => s.name === service.name)
        .subServices.filter((ss) => ss !== subService)
      custom.subServices = subserviceCustom
      deletedFilter.push(custom)
      setServices(deletedFilter)
    }
  }

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const createRole = () => {
    setIsLoading(true)
    let data = {
      emailClient,
      roleName: roleName.toUpperCase(),
      globalPrexi: '/api/',
      status: 'ACTIVE',
      services: services,
      description,
      createdUser: email,
      updatedUser: email,
    }

    if (superRoleName !== '') data['superRoleName'] = superRoleName

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminRoles = `${process.env.REACT_APP_BASEURL}/admin/roles`
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlAdminRoles, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) {
            setError(true)
            if (res.data && typeof res.data === 'string') {
              setErrorMessage(res.data)
            }
            setIsLoading(false)
          } else {
            handleFetch(false, res.message)
            setTimeout(() => {
              setIsLoading(false)
            }, 2000)
            setAskForAnother(true)
          }
        })
        .catch((error) => {
          console.error('[CREATE ROLE ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : 'Role edition error')
        })
    } else {
      handleFetch(false, 'Role creation success')
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
      setAskForAnother(true)
    }
  }

  useEffect(() => {
    if (servicesToRender.length > 0 && serviceCategoriesToRender.length === 0) {
      const existingCategories = new Set(servicesToRender.map((service) => returnServiceCategory(service.name)))
      setServiceCategoriesToRender(Array.from(existingCategories))
    }
  }, [servicesToRender, serviceCategoriesToRender])

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <Box className="modal">
        {isLoading && <Spinner noTransform />}
        {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}
        <Box width="100%" minWidth="67vw" height="75vh">
          <TabContext value={selectedTab}>
            {isInPreview ? (
              <Box width="100%">
                <Box className="title-roles">
                  <Link
                    className="icon-global-medium role-creation-back"
                    variant="link"
                    onClick={() => {
                      setIsInPreview((previous) => !previous)
                    }}
                  >
                    <ArrowBack className="colors" />
                    {t('common:backToEdit')}
                  </Link>

                  <Fab
                    variant="close"
                    onClick={() => {
                      setShow(false)
                      handleClose()
                    }}
                  >
                    <CloseOutlined />
                  </Fab>
                </Box>
                <Box my="1rem">
                  <Typography variant="title">{t('common:yourNewRoleWillHaveAccessTo')}</Typography>
                </Box>
                <Box className="role-creation-list">
                  <Box className="role-creation-box">
                    {services.map((service, idx) => (
                      <TabPanelItemInsides
                        key={service.name}
                        isInPreview={isInPreview}
                        t={t}
                        servicesToRender={servicesToRender}
                        handleOpen={handleOpen}
                        open={open}
                        service={service}
                        services={services}
                        idx={idx}
                        handleEditService={handleEditService}
                        selectedRowIds={selectedRowIds}
                        setSelectedRowIds={setSelectedRowIds}
                        handleEditSubService={handleEditSubService}
                      />
                    ))}
                  </Box>
                </Box>
                <Box sx={{ marginLeft: 'auto', width: 'fit-content' }}>
                  <Button
                    variant="outlined"
                    disabled={services.length === 0 || roleName === ''}
                    onClick={() => createRole()}
                  >
                    {`${t('common:createNew')} ${t('common:role')}`}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box className="role-creation-grid">
                <Paper elevation={0} variant="colorPrimary" className="role-creation-box">
                  <Typography variant="title" padding="0.5rem">
                    {t('common:permissions')}
                  </Typography>
                  <Box className="transaction-details">
                    <TabList
                      TabIndicatorProps={{
                        style: { display: 'none' },
                      }}
                      sx={{
                        '&.MuiTabs-flexContainer': {
                          maxHeight: '40vh',
                          overflowYY: 'scroll',
                        },
                      }}
                      className="transaction-tablist"
                      onChange={(e, newValue) => setSelectedTab(newValue)}
                    >
                      <Tab
                        label={
                          <Chip
                            label={t('common:basicDetails')}
                            variant={selectedTab === 'basic' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                          />
                        }
                        value="basic"
                      />
                      {serviceCategoriesToRender?.map(
                        (item) =>
                          item !== 'Uncategorized' && (
                            <Tab
                              key={item}
                              label={
                                <Chip
                                  label={t(`common:${item}`)}
                                  variant={selectedTab === item ? 'sliderSelectedPurple' : 'sliderTransparent'}
                                />
                              }
                              value={item}
                            />
                          ),
                      )}
                    </TabList>
                  </Box>
                </Paper>
                <Box className="create-role-content-container">
                  <Box className="title-roles">
                    <Typography variant="title" whiteSpace="nowrap" marginRight="3rem">
                      {t('common:assignPermissionsToNewRole')}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" gap="1rem">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setIsInPreview((previous) => !previous)
                        }}
                      >
                        {t('common:preview')}
                      </Button>
                      <Fab
                        variant="close"
                        onClick={() => {
                          setShow(false)
                          handleClose()
                        }}
                      >
                        <CloseOutlined />
                      </Fab>
                    </Box>
                  </Box>
                  <Box className="role-creation-tab-panel role-creation-list">
                    <TabPanel value="basic">
                      <Box className="role-creation">
                        <Typography variant="h4">{t('common:basicDetails')}</Typography>
                        <Paper variant="rootOutlined">
                          <Box className="role-creation-input-wrapper">
                            <Box className="role-creation-box">
                              <Box className="required">
                                <Typography variant="text2" htmlFor="name">
                                  {t('common:roleName')}
                                </Typography>
                                <Typography variant="subtitle3">({t('common:required')})</Typography>
                              </Box>

                              <TextField
                                fullWidth
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                                required
                                id="name"
                              />
                            </Box>
                            <Box className="role-creation-box">
                              <Box className="required">
                                <Typography variant="text2" htmlFor="description">
                                  {t('common:description')}
                                </Typography>
                                <Typography variant="subtitle3">({t('common:required')})</Typography>
                              </Box>
                              <TextField
                                fullWidth
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                id="description"
                              />
                            </Box>
                            <Box className="role-creation-box">
                              <Typography variant="text2">{t('common:parentRole')}</Typography>

                              <Select
                                fullWidth
                                size="small"
                                displayEmpty
                                value={superRoleName}
                                onChange={(event) => {
                                  setSuperRoleName(event.target.value)
                                }}
                                renderValue={(selected) => {
                                  return selected
                                }}
                              >
                                {Array.isArray(rolesNames) &&
                                  rolesNames.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Box>
                          </Box>

                          {error && (
                            <Box className="create-role-error-container">
                              <Button className="create-role-error" size="small" variant="outlined" color="error">
                                {errorMessage}
                              </Button>
                            </Box>
                          )}
                        </Paper>
                      </Box>
                    </TabPanel>
                    {servicesToRender.map((service, idx) => (
                      <TabPanel key={service.name} value={returnServiceCategory(service.name)}>
                        <TabPanelItemInsides
                          isInPreview={isInPreview}
                          t={t}
                          servicesToRender={servicesToRender}
                          handleOpen={handleOpen}
                          open={open}
                          service={service}
                          services={services}
                          idx={idx}
                          handleEditService={handleEditService}
                          selectedRowIds={selectedRowIds}
                          setSelectedRowIds={setSelectedRowIds}
                          handleEditSubService={handleEditSubService}
                        />
                      </TabPanel>
                    ))}
                  </Box>

                  <Box sx={{ marginTop: '1.5rem', justifySelf: 'end' }}>
                    <Button
                      variant="outlined"
                      disabled={services.length === 0 || roleName === ''}
                      onClick={() => createRole()}
                    >
                      {`${t('common:createNew')} ${t('common:role')}`}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </TabContext>
        </Box>
        <Modal open={askForAnother} onClose={handleClose}>
          <Box className="modal">
            <Box className="modal-top">
              <Box className="modal-titles">
                <Typography variant="title">{t('common:roles')}</Typography>
              </Box>
              <Fab variant="close" onClick={() => handleClose()}>
                <CloseOutlinedIcon />
              </Fab>
            </Box>

            <Box className="modal-box">
              <Typography variant="subtitle">{t('messages:createAnotherRole')}</Typography>
              <Box className="modal-filter-buttons">
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose()
                    setShow(false)
                  }}
                >
                  {t('common:no')}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsInPreview(false)
                    services.forEach((service) => handleEditService(false, service))
                  }}
                >
                  {t('common:yes')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Modal>
  )
}

export default RoleCreationModal

const TabPanelItemInsides = ({
  isInPreview,
  t,
  handleOpen,
  open,
  service,
  services,
  idx,
  selectedRowIds,
  setSelectedRowIds,
  handleEditService,
  handleEditSubService,
}) => {
  return (
    <Paper variant="rootOutlined">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Checkbox
            color="primary"
            checked={services?.find((s) => s.name === service.name)?.subServices?.length > 0 || false}
            onChange={(e) => !isInPreview && handleEditService(e.target.checked, service)}
          />
          <Typography variant="text2" textTransform="capitalize">
            {service.name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Fab variant="close" aria-label="expand row" size="small" onClick={() => handleOpen(idx, !open[idx])}>
            <KeyboardArrowDownIcon
              sx={{
                transform: open[idx] ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 0.5s ease-out',
              }}
            />
          </Fab>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="1rem">
        <Collapse in={open[idx]} timeout={'auto'} unmountOnExit>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" my="0.25rem">
              <Typography variant="subtitle3" textTransform="capitalize" fontSize="1rem" paddingLeft="0.7rem">
                {service.name} {t('common:subservices').toLowerCase()}:
              </Typography>
              {(services?.find((s) => s.name === service.name)?.subServices?.length > 0 || false) && !isInPreview && (
                <Link
                  onClick={() => {
                    handleEditService(false, service)
                  }}
                >
                  {t('common:unselectAll')}
                </Link>
              )}
            </Box>
            <DatatableRoleCreation
              isInPreview={isInPreview}
              service={service}
              services={services}
              selectedRowIds={selectedRowIds}
              setSelectedRowIds={setSelectedRowIds}
              handleEditService={handleEditService}
              handleEditSubService={handleEditSubService}
            />
          </Box>
        </Collapse>
      </Box>
    </Paper>
  )
}
