import NodeCategories from '../../data/NodeCategories.js'
import { Nodes, NodeType } from '../../data/Nodes.js'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import { Box, Button, Input, TextField, Autocomplete, Divider } from '@mui/material'
// import { useTranslation } from 'react-i18next'
import './Sidebar.scss'
import { useState } from 'react'

function Sidebar() {
  // const { t } = useTranslation(['common'])

  const [srvNameTarget, setSrvNameTarget] = useState('')
  const [filteredServices, setFilteredServices] = useState(Nodes)
  const [serviceCategory, setServiceCategory] = useState('All')

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }

  const onSrvNameSearch = (srvNameTarget) => {
    setSrvNameTarget(srvNameTarget)
    if (srvNameTarget !== '') {
      setFilteredServices(() =>
        Object.values(Nodes).filter((n) => n.label.toLowerCase().includes(srvNameTarget.toLowerCase())),
      )
    } else {
      setFilteredServices(Nodes)
    }
  }

  const handleOnClickServiceBtnType = (serviceType) => {
    if (serviceType === 'All') {
      setSrvNameTarget('')
      setFilteredServices(Nodes)
    }
    if (serviceType.includes('basics')) {
      setSrvNameTarget('')
      setFilteredServices(Object.values(Nodes).filter((n) => n.category.includes('basics')))
    }
    if (serviceType.includes('advanced')) {
      setSrvNameTarget('')
      setFilteredServices(Object.values(Nodes).filter((n) => n.category.includes('advanced')))
    }
    if (serviceType === 'Other') {
      setSrvNameTarget('')
      setFilteredServices(Object.values(Nodes).filter((n) => n.category === 'others'))
    }
  }

  return (
    <div className="dnd-flow-sidebar">
      <div className="sidebar-header">
        <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Input
            className="label-input "
            style={{ marginLeft: '0px' }}
            size="small"
            value={srvNameTarget}
            onChange={(evt) => onSrvNameSearch(evt.target.value)}
            placeholder="Search by service ..."
          />
        </Box>
      </div>
      <div className="sidebar-description label-input" style={{ fontSize: 15, textAlign: 'left' }}>
        <span>Drag the service you want to use to the panel</span>
      </div>
      <div className="sidebar-body">
        <div className="sidebar-nodes">
          {Object.values(filteredServices).map((n, i) => {
            const category = NodeCategories[n.category]
            return (
              <div key={'node' + i} className="node-item" onDragStart={(event) => onDragStart(event, n.key)} draggable>
                <div style={{ background: category.color }} className="node-category-color" />
                <Button variant="text" size="small" style={{ color: category.color }}>
                  <span className="node-label">{n.label}</span>
                </Button>
              </div>
            )
          })}
        </div>
        <div>
          <div className="sidebar-footer">
            <Divider />
            <div className="category-colors" style={{ paddingTop: 16 }}>
              {Object.values(NodeCategories).map((nc, i) => {
                return (
                  <div key={'category-color' + i} className="category-color-item">
                    <div style={{ background: nc.color }} className="color-box" />
                    <Button onClick={() => handleOnClickServiceBtnType(nc.name)}>
                      <span className="label-input">{nc.name}</span>
                    </Button>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
