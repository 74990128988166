import './MyRules.scss'
import { useEffect, useState } from 'react'
import AlertFetchSpinner from '../../../common/alertFetchSpinner/alertFetchSpinner'
import { Box } from '@mui/material'
import Spinner from '../../../common/spinner/spinner'
import { useSelector } from 'react-redux'
import DataExportModal from '../../../common/dataExport/DataExportModal'
import { myRulesDataMapper, myRulesDataMapperForPDF } from '../../../common/dataExport/dataMappers/myRulesDataMapper'
import { RulesDrawer } from '../rulesLibrary/RulesDrawer'
import MyRulesTable from './MyRulesTable'
import { useTranslation } from 'react-i18next'

const MyRules = ({
  rules,
  isLoading,
  instanceRules,
  setIsLoading,
  setInstanceRules,
  verifyDeletion,
  verifyEdition,
  handleChangeRemote,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFechError] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [message, setMessage] = useState('')
  const [selectedRule, setSelectedRule] = useState({})
  const [ruleToEdit, setRuleToEdit] = useState({})
  const [ruleParameters, setRuleParameters] = useState({})
  const [open, setOpen] = useState(false)
  const [ruleList, setRuleList] = useState([])
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { accessToken } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])

  useEffect(() => {
    if (instanceRules.length !== 0 || (message && message.includes('deletion success.'))) {
      setRuleList(instanceRules)
    }
  }, [instanceRules, message])

  const columns = [
    { field: 'idConcat', headerName: t('common:ruleId'), width: 200 },
    { field: 'ruleNameAlias', headerName: t('common:ruleName'), width: 270 },
    { field: 'hitRate', headerName: t('common:ruleHitRate'), width: 80 },
    { field: 'action', headerName: t('common:action') },
  ]

  useEffect(() => {
    if (selectedRule && Object.keys(selectedRule).length !== 0 && rules.length !== 0) {
      const schemaSelected = rules.filter((element) => {
        return element.id === selectedRule?.ruleId
      })
      setRuleToEdit(schemaSelected[0])
    }
  }, [selectedRule, rules])

  const handleNext = (rule) => {
    const selected = ruleList.filter((element) => {
      return element.id === rule
    })
    setSelectedRule(selected[0])
    setRuleParameters({ ...selected[0]?.parameters })
    setOpen(true)
  }

  const handleActive = (id, value, row, isGueno, rows, setRowsToShow) => {
    setFetched(false)
    setIsFetching(true)
    setMessage(`${t('messages:desactivateRule')} ${id}.`)
    if (!process.env.REACT_APP_IS_DEMO) {
      const aux = { ...row, instanceId: id }
      delete aux.createdAt
      delete aux.updatedAt
      delete aux.id
      delete aux.idConcat
      delete aux.gueno

      let url
      let method
      let data

      if (isGueno) {
        url = `${process.env.REACT_APP_BASEURL}/rules/${id}`
        data = {
          status: value,
        }
        method = 'PATCH'
      } else {
        url = `${process.env.REACT_APP_BASEURL}/kyt/updateClientInstanceRule/${id}`
        data = {
          ...aux,
          status: value,
        }
        method = 'POST'
      }

      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success) {
            throw new Error(res.message)
          }
          setIsFetching(false)
          setFetched(true)
          setFechError(false)
          setMessage(res.message)
          let idx = ruleList
            .map(function (r) {
              return r.id
            })
            .indexOf(id)
          if (idx !== -1 && res.isCPO) {
            setRowsToShow([
              ...rows.slice(0, idx),
              { ...rows[idx], status: value === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' },
              ...rows.slice(idx + 1),
            ])
          }
          if (idx !== -1 && !res.isCPO) {
            setInstanceRules([
              ...ruleList.slice(0, idx),
              { ...ruleList[idx], status: value },
              ...ruleList.slice(idx + 1),
            ])
          }
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
        .catch((error) => {
          setIsFetching(false)
          setFetched(true)
          setFechError(true)
          setMessage(`${t('messages:desactivateRule')} ${id} ${t('common:error')}.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
    } else {
      setIsFetching(false)
      setFetched(true)
      setFechError(false)
      setMessage('Rule update success')
      let idx = ruleList
        .map(function (r) {
          return r.id
        })
        .indexOf(id)
      setInstanceRules([...ruleList.slice(0, idx), { ...ruleList[idx], status: value }, ...ruleList.slice(idx + 1)])
      setTimeout(() => {
        setFetched(false)
      }, 3000)
    }
  }

  const handleDelete = (id, isGueno) => {
    if (!process.env.REACT_APP_IS_DEMO) {
      let url

      if (isGueno) {
        url = `${process.env.REACT_APP_BASEURL}/rules/${id}`
      } else {
        url = `${process.env.REACT_APP_BASEURL}/kyt/deleteClientInstanceRule/${id}`
      }

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      setSelectedRule({})

      if (isGueno) {
        fetch(url, options)
          .then((res) => res.json())
          .then((res) => {
            if (res.status >= 400) throw Error(res.message)

            let aux = [...ruleList]

            let idx = aux.findIndex((element) => {
              return element.id === id.toString()
            })

            if (!res.isCPO && idx !== -1) {
              setInstanceRules([...aux.slice(0, idx), ...aux.slice(idx + 1)])
            }
            setSelectedRule({})
            setFetched(true)
            setFechError(false)
            setMessage(res.message)
            setTimeout(() => {
              setFetched(false)
            }, 3000)
          })
          .catch((error) => {
            setFetched(true)
            setFechError(true)
            setMessage(`${t('common:rule')} ${id} ${t('messages:deletionError')}.`)
            setTimeout(() => {
              setFetched(false)
            }, 3000)
          })
      } else {
        fetch(url, options)
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode >= 400) throw Error(res.message)

            let aux = [...ruleList]

            let idx = aux.findIndex((element) => {
              return element.id === id.toString()
            })

            if (idx !== -1) {
              setInstanceRules([...aux.slice(0, idx), ...aux.slice(idx + 1)])
            }
            setSelectedRule({})
            setFetched(true)
            setFechError(false)
            setMessage(`${t('common:rule')} ${id} ${t('messages:deletionSuccess')}.`)
            setTimeout(() => {
              setFetched(false)
            }, 3000)
          })
          .catch((error) => {
            setFetched(true)
            setFechError(true)
            setMessage(`${t('common:rule')} ${id} ${t('messages:deletionError')}.`)
            setTimeout(() => {
              setFetched(false)
            }, 3000)
          })
      }
    } else {
      let aux = [...ruleList]

      let idx = aux.findIndex((element) => {
        return element.id === id.toString()
      })

      setInstanceRules([...aux.slice(0, idx), ...aux.slice(idx + 1)])
      setSelectedRule({})
      setFetched(true)
      setFechError(false)
      setMessage(`${t('common:rule')} ${id} ${t('messages:deletionSuccess')}.`)
      setTimeout(() => {
        setFetched(false)
      }, 3000)
    }
  }

  return (
    <Box>
      {isLoading && <Spinner />}
      {isFetching && <AlertFetchSpinner message={message} spinner={true} />}
      {fetched && <AlertFetchSpinner message={message} error={fetchError} />}

      {!isLoading && (
        <MyRulesTable
          handler={handleNext}
          rows={ruleList}
          columns={columns}
          withButtons={true}
          handleSwitchChange={handleActive}
          deleteHandler={handleDelete}
          message={message}
          isFetching={isFetching}
          verifyEdition={verifyEdition}
          verifyDeletion={verifyDeletion}
          isLoading={isLoading}
          rules={rules}
        />
      )}
      {ruleList.length !== 0 && (
        <RulesDrawer
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          selectedRule={selectedRule}
          setSelectedRule={setSelectedRule}
          setOpen={setOpen}
          open={open}
          myRule={true}
          ruleToEdit={ruleToEdit}
          ruleList={ruleList}
          setInstanceRules={setInstanceRules}
          handleChangeRemote={handleChangeRemote}
        />
      )}
      <DataExportModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        data={ruleList}
        dataMapper={myRulesDataMapper}
        dataMapperForPDF={myRulesDataMapperForPDF}
        fileName={'my-rules'}
      />
    </Box>
  )
}

export default MyRules
